import { Houseicon } from "../assets/icons/assorticons";
import { Cart } from "../assets/icons/assorticons";
import { Skuicon } from "../assets/icons/assorticons";
import { Magnifyicon } from "../assets/icons/assorticons";

const anchorFiltersBrand = {
  country: {
    title: "Country",
    options: [
      // {
      //   id: "Country1",
      //   label: "Country1",
      //   value: "Country1",
      //   default: false,
      //   initial: "BZ",
      //   region: "north_america_mill",
      // },{
      //   id: "Country2",
      //   label: "Country2",
      //   value: "Country2",
      //   default: false,
      //   initial: "BZ",
      //   region: "north_america_mill",
      // },
    ],
    placeholder: "Select",
    first: true,
    children: "country",
  },
  dateTimePeriod: {
    title: "Date Time Period",
    options: [
      // {
      //   id: "dateTimePeriod1",
      //   label: "dateTimePeriod1",
      //   value: "dateTimePeriod1",
      //   default: false,
      //   initial: "BZ",
      //   region: "north_america_mill",
      // },{
      //   id: "dateTimePeriod2",
      //   label: "dateTimePeriod2",
      //   value: "dateTimePeriod2",
      //   default: false,
      //   initial: "BZ",
      //   region: "north_america_mill",
      // },
    ],
    placeholder: "Select",
    first: true,
    children: "dateTimePeriod",
  },
  businessUnit: {
    title: "Business Unit",
    options: [
      //   {
      //   id: "Business1",
      //   label: "Business1",
      //   value: "Business1",
      //   default: false,
      //   initial: "BZ",
      //   region: "north_america_mill",
      // },{
      //   id: "Business2",
      //   label: "Business2",
      //   value: "Business2",
      //   default: false,
      //   initial: "BZ",
      //   region: "north_america_mill",
      // },
    ],
    placeholder: "Select",
    first: true,
    children: "businessUnit",
  },
};

const anchorFilter = {
  anchorCategory: {
    title: "Anchor Category",
    options: [],
    placeholder: "Select",
    first: true,
    children: "anchorSegment",
  },
  anchorSegment: {
    title: "Anchor Segment",
    options: [],
    placeholder: "Select",
    children: "anchorBrand",
  },
  anchorBrand: {
    title: "Anchor Brand",
    options: [],
    placeholder: "Select",
    children: "anchorSubBrand",
  },
  anchorSubBrand: {
    title: "Anchor Sub-brand",
    options: [],
    placeholder: "Select",
    children: "anchorPackSize",
  },
  anchorPackSize: {
    title: "Anchor Pack-size",
    options: [],
    placeholder: "Select",
    last: true,
  },
};

const geoFilterV2Config = {
  country: {
    title: "Country",
    options: ["Country1", "Country2"],
  },
  channel: {
    title: "Channel",
    options: ["Channel1", "Channel2"],
    // placeholder: "Select",
    all: false,
    multiple: false,
  },
  region: {
    title: "Region",
    options: ["Region1", "Region2"],
    // placeholder: "Select",
    all: false,
    multiple: false,
    defaultSelectAll: false,
  },
  storeSegment: {
    title: "Store Segment",
    options: ["Store1", "Store2"],
    // placeholder: "Select",
    all: false,
    multiple: false,
    defaultSelectAll: false,
  },
};

const popupTableData = {
  headers: [
    {
      title: "Segment",
      options: [
        {
          id: "ow418imjbd",
          label: "Segment 1",
          value: "SEGMENT 1",
          default: false,
        },
        {
          id: "ow418mjbd",
          label: "Segment 2",
          value: "SEGMENT 2",
          default: false,
        },
        {
          id: "ow18mjbd",
          label: "Segment 3",
          value: "SEGMENT 3",
          default: false,
        },
      ],
      placeholder: "Select",
      all: true,
      multiple: true,
      defaultSelectAll: true,
    },
    {
      title: "Brand",
      options: [
        {
          id: "ow418imjbd",
          label: "Brand 1",
          value: "BRAND 1",
          default: false,
        },
        {
          id: "w418imjbd",
          label: "Brand 2",
          value: "BRAND 2",
          default: false,
        },
        {
          id: "o418imjbd",
          label: "Brand 3",
          value: "BRAND 3",
          default: false,
        },
      ],
      placeholder: "Select",
      all: true,
      multiple: true,
      defaultSelectAll: true,
    },
    {
      title: "Pack Size",
      options: [
        {
          id: "ow418imjbd",
          label: "Pack Size 1",
          value: "PACK SIZE 1",
          default: false,
        },
        {
          id: "ow418imjd",
          label: "Pack Size 2",
          value: "PACK SIZE 2",
          default: false,
        },
        {
          id: "ow418imjb",
          label: "Pack Size 3",
          value: "PACK SIZE 3",
          default: false,
        },
      ],
      placeholder: "Select",
      all: true,
      multiple: true,
      defaultSelectAll: true,
    },
    {
      title: "SKU",
      options: [
        {
          id: "ow418imjbd",
          label: "sku 1",
          value: "SKU 1",
          default: false,
        },
        {
          id: "ow418imj",
          label: "sku 1",
          value: "SKU 1",
          default: false,
        },
        {
          id: "ow418imd",
          label: "sku 1",
          value: "SKU 1",
          default: false,
        },
      ],
      placeholder: "Select",
      all: true,
      multiple: true,
      defaultSelectAll: true,
    },
    {
      title: "Type",
      options: [
        {
          id: "ow418imjbd",
          label: "Type 1",
          value: "TYPE 1",
          default: false,
        },
      ],
      placeholder: "Select",
      all: true,
      multiple: true,
      defaultSelectAll: true,
    },
  ],
  values: [
    {
      segment: "segment 1",
      brand: "brand 1",
      packSize: "packSize 1",
      sku: "sku 1",
      type: "type 1",
    },
    {
      segment: "segment 2",
      brand: "brand 2",
      packSize: "packSize 2",
      sku: "sku 2",
      type: "type 2",
    },
    {
      segment: "segment 3",
      brand: "brand 3",
      packSize: "packSize 3",
      sku: "sku 3",
      type: "type 3",
    },
    {
      segment: "segment 4",
      brand: "brand 4",
      packSize: "packSize 4",
      sku: "sku 4",
      type: "type 4",
    },
  ],
};

const productFilterV2Config = {
  country: {
    title: "Country",
    options: ["Country1", "Country2"],
  },
  dateTimePeriod: {
    title: "Date Time Period",
    options: ["dateTimePeriod1", "dateTimePeriod2"],
  },
  businessUnit: {
    title: "Business Unit",
    options: ["businessUnit1", "businessUnit2"],
  },
};

const quadrantFilterV2Config = {
  xAxis: {
    title: "Add X-Axis",
    options: ["xaxis1", "xaxis2"],
  },
  yAxis: {
    title: "Add Y-Axis",
    options: ["yaxis1", "yaxis2"],
  },
  bubbleSize: {
    title: "Add Bubble Size",
    options: ["size1", "size2"],
  },
};

const tableHeading = [
  { title: "SKU Name", key: "skuName" },
  { title: "Rank", key: "rank" },
  { title: "Model Output", key: "modelOutput" },
  { title: "Sales Mix", key: "salesMix" },
  { title: "Cumulative Sales Mix", key: "cumulativeSalesMix" },
  { title: "Penetration", key: "penetration" },
  { title: "Total Score", key: "totalScore" },
  { title: "Revenue Score", key: "revenueScore" },
  { title: "Profit Score", key: "profitScore" },
  { title: "Profit Score", key: "noPBTScore" },
  { title: "Growth Score", key: "growthScore" },
  { title: "Market Size Score", key: "marketSizeScore" },
  { title: "Capacity Constraint Score", key: "capacityConstraintScore" },
  { title: "Operative Complexity Score", key: "operativeComplexityScore" },
  { title: "Market Growth Score", key: "marketGrowthScore" },
  { title: "Inventory Turn Score", key: "inventoryTurnScore" },
  { title: "Sales Velocity per Month", key: "salesVelocityPerMonth" },
  { title: "Manufacturer Walk Rate", key: "manufacturerWalkRate" },
  { title: "Incremental Revenue Mix", key: "incrementalRevenueMix" },
  { title: "Incremental Profit Mix", key: "incrementalNoPBTMix" },
];

const tableSkeletonData = [
  {
    skuName: "GAC MAR_TRAD CKY_VANIL_464GM_1PK",
    rank: 1,
    modelOutput: "Must-Have",
    salesMix: 2.786068,
    cumulativeSalesMix: 2.786068,
    penetration: 100.0,
    totalScore: 4.9086514,
    revenueScore: 4.9086514,
    profitScore: 4.9136252,
    noPBTScore: 0.0,
    growthScore: 2.813051,
    marketSizeScore: 3.147922,
    capacityConstraintScore: 5.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 267.60278,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 7141.332,
    manufacturerWalkRate: 89.75,
    incrementalRevenueMix: 100.0,
    incrementalNoPBTMix: 90.0,
  },
  {
    skuName: "EMP REG_SNDWCH CKY_CHOC_273GM_1PK",
    rank: 2,
    modelOutput: "Must-Have",
    salesMix: 2.664218,
    cumulativeSalesMix: 5.4502854,
    penetration: 100.0,
    totalScore: 4.898702,
    revenueScore: 4.898702,
    profitScore: 4.8950844,
    noPBTScore: 0.0,
    growthScore: 2.7574277,
    marketSizeScore: 3.9958396,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 1.0,
    marketGrowthScore: 423.03168,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 6230.9414,
    manufacturerWalkRate: 81.89,
    incrementalRevenueMix: 80.0,
    incrementalNoPBTMix: 70.0,
  },
  {
    skuName: "FOD NAR_SNDWCH CKY_ORA_600GM_1PK",
    rank: 3,
    modelOutput: "Must-Have",
    salesMix: 1.3031639,
    cumulativeSalesMix: 6.75345,
    penetration: 100.0,
    totalScore: 4.839687,
    revenueScore: 4.839687,
    profitScore: 4.8659163,
    noPBTScore: 0.0,
    growthScore: 3.2928596,
    marketSizeScore: 3.9958396,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 423.03168,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 3118.4895,
    manufacturerWalkRate: 83.79,
    incrementalRevenueMix: 60.0,
    incrementalNoPBTMix: 50.0,
  },
  {
    skuName: "GIR CHO_SNDWCH CKY_CHOC_228GM_1PK",
    rank: 4,
    modelOutput: "Must-Have",
    salesMix: 1.1655524,
    cumulativeSalesMix: 7.9190025,
    penetration: 96.77419,
    totalScore: 4.754443,
    revenueScore: 4.754443,
    profitScore: 4.715552,
    noPBTScore: 0.0,
    growthScore: 4.5317235,
    marketSizeScore: 3.9958396,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 1.0,
    marketGrowthScore: 423.03168,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 1736.0355,
    manufacturerWalkRate: 50.47,
    incrementalRevenueMix: 40.0,
    incrementalNoPBTMix: 30.0,
  },
  {
    skuName: "GTF FLO_FRT CKY_STRAWBRY_332GM_1PK",
    rank: 5,
    modelOutput: "Must-Have",
    salesMix: 0.7060927,
    cumulativeSalesMix: 8.625094,
    penetration: 100.0,
    totalScore: 4.7349973,
    revenueScore: 4.7349973,
    profitScore: 4.8308687,
    noPBTScore: 0.0,
    growthScore: 2.7079093,
    marketSizeScore: 2.0295753,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 261.22644,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 1533.6067,
    manufacturerWalkRate: 76.05,
    incrementalRevenueMix: 20.0,
    incrementalNoPBTMix: 10.0,
  },
  {
    skuName: "EMP REG_SNDWCH CKY_LMN_403GM_1PK",
    rank: 6,
    modelOutput: "Must-Have",
    salesMix: 0.9149548,
    cumulativeSalesMix: 9.54005,
    penetration: 100.0,
    totalScore: 4.71216,
    revenueScore: 4.71216,
    profitScore: 4.821824,
    noPBTScore: 0.0,
    growthScore: 3.7661104,
    marketSizeScore: 3.9958396,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 423.03168,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 1345.9974,
    manufacturerWalkRate: 51.510002,
    incrementalRevenueMix: 0.0,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "QGL REG_BFY CKY_CHOC_240GM_1PK",
    rank: 7,
    modelOutput: "Good-to-Have",
    salesMix: 0.59780765,
    cumulativeSalesMix: 10.137857,
    penetration: 100.0,
    totalScore: 4.7078643,
    revenueScore: 4.7078643,
    profitScore: 4.752634,
    noPBTScore: 0.0,
    growthScore: 3.4303353,
    marketSizeScore: 1.618731,
    capacityConstraintScore: 5.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 226.36005,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 1314.806,
    manufacturerWalkRate: 77.01,
    incrementalRevenueMix: 0.0,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "QGL REG_BFY CKY_FRT_240GM_1PK",
    rank: 8,
    modelOutput: "Good-to-Have",
    salesMix: 0.5542013,
    cumulativeSalesMix: 10.692059,
    penetration: 100.0,
    totalScore: 4.671686,
    revenueScore: 4.671686,
    profitScore: 3.1972144,
    noPBTScore: 0.0,
    growthScore: 3.659612,
    marketSizeScore: 1.618731,
    capacityConstraintScore: 5.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 226.36005,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 1093.3845,
    manufacturerWalkRate: 69.08,
    incrementalRevenueMix: 0.0,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "CKS REG_CHOC CHIP CKY_CHOC_038GM_1PK",
    rank: 9,
    modelOutput: "Good-to-Have",
    salesMix: 0.22197205,
    cumulativeSalesMix: 10.914031,
    penetration: 98.3871,
    totalScore: 4.5466466,
    revenueScore: 4.5466466,
    profitScore: 4.7282143,
    noPBTScore: 0.0,
    growthScore: 4.5317235,
    marketSizeScore: 2.520237,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 167.25455,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 589.2464,
    manufacturerWalkRate: 91.45,
    incrementalRevenueMix: 0.0,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "EMP REG_SNDWCH CKY_LMN_163GM_1PK",
    rank: 10,
    modelOutput: "Good-to-Have",
    salesMix: 0.2333593,
    cumulativeSalesMix: 11.147389,
    penetration: 100.0,
    totalScore: 4.495998,
    revenueScore: 4.495998,
    profitScore: 4.7424593,
    noPBTScore: 0.0,
    growthScore: 3.7113914,
    marketSizeScore: 3.9958396,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 1.0,
    marketGrowthScore: 423.03168,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 476.12354,
    manufacturerWalkRate: 71.44,
    incrementalRevenueMix: 0.0,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "EMP REG_SNDWCH CKY_CMBO_091GM_1PK",
    rank: 11,
    modelOutput: "Good-to-Have",
    salesMix: 0.116963275,
    cumulativeSalesMix: 11.264353,
    penetration: 95.96774,
    totalScore: 4.3974133,
    revenueScore: 4.3974133,
    profitScore: 4.7372584,
    noPBTScore: 0.0,
    growthScore: 3.8285172,
    marketSizeScore: 3.9958396,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 423.03168,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 304.98648,
    manufacturerWalkRate: 87.62,
    incrementalRevenueMix: 0.0,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "BMS REG_SOPA CRK_SLT_150GM_1PK",
    rank: 12,
    modelOutput: "Good-to-Have",
    salesMix: 0.24077329,
    cumulativeSalesMix: 11.505126,
    penetration: 100.0,
    totalScore: 4.3564873,
    revenueScore: 4.3564873,
    profitScore: 4.670556,
    noPBTScore: 0.0,
    growthScore: 4.0464883,
    marketSizeScore: 1.3865148,
    capacityConstraintScore: 5.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 142.54059,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 257.24628,
    manufacturerWalkRate: 37.41,
    incrementalRevenueMix: 0.0,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "CMX REG_WFR CKY_CHOC_042GM_1PK",
    rank: 13,
    modelOutput: "Good-to-Have",
    salesMix: 0.172061,
    cumulativeSalesMix: 11.677188,
    penetration: 98.3871,
    totalScore: 4.3365893,
    revenueScore: 4.3365893,
    profitScore: 4.660381,
    noPBTScore: 0.0,
    growthScore: 0.0,
    marketSizeScore: 1.864288,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 321.43985,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 238.64014,
    manufacturerWalkRate: 47.780003,
    incrementalRevenueMix: 0.0,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "EMP REG_SNDWCH CKY_CHOC_171GM_1PK",
    rank: 14,
    modelOutput: "Good-to-Have",
    salesMix: 0.18694673,
    cumulativeSalesMix: 11.864134,
    penetration: 97.58065,
    totalScore: 4.308552,
    revenueScore: 4.308552,
    profitScore: 3.1972144,
    noPBTScore: 0.0,
    growthScore: 3.3744857,
    marketSizeScore: 3.9958396,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 423.03168,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 210.3796,
    manufacturerWalkRate: 38.45,
    incrementalRevenueMix: 0.0,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "CMX REG_WFR CKY_STRAWBRY_042GM_1PK",
    rank: 15,
    modelOutput: "Good-to-Have",
    salesMix: 0.19439219,
    cumulativeSalesMix: 12.058526,
    penetration: 98.3871,
    totalScore: 4.262199,
    revenueScore: 4.262199,
    profitScore: 4.614254,
    noPBTScore: 0.0,
    growthScore: 0.0,
    marketSizeScore: 1.864288,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 321.43985,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 178.53783,
    manufacturerWalkRate: 31.64,
    incrementalRevenueMix: 0.0,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "EMP REG_SNDWCH CKY_CMBO_171GM_1PK",
    rank: 16,
    modelOutput: "Good-to-Have",
    salesMix: 0.20870516,
    cumulativeSalesMix: 12.267231,
    penetration: 98.3871,
    totalScore: 4.16791,
    revenueScore: 4.16791,
    profitScore: 3.1972144,
    noPBTScore: 0.0,
    growthScore: 3.352779,
    marketSizeScore: 3.9958396,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 423.03168,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 115.833984,
    manufacturerWalkRate: 19.12,
    incrementalRevenueMix: 0.0,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "GAC MAR_TRAD CKY_VANIL_116GM_1PK",
    rank: 17,
    modelOutput: "Good-to-Have",
    salesMix: 0.006143922,
    cumulativeSalesMix: 12.2733755,
    penetration: 41.935482,
    totalScore: 3.8624337,
    revenueScore: 3.8624337,
    profitScore: 3.1972144,
    noPBTScore: 0.0,
    growthScore: 1.3955592,
    marketSizeScore: 3.147922,
    capacityConstraintScore: 5.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 267.60278,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 34.14762,
    manufacturerWalkRate: 81.61,
    incrementalRevenueMix: 0.0,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "GIR CHO_SNDWCH CKY_CHOC_114GM_1PK",
    rank: 18,
    modelOutput: "Good-to-Have",
    salesMix: 0.011857038,
    cumulativeSalesMix: 12.285233,
    penetration: 93.548386,
    totalScore: 3.8554244,
    revenueScore: 3.8554244,
    profitScore: 4.4831095,
    noPBTScore: 0.0,
    growthScore: 0.0,
    marketSizeScore: 3.9958396,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 423.03168,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 33.270226,
    manufacturerWalkRate: 91.909996,
    incrementalRevenueMix: 0.0,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "MMT CLA_CHOC CVR CKY_CHOC_044GM_1PK",
    rank: 19,
    modelOutput: "Good-to-Have",
    salesMix: 0.27432477,
    cumulativeSalesMix: 12.559557,
    penetration: 100.0,
    totalScore: 3.8529372,
    revenueScore: 3.8529372,
    profitScore: 4.4609504,
    noPBTScore: 0.0,
    growthScore: 4.216298,
    marketSizeScore: 1.7096279,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 149.16565,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 32.592033,
    manufacturerWalkRate: 4.16,
    incrementalRevenueMix: 0.0,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "GAC MAR_TRAD CKY_VANIL_200GM_1PK",
    rank: 20,
    modelOutput: "Good-to-Have",
    salesMix: 0.025897324,
    cumulativeSalesMix: 12.585455,
    penetration: 63.709675,
    totalScore: 3.7708588,
    revenueScore: 3.7708588,
    profitScore: 4.285036,
    noPBTScore: 0.0,
    growthScore: 0.0,
    marketSizeScore: 3.147922,
    capacityConstraintScore: 5.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 267.60278,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 23.334507,
    manufacturerWalkRate: 20.1,
    incrementalRevenueMix: 0.0,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "GVY VAR_SRTDS CKY_VTY_270GM_1PK",
    rank: 21,
    modelOutput: "Good-to-Have",
    salesMix: 0.0034636061,
    cumulativeSalesMix: 12.588919,
    penetration: 35.48387,
    totalScore: 3.7457604,
    revenueScore: 3.7457604,
    profitScore: 4.4107537,
    noPBTScore: 0.0,
    growthScore: 0.0,
    marketSizeScore: 1.5217292,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 1.0,
    marketGrowthScore: 357.34634,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 20.72119,
    manufacturerWalkRate: 74.33,
    incrementalRevenueMix: 0.0,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "ARI FRU_MARSHMLW CKY_FRT_130GM_1PK",
    rank: 22,
    modelOutput: "Good-to-Have",
    salesMix: 5.3309454e-6,
    cumulativeSalesMix: 12.588924,
    penetration: 0.80645156,
    totalScore: 3.2786143,
    revenueScore: 3.2786143,
    profitScore: 4.2006965,
    noPBTScore: 0.0,
    growthScore: 0.0,
    marketSizeScore: 1.4880388,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 282.9105,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 0.6609538,
    manufacturerWalkRate: 35.010002,
    incrementalRevenueMix: 80.61789,
    incrementalNoPBTMix: 0.0,
  },
  {
    skuName: "EMP NOC_SNDWCH CKY_DRK CHOC_109GM_1PK",
    rank: 23,
    modelOutput: "Good-to-Have",
    salesMix: 1.5738982e-5,
    cumulativeSalesMix: 12.588939,
    penetration: 4.032258,
    totalScore: 3.2376883,
    revenueScore: 3.2376883,
    profitScore: 3.1972144,
    noPBTScore: 0.0,
    growthScore: 1.2612491,
    marketSizeScore: 3.9958396,
    capacityConstraintScore: 0.0,
    operativeComplexityScore: 5.0,
    marketGrowthScore: 423.03168,
    inventoryTurnScore: 0.0,
    salesVelocityPerMonth: 0.2448013,
    manufacturerWalkRate: 21.960001,
    incrementalRevenueMix: 19.382105,
    incrementalNoPBTMix: 0.0,
  },
];
const SummaryCardData = [
  { title: "Store Universe", icon: Cart, value: 81 },
  { title: "Total SKUs Sold", icon: Cart, value: 81 },
  { title: "Recommended Must have SKU", icon: Skuicon, value: 19 },
  { title: "Recommended Good to have SKU", icon: Magnifyicon, value: 19 },
];

const exportObject = {
  anchorFilter,
  anchorFiltersBrand,
  geoFilterV2Config,
  productFilterV2Config,
  quadrantFilterV2Config,
  tableHeading,
  tableSkeletonData,
  SummaryCardData,
  popupTableData,
};

export default exportObject;
