import { Grid } from "@mui/material";
import React, { useState } from "react";
import { LightBlueBtn } from "../../../../../styles/Common.Styled";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAssortmentRunSimulationFailure,
  fetchAssortmentRunSimulationRequest,
  fetchAssortmentRunSimulationSuccess,
} from "../../../../../store/actions//AssortmentTool/assortmentFilters.action";
import MixErrorPopup from "../../../../../components/MixErrorPopup/MixErrorPopup";
import { useHistory } from "react-router-dom";
import { SCENARIO_LIBRARY } from "../../../../../router/CONSTANTS";
import { fetchApi } from "../../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import { toast } from "../../../../../store/actions/toast.action";
import { API_URL } from "../../../../../store/actions/constants";

const RunSimulation = ({ data, val, isViewEdit }) => {
  const dispatch = useDispatch();
  const { scenarioName, uid, includeWalkRate } = useSelector(
    (state: any) => state.AssortmentFiltersReducer
  );
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();
  const runSimulation = () => {
    if (val["sum"] !== 100) {
      setErrorPopup(true);
      setErrorMessage(
        "The sum of Assigned Weight and constraint should be equal to 100%"
      );
    } else if (scenarioName == "") {
      setErrorPopup(true);
      setErrorMessage("Scenario name can not be empty");
    } else {
      if (isViewEdit) {
        const updateData = data;
        let payload = {
          scenarioName: scenarioName,
          ...updateData,
        };
        delete payload.default;
        let editPayload = {
          ...payload,
          uid: uid,
          includeWalkRate: includeWalkRate,
        };
        dispatch(fetchAssortmentRunSimulationRequest());
        dispatch(
          fetchApi(
            editPayload,
            API_URL.ASSORTMENT.EDIT_ASSORTMENT_RUN_SIMULATION,
            null
          )
        ).then((res) => {
          if (
            res["data"]["message"] === "Updation of simualtion is in progress."
          ) {
            dispatch(fetchAssortmentRunSimulationSuccess(res.data));
            history.push(SCENARIO_LIBRARY);
          } else {
            dispatch(
              toast(
                "Something went wrong, please try again later.",
                true,
                3000,
                "error"
              )
            );
            dispatch(
              fetchAssortmentRunSimulationFailure(res["data"]["message"])
            );
          }
        });
      } else {
        let uniqenessCheckerPayload = {
          scenarioName: scenarioName,
        };
        dispatch(fetchAssortmentRunSimulationRequest());
        dispatch(
          fetchApi(
            uniqenessCheckerPayload,
            API_URL.ASSORTMENT.UNIQUE_ASSORTMENT_SCENARIO_NAME,
            null
          )
        ).then((res) => {
          if (res["data"]["status"] === 200) {
            const updateData = data;
            let payload = {
              scenarioName: scenarioName,
              ...updateData,
              includeWalkRate: includeWalkRate,
            };

            dispatch(
              fetchApi(
                payload,
                API_URL.ASSORTMENT.ASSORTMENT_CALCULATION_OUTPUT
                
              )
            ).then((res) => {
              if (res["data"]["message"] === "Simualtion is in progress.") {
                dispatch(fetchAssortmentRunSimulationSuccess(res.data));
                history.push(SCENARIO_LIBRARY);
              } else {
                dispatch(
                  toast(
                    "Something went wrong, please try again later.",
                    true,
                    3000,
                    "error"
                  )
                );
                dispatch(
                  fetchAssortmentRunSimulationFailure(res["data"]["message"])
                );
              }
            });
          } else {
            setErrorMessage("Scenario name already exist in database.");
            setErrorPopup(true);
            dispatch(
              toast(
                "Something went wrong, please try again later.",
                true,
                3000,
                "error"
              )
            );
            dispatch(
              fetchAssortmentRunSimulationFailure(res["data"]["message"])
            );
          }
        });
      }
    }
  };

  return (
    <Grid
      justifyContent="flex-end"
      container
      spacing={1}
      sx={{ marginTop: "20px" }}
    >
      <LightBlueBtn color="primary" onClick={() => runSimulation()}>
        Run Simulation
      </LightBlueBtn>
      {errorPopup && (
        <MixErrorPopup
          onCloseHandler={() => setErrorPopup(false)}
          errorMessage={errorMessage}
        />
      )}
    </Grid>
  );
};

export default RunSimulation;
