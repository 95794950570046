import { TextField } from "@material-ui/core";
import {
  styled,
  Table,
  TableCell,
  tableCellClasses,
  TableRow,
  Button,
  Box,
  CardContent,
} from "@mui/material";

export const StyledTableContainer = styled(Box)`
  width: 1038px;
  height: 327.27px;
  top: 981.73px;
  left: 347px;
  overflow: hidden;
`;

export const StyledGridItem = styled(Box)`
width: '101px',
height: '20px',
top: '882px',
left: '1003px',
padding: '8px 10px', // Corrected padding syntax
borderRadius: '8px',
color: '#FFFFFF',
backgroundcolor: '#08509A',
`;

const StyledTable = styled(Table)(() => ({
  alignContent: "center",
}));

const CardContentRight = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  padding: "0px",
  margin: "0px",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F5FE",
    justifyContent: "center",
  },
  color: theme.palette.common.black,
  borderBottom: "2px solid #D3D3D3",
  fontFamily: "Inter",
  textAlign: "center",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F5FE",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
}));

const BlackTextBtn = styled(Button)(({ theme }) => ({
  textTransform: "inherit",
  fontSize: 14,
  color: theme.palette.common.black,
}));

const StyledTableTextField = styled(TextField)`
  text-align:center;
  & input {
    box-sizing: border-box;
    /* Additional styling for input[type="number"] */
    &::placeholder {
      color: red; /* Customize the placeholder color as needed */
    }
  }
  & .MuiOutlinedInput-root {
    width: 90px;
    height: 35px;
    /* Additional styling for MuiOutlinedInput-root */
  }

  /* Additional styling for input[type="number"] */
  & input[type="number"] {
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &:hover::-webkit-inner-spin-button,
    &:hover::-webkit-outer-spin-button {
      background-color: none;
    }
  }
`;

export {
  StyledTable,
  StyledTableCell,
  StyledTableRow,
  BlackTextBtn,
  StyledTableTextField,
  CardContentRight,
};
