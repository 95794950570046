import { styled } from "@mui/material/styles";
import { Button, ListItem } from "@mui/material";
import { Typography, Card, Box, Slider } from "@mui/material";
import { TextField } from "@material-ui/core";
import Paper from "@mui/material/Paper";

const BackBtn = styled(Button)(({ theme }) => ({
  backgroundColor: "#25d4cf",
  color: theme.palette.common.white,
  height: "100%",
  border: "5px solid #fff",
  width: "100%",
  "&:hover": {
    backgroundColor: theme.palette.common.white,
    color: "#25d4cf",
    border: "5px solid #fff",
  },
}));

const Title = styled(Typography)(() => ({
  fontSize: 16,
  color: "#25d4cf",
}));

const SubTitle = styled(Typography)(() => ({
  fontSize: 16,
  color: "#858C94",
  fontStyle: "italic",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  width: "100%",
  "&.MuiInputBase-multiline, .MuiOutlinedInput-multiline": {
    padding: 10,
  },
}));

const BoxBorder = styled(Box)(({ theme }) => ({
  color: "#858C94",
  border: 1,
  borderColor: "#858C94",
  borderStyle: "solid",
  borderRadius: 5,
  padding: 10,
  width: "100%",
  backgroundColor: theme.palette.common.white,
}));

const GreyText = styled(Typography)(() => ({
  color: "#858C94",
  fontSize: "14px",
}));

const ProcessBar = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: 10,
  height: 5,
  width: 0,
  opacity: 1,
  transition: "1s ease-in-out 0.3s",
}));

const ListItemButton = styled(ListItem)(({ theme }) => ({
  color: "black",
  "&:hover": {
    color: "#007FE0",
    cursor: "pointer",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  backgroundColor: "white",
  color: "black",
}));

const SaveButton = styled(Button)(({ theme }) => ({
  color: "#25d4cf",
  height: "100%",
}));

const CancelButton = styled(Button)(({ theme }) => ({
  color: "#858C94",
  height: "100%",
}));

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
  height: 2,
  margin: "25px 0px 0px 0px",
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 28,
    width: 28,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "back",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

const CustomInputField = styled(TextField)(() => ({
  width: "90px",
  margin: "5px",
}));

const CustomInputFieldWithBorder = styled(TextField)(() => ({
  margin: "5px",
}));

const ColorPickerBox = styled(Box)<{ color: string, width?, height? }>(({ color, width=60, height=55}) => ({
  border: "1px solid #858C94",
  background: color,
  height,
  width,
}));

export {
  BackBtn,
  Title,
  SubTitle,
  StyledCard,
  StyledTextField,
  BoxBorder,
  GreyText,
  ProcessBar,
  ListItemButton,
  Item,
  SaveButton,
  CancelButton,
  IOSSlider,
  CustomInputField,
  CustomInputFieldWithBorder,
  ColorPickerBox,
};
