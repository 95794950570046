
import { API } from "../../../util/helper";
import {
  FETCH_ASSORTMENT_SIMULATION_REQUEST,
  FETCH_ASSORTMENT_SIMULATION_SUCCESS,
  FETCH_ASSORTMENT_SIMULATION_FAILURE,
  FETCH_ASSORTMENT_SIZE_OF_PRICE_SUCCESS,
  FETCH_ASSORTMENT_MUST_HAVE_SKU_SUCCESS,
  FETCH_ASSORTMENT_QUADRANT_CHART_DATA_REQUEST,
  FETCH_ASSORTMENT_QUADRANT_CHART_DATA_SUCCESS,
  FETCH_ASSORTMENT_QUADRANT_CHART_DATA_FAILURE,
  FETCH_ASSORTMENT_LIST_TABLE_DATA_REQUEST,
  FETCH_ASSORTMENT_LIST_TABLE_DATA_SUCCESS,
  FETCH_ASSORTMENT_LIST_TABLE_DATA_FAILURE,
  FETCH_ASSORTMENT_SUMMARY_DATA_REQUEST,
  FETCH_ASSORTMENT_SUMMARY_DATA_SUCCESS,
  FETCH_ASSORTMENT_SUMMARY_DATA_FAILURE,
  SET_VIEW_MODE,
  SET_MAX_SOP_MIX_BAR_VAL,
  BACKEND_URL,
} from "../constants";

export const setViewMode = (data) => ({
  type: SET_VIEW_MODE,
  payload: data,
});

export const setMaxSopValue = (data) => ({
  type: SET_MAX_SOP_MIX_BAR_VAL,
  payload: data,
});

export const fetchAssortmentSimulationRequest = (payload) => ({
  type: FETCH_ASSORTMENT_SIMULATION_REQUEST,
  payload,
});

export const fetchAssortmentSimulationSuccess = (data) => ({
  type: FETCH_ASSORTMENT_SIMULATION_SUCCESS,
  payload: data,
});

export const fetchAssortmentSimulationFailure = (error) => ({
  type: FETCH_ASSORTMENT_SIMULATION_FAILURE,
  payload: error,
});

export const fetchAssortmentSizeOfPriceSuccess = (data) => ({
  type: FETCH_ASSORTMENT_SIZE_OF_PRICE_SUCCESS,
  payload: data,
});

export const fetchAssortmentMustHaveSkuSuccess = (data) => ({
  type: FETCH_ASSORTMENT_MUST_HAVE_SKU_SUCCESS,
  payload: data,
});

// Assortment List Table
export const fetchAssortmentListTableDataRequest = () => ({
  type: FETCH_ASSORTMENT_LIST_TABLE_DATA_REQUEST,
});

export const fetchAssortmentListTableDataSuccess = (data) => ({
  type: FETCH_ASSORTMENT_LIST_TABLE_DATA_SUCCESS,
  payload: data,
});

export const fetchAssortmentListTableDataFailure = (error) => ({
  type: FETCH_ASSORTMENT_LIST_TABLE_DATA_FAILURE,
  payload: error,
});

// Summary Data
export const fetchAssortmentSummaryDataRequest = () => ({
  type: FETCH_ASSORTMENT_SUMMARY_DATA_REQUEST,
});

export const fetchAssortmentSummaryDataSuccess = (data) => ({
  type: FETCH_ASSORTMENT_SUMMARY_DATA_SUCCESS,
  payload: data,
});

export const fetchAssortmentSummaryDataFailure = (error) => ({
  type: FETCH_ASSORTMENT_SUMMARY_DATA_FAILURE,
  payload: error,
});

// Quadrant Data
export const fetchAssortmentQuadrantChartDataRequest = () => ({
  type: FETCH_ASSORTMENT_QUADRANT_CHART_DATA_REQUEST,
});

export const fetchAssortmentQuadrantChartDataSuccess = (data) => ({
  type: FETCH_ASSORTMENT_QUADRANT_CHART_DATA_SUCCESS,
  payload: data,
});

export const fetchAssortmentQuadrantChartDataFailure = (error) => ({
  type: FETCH_ASSORTMENT_QUADRANT_CHART_DATA_FAILURE,
  payload: error,
});

export const fetchAssortmentSummaryData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(fetchAssortmentSummaryDataRequest());

    try {
      const response = await API.post(
        `${BACKEND_URL}/api/${apiUrl}`,
        data
      );
      dispatch(fetchAssortmentSummaryDataSuccess(response.data));
    } catch (error) {
      dispatch(fetchAssortmentSummaryDataFailure(error.message));
    }
  };
};

export const fetchAssortmentSizeOfPriceData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(
      fetchAssortmentSimulationRequest({
        loaderForSizeOfPrize: true,
        loaderForListTable: true,
      })
    );

    try {
      const response = await API.post(
        `${BACKEND_URL}/api/${apiUrl}`,
        data
      );
      console.log("sizeeee", response)
      dispatch(fetchAssortmentSizeOfPriceSuccess(response.data));
    } catch (error) {
      dispatch(fetchAssortmentSimulationFailure(error.message));
    }
  };
};

export const fetchAssortmentMustHaveSkuData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(
      fetchAssortmentSimulationRequest({
        loaderForMustHaveSku: true,
      })
    );

    try {
      const response = await API.post(
        `${BACKEND_URL}/api/output/${apiUrl}`,
        data
      );
      dispatch(fetchAssortmentMustHaveSkuSuccess(response.data));
    } catch (error) {
      dispatch(fetchAssortmentSimulationFailure(error.message));
    }
  };
};

export const fetchAssortmentQuadrantChartData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(fetchAssortmentQuadrantChartDataRequest());

    try {
      const response = await API.post(
        `${BACKEND_URL}/api/output/${apiUrl}`,
        data
      );
      dispatch(fetchAssortmentQuadrantChartDataSuccess(response.data));
    } catch (error) {
      dispatch(fetchAssortmentQuadrantChartDataFailure(error.message));
    }
  };
};

export const fetchAssortmentListTableData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(fetchAssortmentListTableDataRequest());
    try {
      const response = await API.post(
        `${BACKEND_URL}/api/output/${apiUrl}`,
        data
      );
      dispatch(fetchAssortmentListTableDataSuccess(response.data));
    } catch (error) {
      dispatch(fetchAssortmentListTableDataFailure(error.message));
    }
  };
};
