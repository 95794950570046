import { combineReducers } from "redux";
import common from "./common.reducer";
import theme from "./theme.reducer";
import anchorFilter from "./anchorFilter.reducer";
import geoFilter from "./geoFilter.reducer";
import productFilter from "./productFilter.reducer";
import twentySecMarketCheck from "./home/twentySecMarketCheck.reducer";
import APIPredictivePepsiVsNonCompetitorsChart from "./APIPredictiveDashboard/pepsiVsNonCompetitors.reducer";
// import ProfitParabolaLinearRegression from "../../pages/ProfitParabolaDashboard/store/ProfitParabolaDashboard.reducer";
import toast from "./toast.reducer";
// import SupermarketPepsicoBaseline from "../../pages/SupermarketDashboard/store/SupermarketDashboard.reducer";
import CreateNotification from "./AlertAndNotification/createNotification.reducer";
import DeleteNotification from "./AlertAndNotification/deleteNotification.reducer";
import DismissNotification from "./AlertAndNotification/dismissNotification.reducer";
import GetAllNotification from "./AlertAndNotification/getNotification.reducer";
import User from "./user.reducer";
import FavoriteDash from "./YourDashboard/favoriteDash.reducer";
import SavedDash from "./YourDashboard/savedDash.reducer";
import TrendingPage from "./YourDashboard/trendingPage.reducer";
// import BrandLadderLoader from "../../pages/BrandLadder/store/brandLadder.reducer";
// import {
//   default as HistoricPrice,
//   default as HistoricPriceLocalFilter,
// } from "../../pages/HistoricPriceAndCrossPriceElasticity/store/historicPrice.reducer";
// import MacroEconDashboard from "../../pages/MacroEconDashboard/store/macroEcon.reducer";
// import PredictivePackEconomics from "../../pages/PredictivePackEconomics/store/PredictivePackEconomics.reducer";
// import PricingPotentialLocalFilter from "../../pages/PricingPotential/store/pricingPotential.reducer";
// import RevenueMap from "../../pages/RevenueMap/store/revenueMap.reducer";
// import SellOutDashboard from "../../pages/SellOutDashboard/store/revenueMap.reducer";
import pepsicoFilter from "./pepsicoFilter.reducer";
// import PriceSettingTool from "../../pages/PriceSettingTool/store/priceSettingTool.reducer";
// import ElasticityPricingTrack from "../../pages/ElasticityPricingTrack/store/elasticityPricing.reducer";
// import PricePianoDataLoader from "../../pages/PriceRangePiano/store/PriceRangePiano.reducer";
// import PriceLadderDataLoader from "../../pages/PriceLadder/store/PriceLadder.reducer";
// import GeoXrefDataLoader from "../../pages/GeoXREF/store/GeoXref.reducer";
// import ProdXrefDataLoader from "../../pages/ProductXREF/store/ProdXref.reducer";
// import ApiPredictive from "../../pages/APIPredictiveDashboard/store/apiPredictive.reducer";
// import DataSourceLoader from "../../pages/DataSourceRefresh/store/DataSourceRefresh.reducer";
import AssortmentFiltersReducer from "./AssortmentTool/assortmentFilters.reducer";
import UserInputReducer from "./userInput.reducer";
import assortmentDashboardReducer from "./AssortmentTool//assortmentDashboard.reducer";
import allFilter from "./ExecutionTool/allFilter.reducer";
import executionUserInputs from "./ExecutionTool/userInput.reducer";
import overviewscore from "./ExecutionTool/overviewscore.reducer";
import portfolioGeoLevelReducer from "./ExecutionTool/portfolioGeoLevelSummary.reducer"
import {ScenarioLibraryReducer} from "./ScenarioLibrary/scenarioLibrary.reducer"
import mixSimulationAllFilter from "./MixSimulationTool/mixSimulationFilters.reducer";
import MixPortfolioReducer from "./MixSimulationTool/mixPortfolio.reducer";
import MixGeoLevelReducer from "./MixSimulationTool/mixGeoLevel.reducer";
import mixSimulationUserInput from "./MixSimulationTool/mixsimulationUserInputs.reducer";
import mixSimulationConstraintTable from "./MixSimulationTool/mixSimulationConstraintTable.reducer";
import MixUserConstraintsReducer from "./MixSimulationTool/mixUserConstraints.reducer";
import MixSummaryReducer from "./MixSimulationTool/mixSummary.reducer";
import portfolioGeoLevelFilter from './ExecutionTool/portfolioGeoLevelFilters.reducer';

const rootReducer = combineReducers({
  theme,
  common,
  toast,
  geoFilter,
  productFilter,
  anchorFilter,
  twentySecMarketCheck,
  APIPredictivePepsiVsNonCompetitorsChart,
  // ProfitParabolaLinearRegression,
  TrendingPage,
  FavoriteDash,
  SavedDash,
  // SupermarketPepsicoBaseline,
  CreateNotification,
  GetAllNotification,
  DismissNotification,
  DeleteNotification,
  User,
  // MacroEconDashboard,
  // RevenueMap,
  // SellOutDashboard,
  pepsicoFilter,
  // HistoricPrice,
  // HistoricPriceLocalFilter,
  // PricingPotentialLocalFilter,
  // PredictivePackEconomics,
  // BrandLadderLoader,
  // PriceSettingTool,
  // ElasticityPricingTrack,
  // PricePianoDataLoader,
  // PriceLadderDataLoader,
  // GeoXrefDataLoader,
  // ProdXrefDataLoader,
  // ApiPredictive,
  // DataSourceLoader,
  AssortmentFiltersReducer,
  UserInputReducer,
  assortmentDashboardReducer,
  allFilter,
  portfolioGeoLevelFilter,
  executionUserInputs,
  overviewscore,
  portfolioGeoLevelReducer,
  ScenarioLibraryReducer,
  mixSimulationAllFilter,
  MixPortfolioReducer,
  MixGeoLevelReducer,
  mixSimulationUserInput,
  mixSimulationConstraintTable,
  MixUserConstraintsReducer,
  MixSummaryReducer
});

export default rootReducer;
