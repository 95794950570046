import { API } from "../../../util/helper";
import {
  FETCH_ASSORTMENT_PRODUCT_FILTERS_REQUEST,
  FETCH_ASSORTMENT_PRODUCT_FILTERS_SUCCESS,
  FETCH_ASSORTMENT_PRODUCT_FILTERS_FAILURE,
  FETCH_ASSORTMENT_GEO_FILTERS_REQUEST,
  FETCH_ASSORTMENT_GEO_FILTERS_SUCCESS,
  FETCH_ASSORTMENT_GEO_FILTERS_FAILURE,
  FETCH_ASSORTMENT_QUADRANT_FILTERS_REQUEST,
  FETCH_ASSORTMENT_QUADRANT_FILTERS_SUCCESS,
  FETCH_ASSORTMENT_QUADRANT_FILTERS_FAILURE,
  FETCH_ASSORTMENT_RUN_SIMULATION_DATA_REQUEST,
  FETCH_ASSORTMENT_RUN_SIMULATION_DATA_SUCCESS,
  FETCH_ASSORTMENT_RUN_SIMULATION_DATA_FAILURE,
  FETCH_ASSORTMENT_PENETRATION_AVG_DATA_REQUEST,
  FETCH_ASSORTMENT_PENETRATION_AVG_DATA_SUCCESS,
  FETCH_ASSORTMENT_PENETRATION_AVG_DATA_FAILURE,
  FETCH_ASSORTMENT_MANUAL_CSV_DATA_REQUEST,
  FETCH_ASSORTMENT_MANUAL_CSV_DATA_SUCCESS,
  FETCH_ASSORTMENT_MANUAL_CSV_DATA_FAILURE,
  FETCH_ASSORTMENT_UPLOAD_CSV_DATA_REQUEST,
  FETCH_ASSORTMENT_UPLOAD_CSV_DATA_SUCCESS,
  FETCH_ASSORTMENT_UPLOAD_CSV_DATA_FAILURE,
  BACKEND_URL,
  GET_SCENARIO_DETAILS,
  LOADER_FOR_SCENARIO_DETAILS,
  LOADER_FOR_SCENARIO_SAVE,
  VIEW_EDIT_SCENARIO,
  SET_DEFAULT_PRODUCT_FILTERS,
  SET_DEFAULT_GEO_FILTERS,
  SET_SCENARIO_NAME,
  SET_PENETRATION_LIMIT_LEVEL,
  SET_PENETRATION_VIEW_MODE,
  SET_GEO_FILTER_VISIBILITY,
  SET_DEFAULT_QUADRANT_FILTERS,
  SET_WALK_RATE,
  RESET_STATE_FOR_ASSORTMENT_FILTERS
} from "../constants";

export const resetAssortmentFilterState = () => ({
  type: RESET_STATE_FOR_ASSORTMENT_FILTERS,
});

export const setAssortmentScenarioName = (data) => ({
  type: SET_SCENARIO_NAME,
  payload: data,
});

export const setPenetrationLimitLevel = (data) => ({
  type: SET_PENETRATION_LIMIT_LEVEL,
  payload: data,
});

export const setWalkRate = (data) => ({
  type: SET_WALK_RATE,
  payload: data,
});

export const setPenetrationViewMode = (data) => ({
  type: SET_PENETRATION_VIEW_MODE,
  payload: data,
});

export const setAssortmentGeoFilterVisibility = (data) => ({
  type: SET_GEO_FILTER_VISIBILITY,
  payload: data,
});

//Product Filters
export const fetchAssortmentProductFiltersRequest = () => ({
  type: FETCH_ASSORTMENT_PRODUCT_FILTERS_REQUEST,
});

export const fetchAssortmentProductFiltersSuccess = (data) => ({
  type: FETCH_ASSORTMENT_PRODUCT_FILTERS_SUCCESS,
  payload: data,
});

export const fetchAssortmentProductFiltersFailure = (error) => ({
  type: FETCH_ASSORTMENT_PRODUCT_FILTERS_FAILURE,
  payload: error,
});

export const setDefaultProductFilters = (data) => ({
  type: SET_DEFAULT_PRODUCT_FILTERS,
  payload: data,
});

//Geo Filters
export const fetchAssortmentGeoFiltersRequest = () => ({
  type: FETCH_ASSORTMENT_GEO_FILTERS_REQUEST,
});

export const fetchAssortmentGeoFiltersSuccess = (data) => ({
  type: FETCH_ASSORTMENT_GEO_FILTERS_SUCCESS,
  payload: data,
});

export const fetchAssortmentGeoFiltersFailure = (error) => ({
  type: FETCH_ASSORTMENT_GEO_FILTERS_FAILURE,
  payload: error,
});

export const setDefaultGeoFilters = (data) => ({
  type: SET_DEFAULT_GEO_FILTERS,
  payload: data,
});

//Quadrant Filters
export const fetchAssortmentQuadrantFiltersRequest = () => ({
  type: FETCH_ASSORTMENT_QUADRANT_FILTERS_REQUEST,
});

export const fetchAssortmentQuadrantFiltersSuccess = (data) => ({
  type: FETCH_ASSORTMENT_QUADRANT_FILTERS_SUCCESS,
  payload: data,
});

export const fetchAssortmentQuadrantFiltersFailure = (error) => ({
  type: FETCH_ASSORTMENT_QUADRANT_FILTERS_FAILURE,
  payload: error,
});

export const setDefaultQuadrantFilters = (data) => ({
  type: SET_DEFAULT_QUADRANT_FILTERS,
  payload: data,
});

//Run Simulation

export const fetchAssortmentRunSimulationRequest = () => ({
  type: FETCH_ASSORTMENT_RUN_SIMULATION_DATA_REQUEST,
});

export const fetchAssortmentRunSimulationSuccess = (data) => ({
  type: FETCH_ASSORTMENT_RUN_SIMULATION_DATA_SUCCESS,
  payload: data,
});

export const fetchAssortmentRunSimulationFailure = (error) => ({
  type: FETCH_ASSORTMENT_RUN_SIMULATION_DATA_FAILURE,
  payload: error,
});

// Avg Penetration Data

export const fetchAssortmentPenetrationAvgDataRequest = () => ({
  type: FETCH_ASSORTMENT_PENETRATION_AVG_DATA_REQUEST,
});

export const fetchAssortmentPenetrationAvgDataSuccess = (data) => ({
  type: FETCH_ASSORTMENT_PENETRATION_AVG_DATA_SUCCESS,
  payload: data,
});

export const fetchAssortmentPenetrationAvgDataFailure = (error) => ({
  type: FETCH_ASSORTMENT_PENETRATION_AVG_DATA_FAILURE,
  payload: error,
});

// Manual CSV Data

export const fetchAssortmentManualCSVDataRequest = () => ({
  type: FETCH_ASSORTMENT_MANUAL_CSV_DATA_REQUEST,
});

export const fetchAssortmentManualCSVDataSuccess = (data) => ({
  type: FETCH_ASSORTMENT_MANUAL_CSV_DATA_SUCCESS,
  payload: data,
});

export const fetchAssortmentManualCSVDataFailure = (error) => ({
  type: FETCH_ASSORTMENT_MANUAL_CSV_DATA_FAILURE,
  payload: error,
});

// Upload CSV Data

export const fetchAssortmentUploadCSVDataRequest = () => ({
  type: FETCH_ASSORTMENT_UPLOAD_CSV_DATA_REQUEST,
});

export const fetchAssortmentUploadCSVDataFailure = (error) => ({
  type: FETCH_ASSORTMENT_UPLOAD_CSV_DATA_FAILURE,
  payload: error,
});

export const setIsCsvUploaded = (data) => ({
  type: FETCH_ASSORTMENT_UPLOAD_CSV_DATA_SUCCESS,
  payload: data,
});

//Scenario Details

export const getScenarioDetails = (payload) => ({
  type : GET_SCENARIO_DETAILS,
  payload
});

export const loaderScenarioDetails = (payload) => ({
  type : LOADER_FOR_SCENARIO_DETAILS,
  payload 
});

export const loaderForScenarioSave = (payload) => ({
  type : LOADER_FOR_SCENARIO_SAVE,
  payload 
});

export const isViewEditScenario = (payload) => ({
  type : VIEW_EDIT_SCENARIO,
  payload
});

export const fetchAssortmentProductFiltersData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(fetchAssortmentProductFiltersRequest());

    try {
      const response = await API.post(
        `${BACKEND_URL}/api/filter/${apiUrl}`,
        data
      );
      dispatch(fetchAssortmentProductFiltersSuccess(response.data));
    } catch (error) {
      dispatch(fetchAssortmentProductFiltersFailure(error.message));
    }
  };
};

export const fetchAssortmentGeoFiltersData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(fetchAssortmentGeoFiltersRequest());

    try {
      const response = await API.post(
        `${BACKEND_URL}/api/filter/${apiUrl}`,
        data
      );
      dispatch(fetchAssortmentGeoFiltersSuccess(response.data));
    } catch (error) {
      dispatch(fetchAssortmentGeoFiltersFailure(error.message));
    }
  };
};

export const fetchQuadrantFiltersData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(fetchAssortmentQuadrantFiltersRequest());

    try {
      const response = await API.post(
        `${BACKEND_URL}/api/output/${apiUrl}`,
        data
      );

      dispatch(fetchAssortmentQuadrantFiltersSuccess(response.data));
    } catch (error) {
      dispatch(fetchAssortmentQuadrantFiltersFailure(error.message));
    }
  };
};

export const fetchRunSimlationData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(fetchAssortmentRunSimulationRequest());

    try {
      const response = await API.post(
        `${BACKEND_URL}/api/output/${apiUrl}`,
        data
      );

      dispatch(fetchAssortmentRunSimulationSuccess(response.data));
    } catch (error) {
      dispatch(fetchAssortmentRunSimulationFailure(error.message));
    }
  };
};

export const fetchPenetrationAvgData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(fetchAssortmentPenetrationAvgDataRequest());

    try {
      const response = await API.post(
        `${BACKEND_URL}/api/output/${apiUrl}`,
        data
      );

      dispatch(fetchAssortmentPenetrationAvgDataSuccess(response.data));
    } catch (error) {
      dispatch(fetchAssortmentPenetrationAvgDataFailure(error.message));
    }
  };
};


