import React, { useEffect, useState } from "react";
import DataGridTables from "../../../../components/ExecutionDataTable/DataGridTables";
import { columnsForPortfolio } from "./portfolioTableConfig";
import { styleForDataGrid, center, alignEnd } from "./portfolioTableStyled";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import { useCSVDownloader } from "react-papaparse";
import { handleDownloadCSV } from "../helper";
import {
  dispatchState,
  fetchApi,
  portfolioSummary,
  portfolioSummaryLoader,
  setPortfolioLevel,
} from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import PortfolioLevelFilter from "./PortfolioLevelFilter";
import Indicator from "../../../../components/Loader/Loader";
import { API_URL } from "../../../../store/actions/constants";

const Portfolio = () => {
  const filterableColumns = [
    "segments",
    "brand",
    "subBrand",
    "flavor",
    "packSize",
    "sku",
    "checkoutSku",
  ];
  const sortableColumns = [
    "revenuMix",
    "netRevenueSOP",
    "nonPBT_SOP",
    "avgStorePenetration",
    "executionScore",
    "mustHaveDistributionScore",
    "goodToHaveDistributionScore",
    "assortmentOverall",
    "mixScore",
    "netPriceRealisationScore",
    "priceChangeVsRestOfTheMarketScore",
    "priceChangeVsInflationScore",
    "priceScore",
    "exhibitionScore",
    "shelfSpaceScore",
    "shelfAndMerchandizingScore",
    "revenueMixResult",
    "profitMixResult",
    "volumeMixResult",
  ];
  const overallFilters = useSelector(
    (state: any) => state.allFilter.selectedFilters
  );
  const selectedTableLevelFilters = useSelector(
    (state: any) => state.portfolioGeoLevelFilter.selectedFilters
  );
  const loader = useSelector(
    (state: any) => state.portfolioGeoLevelFilter.portfolioLoading
  );
  const { loadingStateForAll } = useSelector(
    (state: any) => state.overviewscore
  );
  const executionUserInputs = useSelector(
    (state: any) => state.executionUserInputs
  );
  const levels = [
    {
      name: "Brand",
      value: "brand",
    },
    {
      name: "Segment",
      value: "segment",
    },
    {
      name: "SKU",
      value: "sku",
    },
  ];

  const dispatch = useDispatch();
  const [selectedLevel, setSelectedLevel] = useState("brand");
  const { CSVDownloader, Type } = useCSVDownloader();
  const {
    portfolioSummaryData,
    loaderForPortfolio,
    selectedLevelForPortfolio,
  } = useSelector((state: any) => state.portfolioGeoLevelReducer);

  useEffect(() => {
    setSelectedLevel(selectedLevelForPortfolio);
  }, [selectedLevelForPortfolio]);

  const createRunSimulationPayload = (
    overallSelectedFilters,
    userInputFilter,
    level?,
    levelFilters?
  ) => {
    let filterData = {
      overallFilters: {
        period: overallSelectedFilters.period,
        businessUnit: overallSelectedFilters.businessUnit,
        country: [overallSelectedFilters.country[0].toUpperCase()],
      },
      geo: {
        channel: overallSelectedFilters.channel,
        region: overallSelectedFilters.region,
        storeSegment: overallSelectedFilters.storeSegment,
        store: overallSelectedFilters.store,
      },
      portfolioGeo: {
        channel: levelFilters.channel,
        region: levelFilters.region,
        storeSegment: levelFilters.storeSegment,
      },
      userInputFilter: {
        decisionVariable: {
          assortmentScenarioName:
            userInputFilter.selectedData.assortment_scenario,
          mixScenarioName: userInputFilter.selectedData.mix_scenario,
        },
        assortmentsTargets: {
          goodToHaveSkus: [userInputFilter.weights.goodToHaveSku],
          mustHaveSKUs: [userInputFilter.weights.mustHaveSku],
        },
      },
      level: level,
    };
    return filterData;
  };

  const handleLevelChange = (event) => {
    const level = event.target.value;
    const dispatchActions: dispatchState = {
      loaderStateFetch: portfolioSummaryLoader(true),
    };
    dispatch(setPortfolioLevel(level));
    setSelectedLevel(level);
    const payload = createRunSimulationPayload(
      overallFilters,
      executionUserInputs,
      level,
      selectedTableLevelFilters
    );
    dispatch(
      fetchApi(
        payload,
        API_URL.EXECUTION.GET_PORTFOLIO_DATA_AFTER_EXECUTION,
        dispatchActions
      )
    )
      .then((res: any) => {
        dispatch(portfolioSummaryLoader(false));
        dispatch(portfolioSummary(res?.data?.portfolioData));
      })
      .catch((error) => {
        dispatch(portfolioSummaryLoader(false));
        console.log("ERROR", error);
      });
  };

  return (
    <Card style={{ border: "none", boxShadow: "none", position: "relative" }}>
      <Indicator position="absolute" show={loader && !loadingStateForAll} />
      <Grid
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        <Box style={alignEnd} sx={{ padding: "0.5rem" }}>
          <div style={{ ...center, width: "240px" }}>
            <span>Level: </span>
            <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
              <Select
                labelId="select-small-label"
                id="select-small"
                value={selectedLevel}
                label=""
                onChange={handleLevelChange}
              >
                {levels.map((element) => {
                  return (
                    <MenuItem value={element.value}>{element.name}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <Button
            disabled={!portfolioSummaryData.length}
            variant="text"
            sx={{
              color: "#2F5597",
              fontWeight: 500,
              border: "1px solid #2F5597",
            }}
          >
            <CSVDownloader
              filename={"execution_portfolioData.csv"}
              bom={true}
              config={{
                header: true,
              }}
              data={() => handleDownloadCSV(portfolioSummaryData, "portfolio")}
            >
              Download CSV
            </CSVDownloader>
          </Button>
        </Box>
      </Grid>
      <Grid style={{ marginBottom: "10px", marginLeft: "10px" }}>
        <Box style={{ display: "flex" }} sx={{ padding: "0.5rem" }}>
          <PortfolioLevelFilter />
        </Box>
      </Grid>
      <Divider />
      <DataGridTables
        isScenarioLibrary={false}
        filterableColumns={filterableColumns}
        sortableColumns={sortableColumns}
        loading={loaderForPortfolio}
        rows={loaderForPortfolio ? [] : portfolioSummaryData}
        columns={
          !portfolioSummaryData.length || loaderForPortfolio
            ? []
            : columnsForPortfolio
        }
        disableColumnMenu={true}
        style={styleForDataGrid}
        autoPageSize={true}
        rowCount={portfolioSummaryData.length}
        paginationMode="client"
        callback={(row) => row.uniqueId}
      ></DataGridTables>
    </Card>
  );
};

export default Portfolio;
