import {
  styled,
  Table,
  TableCell,
  tableCellClasses,
  TableRow,
  Button,
  IconButton,
} from "@mui/material";


import { DataGrid } from "@mui/x-data-grid";

const CustomDataGrid = styled(DataGrid)`
  border: 3px solid #D0D2D3 !important; /* Add border to the whole table */
`;

const CustomColumnHeader = styled("div")`
  background-color: #f1f5fe;
  color: black;
    display: flex;
  align-items: center;
`;

const CustomHeaderCell = styled("div")`
background-color: #f1f5fe;
  display: flex;
  align-items: center;
  font-family:"Inter";
  font-weight:600;
  font-size:14px;
  color:#333F48;
`;

const CustomFilterIcon = styled(IconButton)`
  margin-left: auto;
`;

const StyledTable = styled(Table)(() => ({
  border: "1px solid #D3D3D3",
  alignContent: "center",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F5FE",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "row",
    minWidth: 140,
  },
  color: theme.palette.common.black,
  border: "1px solid #D3D3D3",
  alignContent: "center",
  fontFamily: "Inter",
  weight: 600,
  justifyContent: "center",
  verticalAlign: "center",
  minHeight: 20,
  maxHeight: 20,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F5FE",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "white",
  },
}));

const BlackTextBtn = styled(Button)(({ theme }) => ({
  textTransform: "inherit",
  fontSize: 14,
  color: theme.palette.common.black,
}));

export {
  StyledTable,
  StyledTableCell,
  StyledTableRow,
  BlackTextBtn,
  CustomDataGrid,
  CustomColumnHeader,
  CustomHeaderCell,
  CustomFilterIcon,
};
