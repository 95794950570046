import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { closeIcon } from "../../../../assets/icons/common";
import { ViewEditButton } from "../MixScenarioLibrary/MixScenarioLibrary.styles";


const useStyles = makeStyles(() => ({
  darkText: {
    color: "#000000 !important",
  },
}));

const MixUserInputsPopup = ({ open, onCloseHandler, selectedFilters }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onCloseHandler}
      style={{
        zIndex: 100,
        margin: "auto",
        width: "100%",
        minHeight: "500px",
        maxHeight: "80vh",
      }}
    >
      <DialogTitle
        style={{ position: "relative" }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <img
          style={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }}
          src={closeIcon}
          alt="X"
          onClick={onCloseHandler}
        />
      </DialogTitle>
      <DialogContent>
      {`No Assortment Scenario created for ${selectedFilters.country[0]}, ${selectedFilters.period[0]} and ${selectedFilters.businessUnit[0]}.
       Select any other option`}
      </DialogContent>
      <DialogActions> <ViewEditButton onClick={onCloseHandler} autoFocus>
            okay
          </ViewEditButton></DialogActions>
    </Dialog>
  );
};

export default MixUserInputsPopup;
