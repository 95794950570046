import * as React from "react";
import AppMenu from "./components/AppMenu";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../themes/theme";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CssBaseline from "@material-ui/core/CssBaseline";
import { FooterText } from '../styles/Common.Styled';

import Appbar from "./components/Appbar/Appbar";
import {
  Drawer,
  FlotButton,
  DrawerHeader,
  Footer,
  MainContainer
} from "./Layout.Styled";
import { openSidebar } from "../store/actions";

const Layout: React.FC<{ children }> = ({ children }) => {
  const dispatch = useDispatch();
  const theme = useSelector(
    (state: { theme: { darkmode: boolean } }) => state.theme
  );
  const open = useSelector((state: any) => state.common.sidebarOpen);
  const handleDrawerToggle = () => {
    dispatch(openSidebar(!open));
  };

  return (
    <ThemeProvider theme={theme.darkmode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Box sx={{ display: "flex" }}>
        <Appbar />
        <Drawer variant="permanent" open={open}>
          <FlotButton
            onClick={handleDrawerToggle}
            data-testid="drawerToggleButton"
            open={open}
          >
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </FlotButton>
          <DrawerHeader />
          <AppMenu drawerState={open} />
        </Drawer>
        <MainContainer sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Box marginBottom={10}>
            {children}
            <Footer position="fixed" sx={{ top: "auto", bottom: 0 }}>
              {/* <FooterText>RevolutioM is Powered by Optimization Partners</FooterText> */}
            </Footer>
          </Box>
        </MainContainer>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
