import {
  GET_TRENDING_DASH,
  GET_TRENDING_DASH_SUCCESS,
  GET_TRENDING_DASH_FAILURE,
  POST_TRENDING,
  POST_TRENDING_SUCCESS,
  POST_TRENDING_FAILURE,
} from "../../actions/YourDashboard/constant";

let trendingPageInitialState = {
  data: null,
  loading: true,
  error: null,
  saveLoading: false,
  saveError: null
};

const TrendingPage = (
  state = trendingPageInitialState,
  action
) => {
  switch (action.type) {
    case GET_TRENDING_DASH:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case GET_TRENDING_DASH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case GET_TRENDING_DASH_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
      case POST_TRENDING:
        return {
          ...state,
          saveLoading: true,
          saveError: null,
        };
      case POST_TRENDING_SUCCESS:
        return {
          ...state,
          saveLoading: false,
          saveError: null,
        };
      case POST_TRENDING_FAILURE:
        return {
          ...state,
          saveLoading: false,
          saveError: action.payload,
        };
    default:
      return state;
  }
};

export default TrendingPage;
