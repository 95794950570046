import React, { useEffect, useState } from "react";
import { CardContent, Grid, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import {
  SectionTitle,
  SkuAvgValue,
  Title,
} from "./AsssignPenetrationTarget.Styled";
import { useDispatch, useSelector } from "react-redux";
import { setIsCsvUploaded } from "../../../../../store/actions//AssortmentTool/assortmentFilters.action";
import { ApplyBtn, ClearBtn } from "../../../../../styles/Common.Styled";
import Indicator from "../../../../../components/Loader/Loader";
import {
  fetchAssortmentMustHaveSkuData,
  fetchAssortmentSizeOfPriceData,
  setViewMode,
} from "../../../../../store/actions//AssortmentTool/assortmentDashboard.action";
import Skeletons from "../../../Skeletons/Skeletons";
import TextFieldInput from "../../CommonComponents/TextFieldInput/TextFieldInput";
import { formatInputValue, formatValue } from "../../CommonComponents/utils";
import { API_URL } from "../../../../../store/actions/constants";

const useStyles = makeStyles((theme) => ({
  asterik: { color: "red" },
}));

const AsssignPenetrationTarget = ({ onInputChange, geoFilters }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState({
    mustHaveSku: 0,
    goodToHaveSku: 0,
  });

  const handleInputChange = (fieldName, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
    onInputChange(fieldName, value);
  };

  const {
    uid,
    penetrationTargetAverage,
    loaderForAvgPenetration,
    penetrationViewMode,
    isCSVUploaded,
  } = useSelector((state) => state.AssortmentFiltersReducer);

  const applyPenetrationFilter = (val) => {
    let payload = {
      uid: `${uid}`,
      storeSegment: geoFilters.storeSegment,
      channel: geoFilters.channel,
      region: geoFilters.region,
      mustHave: Number(val.mustHaveSku) / 100,
      goodToHave: Number(val.goodToHaveSku) / 100,
    };

    dispatch(fetchAssortmentMustHaveSkuData(payload,API_URL.ASSORTMENT.MUST_HAVE_SKU_DATA));
    dispatch(
      fetchAssortmentSizeOfPriceData(
        payload,
        API_URL.ASSORTMENT.TABLE_AND_SIZE_OF_PRIZE_DATA
      )
    );
    dispatch(setViewMode(true));
    dispatch(setIsCsvUploaded(false));
  };

  useEffect(() => {
    const goodSKU = penetrationTargetAverage["goodToHaveSkuCurrentAverage"];
    const mustSKU = penetrationTargetAverage["mustHaveSkuCurrentAverage"];
    let updatedInputValues = {
      mustHaveSku:
        formatInputValue(mustSKU) > 100 ? 100 : formatInputValue(mustSKU),
      goodToHaveSku:
        formatInputValue(goodSKU) > 100 ? 100 : formatInputValue(goodSKU),
    };
    setInputValues(updatedInputValues);
    if (isCSVUploaded === true) {
      applyPenetrationFilter(updatedInputValues);
    }
  }, [penetrationTargetAverage]);

  const clearPenetrationFilter = () => {
    setInputValues({
      mustHaveSku: 0,
      goodToHaveSku: 0,
    });
  };

  return (
    <>
      <CardContent sx={{ backgroundColor: "#F1F5FE", color: "#fff" }}>
        <Grid
          style={{
            position: "relative",
          }}
          container
          spacing={3}
        >
          <Indicator position="absolute" show={loaderForAvgPenetration} />
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            item
          >
            <Grid item>
              <Title color="black">
                Assign Penetration (Distribution) Target (in %)
              </Title>
            </Grid>
            <Grid item>
              <Typography style={{ alignSelf: "flex-end" }}>
                <span style={{ fontSize: 10, fontWeight: 700, color: "black" }}>
                  *NOTE:{" "}
                </span>
                <span style={{ fontSize: 10, fontWeight: 400, color: "black" }}>
                  Assigned Penetration (Distribution) Target can individually be
                  0-100%
                </span>
              </Typography>
            </Grid>
          </Grid>

          {!penetrationViewMode ? (
            <Skeletons type={"PenetrationTarget"} />
          ) : (
            <>
              <Grid container item xs={12} spacing={3} alignItems="center">
                <Grid item xs={2}>
                  <SectionTitle color="black">
                    Must have SKU<span className={classes.asterik}>*</span>
                  </SectionTitle>
                </Grid>
                <Grid item xs={2}>
                  <TextFieldInput
                    defaultValue={inputValues.mustHaveSku}
                    callback={(value) =>
                      handleInputChange("mustHaveSku", value)
                    }
                    disabled={false}
                  />
                </Grid>
                <Grid flexDirection="row" item xs={2}>
                  <SectionTitle color="black">
                    Good to have SKU <span className={classes.asterik}>*</span>
                  </SectionTitle>
                </Grid>
                <Grid item xs={2}>
                  <TextFieldInput
                    defaultValue={inputValues.goodToHaveSku}
                    callback={(value) =>
                      handleInputChange("goodToHaveSku", value)
                    }
                    disabled={false}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={3} alignItems="center">
                <Grid item xs={2}>
                  <SectionTitle color="black">
                    Current Average<span className={classes.asterik}>*</span>
                  </SectionTitle>
                </Grid>
                <Grid item xs={2}>
                  <SkuAvgValue>
                    {formatValue(
                      penetrationTargetAverage["mustHaveSkuCurrentAverage"]
                    )}
                  </SkuAvgValue>
                </Grid>
                <Grid flexDirection="row" item xs={2}>
                  <SectionTitle color="black">
                    Current Average <span className={classes.asterik}>*</span>
                  </SectionTitle>
                </Grid>
                <Grid item xs={2}>
                  <SkuAvgValue>
                    {formatValue(
                      penetrationTargetAverage["goodToHaveSkuCurrentAverage"]
                    )}
                  </SkuAvgValue>
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                marginBottom={"15px"}
                marginRight={"35px"}
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <>
                  <Grid item>
                    <ApplyBtn
                      onClick={() => applyPenetrationFilter(inputValues)}
                    >
                      Apply
                    </ApplyBtn>
                  </Grid>
                  <Grid style={{ marginLeft: "10px" }} item>
                    <ClearBtn onClick={clearPenetrationFilter}>
                      Clear Filter
                    </ClearBtn>
                  </Grid>
                </>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </>
  );
};

export default AsssignPenetrationTarget;
