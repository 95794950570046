import { API } from "../../../util/helper";
import {
  MIX_GEO_LEVEL_TABLE_DATA_REQUEST,
  MIX_GEO_LEVEL_TABLE_DATA_SUCCESS,
  MIX_GEO_LEVEL_TABLE_DATA_FAILURE,
  SET_MIX_GEO_LEVEL,
  BACKEND_URL,
  RESET_MIX_SIMULATION_GEO_LEVEL_TABLE,
} from "../constants";

export const setMixGeoLevel = (payload) => ({
  type: SET_MIX_GEO_LEVEL,
  payload
});

export const fetchMixGeoLevelDataRequest = () => ({
  type: MIX_GEO_LEVEL_TABLE_DATA_REQUEST,
});

export const fetchMixGeoLevelDataSuccess = (data) => ({
  type: MIX_GEO_LEVEL_TABLE_DATA_SUCCESS,
  payload: data,
});

export const fetchMixGeoLevelDataFailure = (error) => ({
  type: MIX_GEO_LEVEL_TABLE_DATA_FAILURE,
  payload: error,
});

export const resetMixSimulationGeoLevelTableData = () => ({
  type: RESET_MIX_SIMULATION_GEO_LEVEL_TABLE,
});

export const fetchMixGeoLevelData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(fetchMixGeoLevelDataRequest());

    try {
      const response = await API.post(
        `${BACKEND_URL}/api/output/${apiUrl}`,
        data
      );
      dispatch(fetchMixGeoLevelDataSuccess(response.data));
    } catch (error) {
      dispatch(fetchMixGeoLevelDataFailure(error.message));
    }
  };
};
