import React from 'react'
import HighchartContainer from "../../../../components/HighchartContainer";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);


const goodToHave = "#F77B36"; 
const mustHaveSKU = "#F2C2A6"; 
const actualSales = "#1A99D5";


export const WaterfallChartAssortment: React.FC<{
  chartRef;
  data;
  chartConfig;
  id;
}> = ({ chartRef, data, chartConfig, id }) => {

  React.useEffect(() => {
    if (
      chartRef &&
      chartRef.current &&
      chartRef.current.chart &&
      data?.length > 0
    ) {
      const chart = chartRef.current.chart;
      while (chart.series.length) {
        chart.series[0].remove();
      }
      let chartData: any = [];
      console.log("data", data);
      data.map((item, i) => {
        if (i === 0 ) {
          chartData.push({
            name: item.name,
            y: item.value,
            percentVal: item.percentValue,
            color : item.color,
            isSum: i === data.length - 1,
            level: item.level
          });
        } else {
          chartData.push({
            name: item.name,
            y: item.value,
            percentVal: item.percentValue,
            color : item.color,
              level: item.level
          });
        }
      });

      chart.addSeries(
        { upColor: goodToHave, color: mustHaveSKU, data: [...chartData] },
        false
      );
      chart.redraw();
    }
  }, [data]);

  return (
    <>
      <HighchartContainer chartRef={chartRef} id={id}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartConfig}
          ref={chartRef}
        />
      </HighchartContainer>
    </>
  );
};