import React from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { BlackInputTitle, PrimaryBtn, Title } from "../../../styles/Common.Styled";
import Dropdown from "../../../components/Dropdown";
import { categories, countries } from "../../../mocks/common";
import { logo } from "../../../assets/images";
import { createUserAccount } from '../service';
import { useDispatch } from "react-redux";
import { toast } from "../../../store/actions/toast.action";
const useStyles = makeStyles((theme) => ({
  blueBG: {
    backgroundColor: "#F2F8FC !important",
  },
  greyBG: {
    backgroundColor: "#858C94 !important",
  },
}));

const UserCreation: React.FC = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = React.useState({
    "firstName": "",
    "lastName": "",
    "emailAddress": "",
    "phoneNumber": "",
    "country": "",
    "address": "",
    "role": "",
    "defaultCountry": "",
    "defaultCategory": "",
    "accountCountry": "",
    "accountCategory": ""
  })
  const classes = useStyles();

  const [southAmericaSelecteeCountries, setSouthAmericaSelectedCountries] =
    React.useState([]);
  const [northAmericaSelecteeCountries, setNorthAmericaSelectedCountries] =
    React.useState([]);
  const onChangeCountry = (e) => {
    const result = countries.filter(
      (c) => e.findIndex((x) => x === c.value) !== -1
    );
    setCountries(result);
  };
  const setCountries = (countries) => {
    setSouthAmericaSelectedCountries(
      countries.filter((x) => x.region === "south_america_mill")
    );
    setNorthAmericaSelectedCountries(
      countries.filter((x) => x.region === "north_america_mill")
    );
  };

  const onClickCreateAccount = async () => {
    createUserAccount(userData).then((res)=>{
      dispatch(toast('User added successfully', true, 2000, 'success'));
    }).catch(()=>{
      dispatch(toast('Something went wrong', true, 2000, 'error'));
    })
  }

  const onChangeInput = (e, field) => {
    const data = userData;
    data[field] = e.target.value;
    setUserData(data);
  }

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sm={6} className={classes.blueBG}>
          <Box sx={{ m: 5 }}>
            <img src={logo} alt={logo} height="45px" className="m-b-20" />

            <Grid container className="m-b-20">
              <Grid item xs={12} sm={6} key="firstName">
                <FormControl style={{ width: "100%" }}>
                  <BlackInputTitle>First Name</BlackInputTitle>
                  <FormControlLabel
                    defaultValue={userData.firstName}
                    name="firstName"
                    id="first-name"
                    label=""
                    control={<TextField InputProps={{ autoComplete: 'off' }} style={{ width: "100%" }} />}
                    onChange={(e) => onChangeInput(e, 'firstName')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} key="lastName">
                <FormControl style={{ width: "100%" }}>
                  <BlackInputTitle>Last Name</BlackInputTitle>
                  <FormControlLabel
                    defaultValue={userData.lastName}
                    name="lastName"
                    id="last-name"
                    label=""
                    control={<TextField InputProps={{ autoComplete: 'off' }} style={{ width: "100%" }} />}
                    onChange={(e) => onChangeInput(e, "lastName")}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="m-b-20">
              <Grid item xs={12} sm={6} key="email">
                <FormControl style={{ width: "100%" }}>
                  <BlackInputTitle>Email</BlackInputTitle>
                  <FormControlLabel
                    defaultValue={userData.emailAddress}
                    name="emailAddress"
                    id="email-address"
                    label=""
                    control={<TextField InputProps={{ autoComplete: 'off' }} style={{ width: "100%" }} />}
                    onChange={(e) => onChangeInput(e, "emailAddress")}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} key="country">
                <FormControl style={{ width: "100%" }}>
                  <BlackInputTitle>Country</BlackInputTitle>
                  <FormControlLabel
                    defaultValue={userData.country}
                    name="country"
                    id="country"
                    label=""
                    control={<TextField InputProps={{ autoComplete: 'off' }} style={{ width: "100%" }} />}
                    onChange={(e) => onChangeInput(e, "country")}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {/* <Title sx={{ mb: 1 }}>Find Pepsi Employee</Title>
            <Autocomplete
              disablePortal
              id="search-user-data"
              options={userNames}
              // sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField InputProps={{ autoComplete: 'off' }} {...params} label="Search user data" />
              )}
              sx={{ mb: 4 }}
            /> */}
            <Title sx={{ mb: 1 }}>Account Permission Settings</Title>
            <Grid container spacing={2} sx={{ mb: 4 }}>
              <Grid item xs={12} key="filterByCountry">
                <BlackInputTitle>Country Permissions</BlackInputTitle>
                <Dropdown
                  keyText="homepage-country-dropdown"
                  data={countries}
                  multiple={true}
                  multipleSelectionMsg="Muliple"
                  placeholder="Select Country"
                  defaultOption={[
                    ...northAmericaSelecteeCountries.map((c) => c.value),
                    ...southAmericaSelecteeCountries.map((c) => c.value),
                  ]}
                  onChange={onChangeCountry}
                />
              </Grid>
              <Grid item xs={12} key="filterByCategory">
                <BlackInputTitle>Category Permissions</BlackInputTitle>
                <Dropdown
                  keyText="homepage-country-dropdown"
                  data={categories}
                  placeholder="Select Category"
                  defaultOption={["Sweet"]}
                />
              </Grid>
            </Grid>
            <Title sx={{ mb: 1 }}>Default Permission Settings</Title>
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ mb: 4 }} key="filterByCountry">
                <BlackInputTitle>Country Permissions</BlackInputTitle>
                <Dropdown
                  keyText="homepage-country-dropdown"
                  data={countries}
                  multiple={true}
                  multipleSelectionMsg="Muliple"
                  placeholder="Select Country"
                  defaultOption={[
                    ...northAmericaSelecteeCountries.map((c) => c.value),
                    ...southAmericaSelecteeCountries.map((c) => c.value),
                  ]}
                  onChange={onChangeCountry}
                />
              </Grid>
              <Grid item xs={6} key="filterByCategory">
                <BlackInputTitle>Category Permissions</BlackInputTitle>
                <Dropdown
                  keyText="homepage-country-dropdown"
                  data={categories}
                  placeholder="Select Category"
                  defaultOption={["Sweet"]}
                />
              </Grid>
            </Grid>
            <Grid xs={12}>
              <FormControl sx={{ mb: 4 }}>
                <FormControlLabel
                  value="send-details-to-email"
                  control={<Checkbox />}
                  label="Send users details to your email address."
                  labelPlacement="end"
                />
              </FormControl>
            </Grid>
            <PrimaryBtn onClick={onClickCreateAccount}>Create Account</PrimaryBtn>
          </Box>
        </Grid >
        <Grid item sm={6} display={{ xs: "none", lg: "block" }} className={classes.greyBG} />
      </Grid >
    </Box >
  );
};

export default UserCreation;