import React, { useState } from "react";
import MixSkeletons from "../../MixSkeletons/MixSkeletons";
import { Grid } from "@mui/material";
import { FilterGrid } from "../../../ExecutionTool/components/ExecutionCommon.Styled";
import { useDispatch, useSelector } from "react-redux";
import { DropdownTitle } from "../../../../components/GeoFilters-V4/GeoFilters-V4.Styled";
import AssortmentDropdown from "../../../../components/AssortmentDropdown";
import {
  fetchMixSimulationConstraintTableData,
  fetchMixSimulationConstraintTableFilter,
  isMixSimulationConstraintTableDataChanged,
  loaderForMixScreen,
  setSelectedMixSimulationConstraintTableFilter,
} from "../../../../store/actions/MixSimulationTool/mixSimulationConstraintTable.action";
import SaveChangesPopup from "./SaveChangesPopup";
import {
  dispatchState,
  fetchApi,
} from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import Papa from "papaparse";
import { API_URL } from "../../../../store/actions/constants";

const FilterLabelMapper = {
  channel: "Channel",
  region: "Region",
  storeSegment: "Store Segment",
};
const MixTableFilters = () => {
  const filters = useSelector(
    (state: any) => state.mixSimulationConstraintTable.tableFilter
  );
  const filtersOptions = useSelector(
    (state: any) => state.mixSimulationConstraintTable.selectedFilters
  );
  const mixSimulationUserInputs = useSelector(
    (state: any) => state.mixSimulationUserInput
  );
  const selectedTableFilters = useSelector(
    (state: any) => state.mixSimulationConstraintTable.selectedFilters
  );
  const isDataChanged = useSelector(
    (state: any) => state.mixSimulationConstraintTable.isDataChanged
  );
  const intermediatetableData = useSelector(
    (state: any) => state.mixSimulationConstraintTable.intermediateTableData
  );

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const convertData = Papa.unparse(intermediatetableData);
  const file = new Blob([convertData], { type: "text/csv" });

  const formData = new FormData();
  formData.append("CsvFile", file);
  formData.append("Uid", mixSimulationUserInputs.uid);
  const onChangeGeoLevel = (filterName, selectionData) => {
    let payload = {
      uid: mixSimulationUserInputs.uid,
      default: false,
      channel: [],
      region: [],
      storeSegment: [],
    };
    switch (filterName) {
      case "channel":
        payload.channel = selectionData;
        dispatch(fetchMixSimulationConstraintTableFilter(payload, "region"));
        break;
      case "region":
        payload.channel = selectedTableFilters.channel;
        payload.region = selectionData;
        // payload.storeSegment=[]

        dispatch(
          fetchMixSimulationConstraintTableFilter(payload, "storeSegment")
        );
        if (!isDataChanged) {
          let tableDataPayload = {
            uid: mixSimulationUserInputs.uid,
            goal: mixSimulationUserInputs.selectedGoal,
            filtersSelected: true,
            channel: selectedTableFilters.channel[0],
            region: selectionData[0],
            storeSegment: selectedTableFilters.storeSegment[0],
          };
          dispatch(
            fetchMixSimulationConstraintTableData(
              tableDataPayload,
              API_URL.MIXSIMULATION.GET_MIX_CONTRAINT_TABLE_DATA
            )
          );
        } else {
          setOpen((isOpen) => !isOpen);
        }
        break;
      case "storeSegment":
        if (!isDataChanged) {
          let tableDataPayload = {
            uid: mixSimulationUserInputs.uid,
            goal: mixSimulationUserInputs.selectedGoal,
            filtersSelected: true,
            channel: selectedTableFilters.channel[0],
            region: selectedTableFilters.region[0],
            storeSegment: selectionData[0],
          };
          dispatch(
            fetchMixSimulationConstraintTableData(
              tableDataPayload,
              API_URL.MIXSIMULATION.GET_MIX_CONTRAINT_TABLE_DATA
            )
          );
        } else {
          setOpen((isOpen) => !isOpen);
        }
        break;
      default:
        break;
    }
    dispatch(
      setSelectedMixSimulationConstraintTableFilter({
        name: filterName,
        value: selectionData,
      })
    );
    console.log(isDataChanged);
  };

  const generateTableFilterPayload = (filterName, selectionData) => {
    // switch (filterName) {
    //     case "channel":
    //         return {
    //             uid: mixSimulationUserInputs.uid,
    //             goal: mixSimulationUserInputs.selectedGoal,
    //             filtersSelected: true,
    //             "channel": selectionData[0],
    //             "region": "",
    //             "storeSegment": ""
    //         }
    //     case "region":
    //         return {
    //             uid: mixSimulationUserInputs.uid,
    //             goal: mixSimulationUserInputs.selectedGoal,
    //             filtersSelected: true,
    //             "channel": selectedTableFilters.channel[0],
    //             "region": selectionData[0],
    //             "storeSegment": ""
    //         }
    //     case "storeSegment":
    //         return {
    //             uid: mixSimulationUserInputs.uid,
    //             goal: mixSimulationUserInputs.selectedGoal,
    //             filtersSelected: true,
    //             "channel": selectedTableFilters.channel[0],
    //             "region": selectedTableFilters.region[0],
    //             "storeSegment": selectionData[0]
    //         }
    //     default:
    //         return {
    //             uid: mixSimulationUserInputs.uid,
    //             goal: mixSimulationUserInputs.selectedGoal,
    //             filtersSelected: false,
    //             channel: "",
    //             region: "",
    //             storeSegment: ""
    //         }
    // }
  };

  const handleYes = () => {
    const dispatchAction: dispatchState = {
      loaderStateFetch: loaderForMixScreen(true),
    };
    dispatch(
      fetchApi(
        formData,
        API_URL.MIXSIMULATION.SAVE_MIX_CONSTRIANT_TABLE_DATA,
        dispatchAction
      )
    ).then((res) => {
      dispatch(isMixSimulationConstraintTableDataChanged(false));
      let tableDataPayload = {
        uid: mixSimulationUserInputs.uid,
        goal: mixSimulationUserInputs.selectedGoal,
        filtersSelected: true,
        channel: selectedTableFilters.channel[0],
        region: selectedTableFilters.region[0],
        storeSegment: selectedTableFilters.storeSegment[0],
      };
      dispatch(
        fetchMixSimulationConstraintTableData(
          tableDataPayload,
          API_URL.MIXSIMULATION.GET_MIX_CONTRAINT_TABLE_DATA
        )
      );
      dispatch(loaderForMixScreen(false));
    });
  };

  const handleNo = () => {
    dispatch(isMixSimulationConstraintTableDataChanged(false));
    let tableDataPayload = {
      uid: mixSimulationUserInputs.uid,
      goal: mixSimulationUserInputs.selectedGoal,
      filtersSelected: true,
      channel: selectedTableFilters.channel[0],
      region: selectedTableFilters.region[0],
      storeSegment: selectedTableFilters.storeSegment[0],
    };
    dispatch(
      fetchMixSimulationConstraintTableData(
        tableDataPayload,
        API_URL.MIXSIMULATION.GET_MIX_CONTRAINT_TABLE_DATA
      )
    );
  };

  return (
    <div style={{ width: "100%", padding: "10px" }}>
      <Grid container spacing={1}>
        {Object.keys(filters).map((item) => {
          return (
            <>
              {Object.keys(filters).length == 0 ? (
                <FilterGrid>
                  <MixSkeletons type={"filter"} />
                </FilterGrid>
              ) : (
                <Grid item style={{ background: "#EDF5FA" }}>
                  <DropdownTitle className="m-b-10">
                    {FilterLabelMapper[item]}{" "}
                    <span style={{ color: "red" }}>*</span>{" "}
                  </DropdownTitle>
                  <div>
                    <AssortmentDropdown
                      data={filters[item].map((ele, index) => ({
                        id: `${index}-${ele}-${index}`,
                        label: ele,
                        value: ele,
                      }))}
                      multiple={false}
                      allOption={false}
                      onChange={(selectionData) =>
                        onChangeGeoLevel(item, selectionData)
                      }
                      defaultOption={
                        filtersOptions[item] ? filtersOptions[item] : []
                      }
                    />
                  </div>
                  <SaveChangesPopup
                    isOpen={open}
                    setIsOpen={setOpen}
                    onYes={handleYes}
                    onNo={handleNo}
                  ></SaveChangesPopup>
                </Grid>
              )}
            </>
          );
        })}
      </Grid>
    </div>
  );
};

export default MixTableFilters;
