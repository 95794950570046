import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Stack } from "@mui/material";
import MixSkeletons from "../../MixSkeletons/MixSkeletons";
import { useDispatch, useSelector } from "react-redux";
import { getMixDecisionVariables, resetMixUserInputs, setMixSimulationAssortmentScenarioUid, setMixSimulationDecisionVariable, setMixSimulationGoal } from "../../../../store/actions/MixSimulationTool/mixsimulationUserInputs.action";
import MixUserInputsPopup from "./MixUserInputsPopup";
import { clearMixSimulationFiltersSuccess, fetchMixSimulationFiltersData } from "../../../../store/actions/MixSimulationTool/mixSimulatorFilter.action";
import { API_URL } from "../../../../store/actions/constants";

const GridContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const GridItem = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledTypography = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 500,
  // opacity:'60%'
});

const StyledBox = styled(Box)({
  width: '100%',
});

const StyledTextField = styled(TextField)({
  width: 332,
  height: 24,
});

const DecisionVariable = ({showSkeleton}) => {
  const {assortmentScenarios} = useSelector((state:any)=>state.mixSimulationUserInput)
  const {selectedAssortmentScenaioUid} = useSelector((state:any)=>state.mixSimulationUserInput)
  const [assortmentUid, setAssortmentUid] = useState('');
  const overallFilters = useSelector((state:any)=>state.mixSimulationAllFilter)
  const [openPopup, setOpenPopup] = useState(false)
  const onClose = (e) => {
    // console.log(e)
    setOpenPopup(false);
    clearFilters();
  }
  const dispatch = useDispatch();
  // console.log(showSkeleton)
  const handleChange = (event) => {
    setAssortmentUid(event.target.value);
    dispatch(setMixSimulationAssortmentScenarioUid(event.target.value));
  };

  const clearFilters = () => {

    dispatch(clearMixSimulationFiltersSuccess({
      data: {
        overall: {
          country: [],
          period: [],
          businessUnit: []
        },
        geo: {
          channel: [],
          region: [],
          storeSegment: []
        }
      }
    }));
    dispatch(setMixSimulationGoal(""))
    dispatch(fetchMixSimulationFiltersData({ default: true }, API_URL.MIXSIMULATION.OPTIMIZER_FILTER));
  };


  useEffect(()=>{
   if(selectedAssortmentScenaioUid !=''){
    setAssortmentUid(selectedAssortmentScenaioUid)
   }
    // console.log(assortmentUid)
    // console.log(selectedAssortmentScenaioUid)
  },[selectedAssortmentScenaioUid])
  useEffect(()=>{
if(!overallFilters.isEditMode){
  dispatch(setMixSimulationAssortmentScenarioUid(""))
  setAssortmentUid("")
}
    if(overallFilters.selectedFilters.businessUnit.length>0){
      // console.log("overallFiltersDV",overallFilters.selectedFilters)
      let assortmentLinkedScenariosPayload = {
        "default": true,
        "businessUnit":overallFilters.selectedFilters.businessUnit[0],
        "country": overallFilters.selectedFilters.country[0],
        "dateTimePeriod": overallFilters.selectedFilters.period[0]
      }
      dispatch(getMixDecisionVariables(assortmentLinkedScenariosPayload))
      .then((res) => {
        // console.log(res)
        if (res.data.scenario == null) {
          setOpenPopup(true);
          dispatch(resetMixUserInputs())
        }else{
            dispatch(setMixSimulationDecisionVariable(res))

        }
      });

    }
  },[overallFilters.selectedFilters.businessUnit])

  return (
    <Grid container spacing={0}>
      {console.log("assortmentScenarios",assortmentScenarios)}
      <Grid style={{ marginTop: "20px", padding:'0px 16px' }}>
        <Typography
          style={{
            fontFamily: "Mulish",
            fontSize: "16px",
            fontWeight: 800,
            color: "#000000",

          }}
        >
          Decision Variables
        </Typography>
        <GridContainer>
          <Stack spacing={1}>
            <StyledTypography style={{ color: "black" }}>
              Assortment Scenario Linked :
            </StyledTypography>
          
            {assortmentScenarios.length==0?(<><MixSkeletons type={'decisionVariable'}/></>):(<StyledBox className="form-group">
              <FormControl size="small" sx={{ m: 1,width:'100%' }}>
                <Select
                  value={assortmentUid}
                  onChange={handleChange}                  
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {assortmentScenarios.map(scenarioInfo=>{                    
                    return <MenuItem value={scenarioInfo.uid}>
                    {scenarioInfo.scenarioName}
                    </MenuItem>
                  })}
                </Select>
              </FormControl>
            </StyledBox>)
            }
          </Stack>
        </GridContainer>
      </Grid>
      <MixUserInputsPopup open={openPopup} onCloseHandler={onClose} selectedFilters = {overallFilters.selectedFilters}/>
    </Grid>
    
      

    
  );
};

export default DecisionVariable;
