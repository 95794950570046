import _ from "lodash";
import { compileHTMLTemplate } from "../../util/helper";
import { visualTooltip } from "../../util/mmpwVisualConfig";
import {
  OPEN_SIDE_MENU,
  LOADER,
  ADDREMOVELABEL,
  REMOVELABEL,
  REMOVESERIESLABEL,
} from "./constants";
export const openSidebar = (payload: boolean) => {
  return {
    type: OPEN_SIDE_MENU,
    payload,
  };
};
export const loader = (payload: boolean) => {
  return {
    type: LOADER,
    payload,
  };
};
export const addRemoveLabel = (payload, id, tooltips, chart, selectAll?) => {
  const key = Object.keys(visualTooltip).find((key) => id.includes(key));
  let config = {
    html: "{{category}}<p>{{y}}</p>",
    variables: {
      category: {
        isFormat: false,
        decimal: null,
      },
      y: {
        isFormat: true,
        decimal: 2,
      },
    },
  };
  if (key) {
    config = visualTooltip[key].tooltip;
  }
  const request = tooltips && tooltips[id] ? [...tooltips[id]] : [];
  if (_.isArray(payload) && payload.length) {
    payload.map((event) => {
      const pointId = `${id}-tooltip-${event.series.name.replace(/\s/g, "")}-${
        event.index
      }`;
      const index = request.findIndex((t) => t.id === pointId);
      if (index !== -1) {
        if (!selectAll) request.splice(index, 1);
      } else if(event.y !== null){
        request.push({
          seriesIndex: event.series.index,
          id: pointId,
          ...getXAndY(chart.series[event.series.index].type, event, chart),
          tooltip: compileHTMLTemplate(event, config),
          chart: { ...event },
        });
      }
    });
  }
  return {
    type: ADDREMOVELABEL,
    payload: { ...tooltips, [id]: request },
  };
};
const getXAndY = (type, event, chart) => {
  switch (type) {
    case "column":
    case "bar":
    case "waterfall":
    case "pie":
      return {
        x: event.tooltipPos[0] + chart.plotLeft - 5,
        y: event.tooltipPos[1] + chart.plotTop - 5,
      };
    case "line":
      return chart.inverted
        ? {
            x: chart.plotWidth + chart.plotLeft - (event.plotY + 5),
            y: chart.plotHeight + chart.plotTop - (event.plotX + 5),
          }
        : {
            x: event.plotX + chart.plotLeft - 5,
            y: event.plotY + chart.plotTop - 5,
          };
    case "bubble":
      return chart.inverted
        ? {
            x: chart.plotWidth + chart.plotLeft - event.plotY,
            y: chart.plotHeight + chart.plotTop - (event.plotX + 5),
          }
        : {
            x: event.plotX + chart.plotLeft - 5,
            y: event.plotY + chart.plotTop - 5,
          };
    default:
      return {
        x: event.plotX + chart.plotLeft - 5,
        y: event.plotY + chart.plotTop - 5,
      };
  }
};
export const removeSeriesLabels = (seriesData, id) => {
  const pointIds = [];
  if (_.isArray(seriesData) && seriesData.length) {
    seriesData.map((data, i) => {
      const pointId = `${id}-tooltip-${data.series.name.replace(
        /\s/g,
        ""
      )}-${i}`;
      pointIds.push(pointId);
    });
  }
  return {
    type: ADDREMOVELABEL,
    payload: { id, pointIds },
  };
};
export const removeLabels = (id) => {
  return {
    type: REMOVELABEL,
    payload: id,
  };
};
