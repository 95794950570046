import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";


export const GridContainer = styled(Grid)`
  padding: 30px;
  display: flex;
  justify-content: space-between;
  height: 34px;
  fontsize; 14px;
`;

export const GridItem = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const StyledTypography = styled(Typography)`
font-family: Montserrat;
font-size: 14px;
font-weight: 500;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
color:#ffffff
`;

export const StyledBox = styled(Box)`
  &.form-group {
    pmd-textfield pmd-textfield-filled {

    }
  }
`;



