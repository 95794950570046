import React, { useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import { DropdownTitle } from "./OverallFilters.Styled";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAssortmentProductFiltersData,
  fetchAssortmentProductFiltersFailure,
  fetchAssortmentProductFiltersRequest,
  fetchAssortmentProductFiltersSuccess,
  getScenarioDetails,
  loaderScenarioDetails,
  setDefaultProductFilters,
} from "../../../../../store/actions//AssortmentTool/assortmentFilters.action";
import AssortmentDropdown from "../../../../../components/AssortmentDropdown";
import { makeStyles } from "@material-ui/core/styles";
import {
  dispatchState,
  fetchApi,
} from "../../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import { ClearBtn } from "../../../../../styles/Common.Styled";
import { API_URL } from "../../../../../store/actions/constants";

const useStyles = makeStyles((theme) => ({
  asterik: { color: "red" },
}));

const OverallFilters: React.FC<{
  data;
  onSelectFilters;
  isViewEdit;
}> = ({ data, onSelectFilters, isViewEdit }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const AssortmentFiltersData = useSelector(
    (state) => state.AssortmentFiltersReducer
  );
  const initialState = {
    country:
      AssortmentFiltersData.country === null
        ? null
        : AssortmentFiltersData.country[0],
    businessUnit:
      AssortmentFiltersData.businessUnit === null
        ? null
        : AssortmentFiltersData.businessUnit[0],
    dateTimePeriod:
      AssortmentFiltersData.dateTimePeriod === null
        ? null
        : AssortmentFiltersData.dateTimePeriod[0],
  };
  const [prevAssortmentFiltersData, setPrevAssortmentFiltersData] = useState(
    AssortmentFiltersData
  );

  const [selectedProductFilters, setSelectedProductFilters] = useState({
    ...initialState,
  });

  const [disabledProductFilters, setDisabledProductFilters] = useState({
    country: false,
    businessUnit: false,
    dateTimePeriod: false,
  });

  const [selectedValues, setSelectedValues] = useState({
    country:
      AssortmentFiltersData.country === null
        ? null
        : AssortmentFiltersData.country[0],
    businessUnit:
      AssortmentFiltersData.businessUnit === null
        ? null
        : AssortmentFiltersData.businessUnit[0],
    dateTimePeriod:
      AssortmentFiltersData.dateTimePeriod === null
        ? null
        : AssortmentFiltersData.dateTimePeriod[0],
  });

  const [countries, setCountries] = useState("");
  const [businessUnits, setBusinessUnits] = useState("");
  const [dateTimePeriods, setDateTimePeriods] = useState("");

  const handleFilterChange = async (filterKey, handleFunction, data) => {
    try {
      const response = data[filterKey];
      const filterObjects = response.map((item) => ({
        id: item,
        label: item,
        value: item,
        default: false,
      }));
      handleFunction(filterObjects);
    } catch (error) {
      console.error(`Error handling ${filterKey} data:`, error);
    }
  };

  useEffect(() => {
    // Check if "country" data has changed
    if (
      AssortmentFiltersData.country !== prevAssortmentFiltersData.country &&
      AssortmentFiltersData.country !== null &&
      AssortmentFiltersData.country !== undefined &&
      AssortmentFiltersData.country !== ""
    ) {
      handleFilterChange("country", setCountries, AssortmentFiltersData);
    }

    // Check if "businessUnit" data has changed
    if (
      AssortmentFiltersData.businessUnit !==
        prevAssortmentFiltersData.businessUnit &&
      AssortmentFiltersData.businessUnit !== null &&
      AssortmentFiltersData.businessUnit !== undefined &&
      AssortmentFiltersData.businessUnit !== ""
    ) {
      handleFilterChange(
        "businessUnit",
        setBusinessUnits,
        AssortmentFiltersData
      );
    }

    // Check if "dateTimePeriod" data has changed
    if (
      AssortmentFiltersData.dateTimePeriod !==
        prevAssortmentFiltersData.dateTimePeriod &&
      AssortmentFiltersData.dateTimePeriod !== null &&
      AssortmentFiltersData.dateTimePeriod !== undefined &&
      AssortmentFiltersData.dateTimePeriod !== ""
    ) {
      handleFilterChange(
        "dateTimePeriod",
        setDateTimePeriods,
        AssortmentFiltersData
      );
    }
  }, [AssortmentFiltersData, prevAssortmentFiltersData]);

  useEffect(() => {
    if (selectedProductFilters) {
      onSelectFilters({ ...selectedProductFilters });
    }
  }, [selectedProductFilters]);

  useEffect(() => {
    if (isViewEdit) {
      const dispatchState: dispatchState = {
        loaderStateFetch: loaderScenarioDetails(true),
      };
      dispatch(
        fetchApi(
          null,
          `${API_URL.ASSORTMENT.SCENARIO_DETAILS}?Uid=${isViewEdit}`,
          dispatchState
        )
      )
        .then((res) => {
          dispatch(getScenarioDetails(res.data));
          handleEdit(res.data);
        })
        .catch((err) => {
          console.log("ERROE", err);
        });
    } else {
      dispatch(fetchAssortmentProductFiltersRequest());
      dispatch(
        fetchApi(
          {
            default: true,
            country: "",
            dateTimePeriod: "",
            businessUnit: "",
          },
          API_URL.ASSORTMENT.PRODUCT_FILTERS,
          null
        )
      )
        .then((res) => {
          dispatch(fetchAssortmentProductFiltersSuccess(res.data));
          handleFilterChange("country", setCountries, res.data);
          handleFilterChange("businessUnit", setBusinessUnits, res.data);
          handleFilterChange("dateTimePeriod", setDateTimePeriods, res.data);
          const { country, businessUnit, dateTimePeriod } = res.data;
          const updatedValues = {
            country: country[0],
            businessUnit: businessUnit[0],
            dateTimePeriod: dateTimePeriod[0],
          };
          setSelectedValues(updatedValues);
          setSelectedProductFilters(updatedValues);
          dispatch(setDefaultProductFilters(updatedValues));
        })
        .catch((err) => {
          dispatch(fetchAssortmentProductFiltersFailure(err.message));
        });
    }
  }, []);

  const handleEdit = (res) => {
    const { country, businessUnit, dateTimePeriod } = res;

    setSelectedValues({
      country,
      businessUnit,
      dateTimePeriod,
    });

    const payload = {
      country,
      businessUnit,
      dateTimePeriod,
      default: false,
    };

    onSelectFilters({ ...payload });

    setDisabledProductFilters({
      country: true,
      businessUnit: true,
      dateTimePeriod: true,
    });
  };

  const onChangeGeoLevel = (key, data, isDefault) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [key]: data[0],
    }));
    const payload = { ...selectedProductFilters, [key]: data[0] };
    if (key === "businessUnit") {
      handleChangesForOtherFilters(
        { [key]: data[0] },
        isDefault,
        key,
        "getAssortmentGeoFilter"
      );
      setSelectedProductFilters(payload);
    } else {
      handleChangesForOtherFilters(
        { [key]: data[0] },
        isDefault,
        key,
        "getAssortmentProductFilter"
      );
    }

    onSelectFilters({ ...payload });
  };

  const handleChangesForOtherFilters = (data, isDefault, key, apiPath) => {
    let updatedValues = {
      country: "",
      dateTimePeriod: "",
      businessUnit: "",
    };
    if (key === "businessUnit") {
      updatedValues = {
        country: selectedValues.country,
        dateTimePeriod: selectedValues.dateTimePeriod,
        businessUnit: selectedValues.businessUnit,
        ...data,
      };
    } else if (key === "dateTimePeriod") {
      updatedValues = {
        country: selectedValues.country,
        dateTimePeriod: selectedValues.dateTimePeriod,
        businessUnit: "",
        ...data,
      };
    } else {
      updatedValues = {
        country: selectedValues.country,
        dateTimePeriod: "",
        businessUnit: "",
        ...data,
      };
    }

    setSelectedValues(updatedValues);
    setSelectedProductFilters(updatedValues);
    dispatch(setDefaultProductFilters(updatedValues));

    if (key !== "businessUnit") {
      const payload = {
        default: isDefault,
        ...updatedValues,
      };
      let finalPayload = { ...payload, ...data };
      dispatch(fetchAssortmentProductFiltersData(finalPayload, apiPath));
    }
  };

  const clearProductFilter = () => {
    setSelectedProductFilters({ ...initialState });
    setSelectedValues({
      country: "",
      businessUnit: "",
      dateTimePeriod: "",
    });
  };

  return (
    <Grid container>
      <Grid item style={{ marginLeft: 0 }}>
        <DropdownTitle>
          {data.country.title}
          <span className={classes.asterik}>*</span>
        </DropdownTitle>
        <AssortmentDropdown
          disabled={disabledProductFilters.country}
          data={countries}
          onChange={(data) => onChangeGeoLevel("country", data, false)}
          placeholder={data.country.placeholder}
          multiple={data.country.multiple}
          allOption={data.country.all}
          defaultOption={selectedValues.country ? [selectedValues.country] : []}
        />
      </Grid>
      <Grid item style={{ marginLeft: "20px" }}>
        <DropdownTitle>
          {data.dateTimePeriod.title}
          <span className={classes.asterik}>*</span>
        </DropdownTitle>
        <AssortmentDropdown
          disabled={disabledProductFilters.dateTimePeriod}
          data={dateTimePeriods}
          onChange={(data) => onChangeGeoLevel("dateTimePeriod", data, false)}
          placeholder={data.dateTimePeriod.placeholder}
          multiple={data.dateTimePeriod.multiple}
          allOption={data.dateTimePeriod.all}
          defaultOption={
            selectedValues.dateTimePeriod ? [selectedValues.dateTimePeriod] : []
          }
        />
      </Grid>
      <Grid item style={{ marginLeft: "20px" }}>
        <DropdownTitle>
          {data.businessUnit.title}
          <span className={classes.asterik}>*</span>
        </DropdownTitle>
        <AssortmentDropdown
          disabled={disabledProductFilters.businessUnit}
          data={businessUnits}
          onChange={(data) => onChangeGeoLevel("businessUnit", data, false)}
          placeholder={data.businessUnit.placeholder}
          multiple={data.businessUnit.multiple}
          allOption={data.businessUnit.all}
          defaultOption={
            selectedValues.businessUnit ? [selectedValues.businessUnit] : []
          }
        />
      </Grid>
      <Grid container justifyContent="flex-end">
        {isViewEdit ? (
          <>
            <Grid style={{ marginLeft: "10px" }} item>
              <Skeleton
                variant="rectangular"
                height={30}
                width={80}
                style={{ padding: "10px" }}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid style={{ marginLeft: "10px" }} item>
              <ClearBtn className="m-r-20" onClick={clearProductFilter}>
                Clear Filter
              </ClearBtn>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default OverallFilters;
