import { Skeleton, Stack, Card, CardContent, Grid, Divider } from '@mui/material';
import React from 'react'

const renderContent = (type) => {
    switch (type) {
        case 'goals':
            return <>
             <Stack direction="row" spacing={1}>
                    <Stack spacing={1 / 8}>
                     <Skeleton  variant="rectangular" width={200} height={36} sx={{ borderRadius: '4px' }} />
                    </Stack>
                </Stack>   
            </>;
            case 'decisionVariable':
                return <>
                <Stack direction="row" spacing={1}>
                       <Stack spacing={1 / 8}>
                        <Skeleton  variant="rectangular" width={200} height={36} sx={{ borderRadius: '4px' }} />
                       </Stack>
                   </Stack>   
               </>;
        case 'constraints':
            return <>
             <Stack direction="row" spacing={1}>
                    <Stack spacing={1 / 8}>
                     <Skeleton  variant="rectangular" width={100} height={36} sx={{ borderRadius: '4px' }} />
                    </Stack>
                </Stack>   
            </>;
        case 'download':
            return <>
            <Stack direction="row" spacing={1}>
                   <Stack spacing={1 / 8}>
                    <Skeleton  variant="rectangular" width={200} height={36} sx={{ borderRadius: '4px' }} />
                   </Stack>
               </Stack>   
           </>;
        case 'upload':
            return <>
            <Stack direction="row" spacing={1}>
                   <Stack spacing={1 / 8}>
                    <Skeleton  variant="rectangular" width={120} height={36} sx={{ borderRadius: '4px' }} />
                   </Stack>
               </Stack>   
           </>;
        case 'filter':
            return <>
                <Stack direction="row" spacing={1}>
                    <Stack spacing={1 / 8}>
                        <Skeleton  variant="text" width={75} sx={{ fontSize: '1rem' }} />
                        <Skeleton  variant="rectangular" width={200} height={36} sx={{ borderRadius: '4px' }} />
                    </Stack>
                </Stack>
            </>;  
        default:
            return <p>Default content.</p>;
    }
};
const MixSkeletons = ({ type }) => {
    return (
        <>
            {renderContent(type)}
        </>
    )
}

export default MixSkeletons