import axios from "axios";
import {
  BACKEND_URL,
  CLEAR_MIX_SIMULATION_FILTERS_REQUEST,
  FETCH_MIX_SIMULATION_FILTERS_FAILURE,
  FETCH_MIX_SIMULATION_FILTERS_REQUEST,
  FETCH_MIX_SIMULATION_FILTERS_SUCCESS,
  SELECTED_MIX_SIMULATION_FILTER,
  SET_MIX_SIMULATION_LAST_TRIGGERED_KEY,
  SET_PREVIOUSLY_SELECTED_MIX_SIMULATION_FILTER,
  UPDATE_MIX_SIMULATION_FILTER,
  OUTPUT_SELECTED_MIX_FILTERS,
  SET_OUTPUT_SCREEN_TO_DISPLAY,
  GET_GEO_FILTER_DATA,
  SET_MIX_SCENARIO_NAME,
  SET_MIX_VIEW_MODE,
  RESET_MIX_SIMULATION_GEO_FILTERS_REQUEST,
  SECTION_FILTER_LOADER,
  GET_HISTORICAL_RANGE_DATA,
  GET_PRICE_ELASTICITY_DATA,
  LOADER_FOR_HISTORICAL_RANGE,
  LOADER_FOR_Elasticity,
  TOGGLE_FOR_PI,
  RESET_DATA_FOR_GRAPH,
  HANDLE_MIX_SIMULATION_EDIT_MODE,
  RESET_ALL_MIX_SIMULATION_FILTERS,
  SELECTED_BUBBLE_VALUE_SELECTION_FILTER_MIX_SIMULATOR,
  SELECTED_BUBBLE_CHART_OPTIONS_SELECTION_FILTER_MIX_SIMULATOR,
  SET_SELECTED_DATE_TIMEPERIOD_DETAILS,
  SET_MIX_SIMULATOR_PRICE_ELASTICITY_DOWNLOAD_DATA,
  SET_MIX_SIMULATOR_PRICE_ELASTICITY_UPLOAD_DATA
} from "../constants";
import { API } from "../../../util/helper";

export const setMixScenarioName = (data) => ({
  type: SET_MIX_SCENARIO_NAME,
  payload: data,
});
export const setMixViewMode = (data) => ({
  type: SET_MIX_VIEW_MODE,
  payload: data,
});
export const handleMixEditMode = (data) => ({
  type: HANDLE_MIX_SIMULATION_EDIT_MODE,
  payload: data,
});
export const resetAllMixFilters = () => ({
  type: RESET_ALL_MIX_SIMULATION_FILTERS,
});

export const selectedMixSimulationFilters = (payload) => {
    return {
      type: SELECTED_MIX_SIMULATION_FILTER,
      payload,
    };
  };

  export const updateMixSimulationFilters = (payload) => {
    return {
      type: UPDATE_MIX_SIMULATION_FILTER,
      payload,
    };
  };

export const fetchMixSimulationFiltersRequest = () => ({
  type: FETCH_MIX_SIMULATION_FILTERS_REQUEST,
});

export const fetchMixSimulationFiltersSuccess = (data) => {
    return {
      type: FETCH_MIX_SIMULATION_FILTERS_SUCCESS,
      payload: data,
    };
  };
  export const clearMixSimulationFiltersSuccess = (data) => {
    return {
      type: CLEAR_MIX_SIMULATION_FILTERS_REQUEST,
      payload: data,
    };
  };
export const fetchMixSimulationFiltersFailure = (error) => ({
  type: FETCH_MIX_SIMULATION_FILTERS_FAILURE,
  payload: error,
});

export const setMixSimulationPreviouslySelectedFilters = () => {
    return {
      type: SET_PREVIOUSLY_SELECTED_MIX_SIMULATION_FILTER,
    };
  };
  
  export const setMixSimulationLastTriggeredKey = (data) => {
    return {
      type: SET_MIX_SIMULATION_LAST_TRIGGERED_KEY,
      payload: data,
    };
  };

  export const setDefaultMixSimulationFilterForOutput = () => {
    return {
      type: OUTPUT_SELECTED_MIX_FILTERS,
    };
  };
export const resetGeoLevelFilter =()=>({
   type:RESET_MIX_SIMULATION_GEO_FILTERS_REQUEST
})
  export const setOutputScreenToDisplay = (payload) => ({
    type: SET_OUTPUT_SCREEN_TO_DISPLAY,
    payload
});

export const getGeoFilterData = (payload) => ({
  type: GET_GEO_FILTER_DATA,
  payload
});

export const sectionFilterLoader = (payload) => ({
  type : SECTION_FILTER_LOADER,
  payload
});

export const getHistoricalData = (payload) => ({
  type : GET_HISTORICAL_RANGE_DATA,
  payload
});

export const getPriceElasticityData = (payload) => ({
  type : GET_PRICE_ELASTICITY_DATA,
  payload
});

export const loaderForHistoricalRange = (payload) => ({
  type : LOADER_FOR_HISTORICAL_RANGE,
  payload
});

export const loaderForElasticityRange = (payload) => ({
  type : LOADER_FOR_Elasticity,
  payload
});

export const toggleForPI = (payload) => ({
  type : TOGGLE_FOR_PI,
  payload 
});

export const setSelectedBubbleValueForSectionFilterMixSimulator = (payload) => ({
  type : SELECTED_BUBBLE_VALUE_SELECTION_FILTER_MIX_SIMULATOR,
  payload 
});

export const setSelectedBubbleChartOptionsMixSimulator = (payload) => ({
  type : SELECTED_BUBBLE_CHART_OPTIONS_SELECTION_FILTER_MIX_SIMULATOR,
  payload 
});

export const setMixSimulatorPriceElasticityDownloadData = (payload) => ({
  type : SET_MIX_SIMULATOR_PRICE_ELASTICITY_DOWNLOAD_DATA,
  payload 
});

export const setMixSimulatorPriceElasticityUploadData = (payload) => ({
  type : SET_MIX_SIMULATOR_PRICE_ELASTICITY_UPLOAD_DATA,
  payload 
});





export const resetDataForGraph = () => ({
  type : RESET_DATA_FOR_GRAPH
})
export const setSelectedDateTimeperiodDetails = (payload) => ({
  type : SET_SELECTED_DATE_TIMEPERIOD_DETAILS,
  payload 
});


export const fetchMixSimulationFiltersData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(fetchMixSimulationFiltersRequest());
    try {
      const response = await API.post(`${BACKEND_URL}/api/${apiUrl}`, data);
      console.log("fetchMixSimulationFiltersRequest",response)
      dispatch(fetchMixSimulationFiltersSuccess(response));
    } catch (error) {
      dispatch(fetchMixSimulationFiltersFailure(error.message));
    }
  };
};
