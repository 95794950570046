import { Typography, styled } from "@mui/material";

export const TypographyTheme = styled(Typography)(({ theme }) => ({
    color:
        theme.palette.mode === 'dark'
            ? theme.palette.common.white
            : theme.palette.common.black,
    marginTop: '1rem',
    marginBottom: '1rem',
    fontWeight : '800'
}));

export const MandatoryInformationContainer = styled(Typography)(({ theme }) => ({
    color:
        theme.palette.mode === 'dark'
            ? theme.palette.common.white
            : theme.palette.common.black,
    display : "flex",
    justifyContent : 'flex-end',
    padding : '5px 10px',
    alignItems : 'center',
    fontFamily : 'Roboto',
    fontSize : '12px',
    fontStyle : 'normal',
    fontWeight : 400,
     lineHeight : '12px'       
}));