import React, { useState } from "react";
import { GridContainer, GridItem, StyledTypography, StyledBox } from "../MixScenario/MixScenario.Styled";
import { DownloadButton, UploadButton } from "./MixCostBar.Styled";
import { Grid, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import { downloadMixSimulationCSVData, downloadMixSimulationConstraintTableData, fetchMixSimulationConstraintTableData, uploadMixSimulationConstraintTableData, uploadValidationSuccess } from "../../../../store/actions/MixSimulationTool/mixSimulationConstraintTable.action";
import { PrimaryBtn } from "../../../../styles/Common.Styled";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useCSVDownloader } from 'react-papaparse';
import Papa from 'papaparse'
import _ from 'lodash'
import { toast } from "../../../../store/actions/toast.action";
import MixSkeletons from "../../MixSkeletons/MixSkeletons";
import { keysToDownloadMapper, keysToUploadMapper } from "../MixTable/MixTableDownloadConfig";
import { API_URL } from "../../../../store/actions/constants";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});


const MixConstBar = ({ tableData }) => {
  const { CSVDownloader } = useCSVDownloader();
  const dispatch = useDispatch();
  const [downloadButtonLoader, setDownloadButtonLoader] = useState(false);
  const [uploadButtonLoader, setUploadButtonLoader] = useState(false);
  const selectedFiltersData = useSelector((state: any) => state.mixSimulationConstraintTable.selectedFilters);
  const goal = useSelector((state: any) => state.mixSimulationUserInput.selectedGoal);
  const uid = useSelector((state: any) => state.mixSimulationUserInput.uid);
  const { dataToDownload } = useSelector((state: any) => state.mixSimulationConstraintTable);
  const handleUpload = (event) => {
    setUploadButtonLoader(true)
    const file = event.target.files[0];
    let updatedData: any = [];
    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target?.result as string;
      const lines = content.split('\n');
      // const requiredHeaders = ['srno', 'channel', 'region', 'store_segment', 'segment', 'brand', 'subbrand', 'sku', 'min_mix', 'max_mix', 'historical_min', 'historical_max', 'maximum_net_revenue_per_kg', 'maximum_profit_margin', 'maximum_market_share', 'penetration', 'sku_volume', 'sku_optimised_volume', 'sku_sales', 'optimised_revenue', 'optimised_profit', 'gross_profit', 'sku_sales_nielsen', 'bu_industry_sales_nielsen', 'business_unit', 'date_time_period', 'country', 'objective_function', 'vendor', 'smkt_chain', 'pack_role', 'flavour', 'net_revenue', 'pepsico_sales_nielsen', 'pepsico_volume_nielsen', 'average_ppk', 'gp_per_kg', 'sku_volume_nielsen', 'bu_industry_volume_nielsen', 'min_volume', 'max_volume', 'sku_rank', 'capacity_constraints', 'operational_complexity', 'pack_size']; // Add your required headers here
      const requiredHeaders = Object.values(keysToDownloadMapper);
      //  const 
      // Extract headers from the first line of the CSV
      const headers = lines[0].trim().split(',');

      // Check if all required headers are present
      const missingHeaders = requiredHeaders.filter((header) => !headers.includes(header));

      if (missingHeaders.length > 0) {
        alert('The following required headers are missing in the CSV: ' + missingHeaders.join(', '));
      } else {
        // const modifiedHeaders =
        
        const unparsed = Papa.parse(content, { header: true,skipEmptyLines: true });
        console.log("csvDataWithUpdatedHeaders 1",unparsed)
        const csvDataWithUpdatedHeaders = unparsed.data.map((row: any) => {
          const newRow = {};
          for (const key in keysToUploadMapper) {
            const mappedKey = keysToUploadMapper[key];
            newRow[mappedKey] = row[key]
          }
          return newRow
        })
        // content

        console.log("csvDataWithUpdatedHeaders 1",content)
        console.log("csvDataWithUpdatedHeaders 1",csvDataWithUpdatedHeaders)
        console.log("csvDataWithUpdatedHeaders 2",dataToDownload)
        console.log("csvDataWithUpdatedHeaders 1",csvDataWithUpdatedHeaders.length)
        console.log("csvDataWithUpdatedHeaders 2",dataToDownload.length)
        if (csvDataWithUpdatedHeaders.length == dataToDownload.length) {
          updatedData = validateUploadedDataAndMerge(dataToDownload, csvDataWithUpdatedHeaders);
          console.log("unparsed", updatedData)
          if (_.some(updatedData, _.isEmpty)) {
            setUploadButtonLoader(false)

            dispatch(toast('Invalid File Uploaded, Only Min Mix & Max Mix are editable', true, 2000, 'error'));
          } else {
            dispatch(uploadValidationSuccess(updatedData));
            dispatch(toast('Validation successful.', true, 2000, 'success'));
            let id = uid;
            // console.log(updatedData)
            const csvContent = Papa.unparse(updatedData)
            // console.log(csvContent)

            const formData = new FormData();
            const fileToUpload = new Blob([csvContent], { type: "text/csv" });
            formData.append("CsvFile", fileToUpload);
            formData.append("uid", id);
            dispatch(uploadMixSimulationConstraintTableData(formData, API_URL.MIXSIMULATION.SAVE_MIX_CONSTRIANT_TABLE_DATA))
              .then((res) => {
                let tableDataPayload = {
                  goal: goal,
                  uid: id,
                  filtersSelected: true,
                  channel: selectedFiltersData.channel[0],
                  region: selectedFiltersData.region[0],
                  storeSegment: selectedFiltersData.storeSegment[0]
                }
                dispatch(fetchMixSimulationConstraintTableData(tableDataPayload, API_URL.MIXSIMULATION.GET_MIX_CONTRAINT_TABLE_DATA));
                setUploadButtonLoader(false)

              })
          }

        } else {
          setUploadButtonLoader(false)

          dispatch(toast('Number of rows in uploaded data doesn\'t match the original data', true, 2000, 'error'));
        }

      }
    };

    reader.readAsText(file);


  };

  const validateUploadedDataAndMerge = (orignalData, uploadedData) => {
    console.log("uploadedData",uploadedData)
    console.log("uploadedData",orignalData)
    const editableColumns = ['min_mix', 'max_mix'];
    return _.map(orignalData, originalObj => {
      const modifiedObj = _.find(uploadedData, { 'id': originalObj.id.toString() });
      console.log("checkTarget",modifiedObj)
      console.log("checkTarget",typeof(originalObj.id))

      if (modifiedObj) {
        const targetMinMix = modifiedObj['min_mix'];
        const targetMaxMix = modifiedObj['min_mix'];
        // console.log("checkTarget",modifiedObj)
        // console.log("checkTarget",isNaN(parseFloat(targetMinMix)))
        // console.log("checkTarget",isNaN(parseFloat(targetMaxMix)))
        // console.log("checkTarget",parseFloat(targetMinMix))
        // console.log("checkTarget",parseFloat(targetMaxMix))
        if (isNaN(parseFloat(targetMinMix)) || isNaN(parseFloat(targetMaxMix))) {
          return {}
        }
        const keysToCheck = _.omit(modifiedObj, editableColumns);
        const valuesMatch = _.isEqualWith(_.pick(originalObj, _.keys(keysToCheck)), keysToCheck, (orignalValue, modifiedValue) => {
          if (_.isNumber(orignalValue) && _.isString(modifiedValue)) {
            return orignalValue === parseFloat(modifiedValue);
          }

        });
        console.log(valuesMatch)
        if (valuesMatch) {
          return {
            ...originalObj,
            min_mix: modifiedObj.min_mix !== undefined ? modifiedObj.min_mix : originalObj.min_mix,
            max_mix: modifiedObj.max_mix !== undefined ? modifiedObj.max_mix : originalObj.max_mix
          };
        } else {
          return {}
        }
      }
      return originalObj;
    });
  }
  const handleDownload = () => {
    setDownloadButtonLoader(true)
    // let downloadData= {}
    let payload = {
      "goal": goal,
      "uid": uid,
      "filtersSelected": false,
      "channel": "",
      "region": "",
      "storeSegment": ""
    }
    dispatch(downloadMixSimulationConstraintTableData(payload, API_URL.MIXSIMULATION.GET_MIX_CONTRAINT_TABLE_DATA))
      .then((res) => {
        // console.log(res)
        // downloadData = res
        // console.log("downloadData",downloadData)
        setDownloadButtonLoader(false);
      })
    // console.log("downloadData",downloadData)
    // let payload = {
    //   overallFilters: {
    //     country: allFilterData.country[0],
    //     dataTimePeriod: allFilterData.period[0],
    //     businessUnit: allFilterData.businessUnit[0]
    //   },
    //   geoFilters: {
    //     channel: allFilterData.channel,
    //     region: allFilterData.region,
    //     storeSegment: allFilterData.storeSegment
    //   },
    //   goal: goal
    // }
    // dispatch(downloadMixSimulationCSVData(payload, 'downloadCsvFileFromMixInputTable');

    // return tableData
  };

  return (
    <GridContainer style={{ padding: 5, display: 'flex', justifyContent: 'space-between', marginTop: 20, marginBottom: 20 }}>
      <Grid item>
        <StyledTypography
          style={{
            whiteSpace: 'nowrap',
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: "700",
            color: "#000000"
          }}>
          Mix Constraint:
        </StyledTypography>
      </Grid>

      <Grid item className="p-r-25" >
        {

          <PrimaryBtn style={{ width: '150px' }} disabled={tableData.length == 0} className="m-r-20" onClick={handleDownload}
            startIcon={downloadButtonLoader ? <CircularProgress style={{
              width: '20px', height: '20px',
            }} color="inherit" /> : <FileDownloadOutlinedIcon />}>
            {/* <CSVDownloader
            filename={'constraint_data_download.csv'}
            bom={true}
            config={{
              header: true
            }}
            data={handleDownload}
          >
            Download CSV
          </CSVDownloader> */}
            Download CSV
          </PrimaryBtn>
        }
        {
          <PrimaryBtn style={{ width: '150px' }} disabled={dataToDownload.length == 0} component="label" color="primary"
            startIcon={uploadButtonLoader ? <CircularProgress style={{
              width: '20px', height: '20px',
            }} color="inherit" /> : <FileUploadOutlinedIcon />}>

            Upload File
            <VisuallyHiddenInput
              onChange={handleUpload}
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </PrimaryBtn>
        }

      </Grid>
    </GridContainer>
  );
};


export default MixConstBar;
