import { mapperForFilterName } from "../../../mocks/mixSiumulation";
import {
  FETCH_EXECUTION_PORTFOLIO_TABLE_FILTERS_REQUEST,
  FETCH_EXECUTION_GEO_TABLE_FILTERS_REQUEST,
  SELECTED_EXECUTION_TABLE_FILTER,
  GET_PORTFOLIO_TABLE_FILTER_DATA,
  GET_GEO_TABLE_FILTER_DATA,
  CLEAR_GEO_PORTFOLIO_LEVEL_SELECTED_FILTERS,
} from "../../actions/constants";

const initialState = {
  geoFilterData: {},
  portfolioFilterData: {},
  portfolioLoading: false,
  geoLoading: false,
  error: null,
  selectedFilters: {
    segment: [],
    brand: [],
    sku: [],
    channel: [],
    region: [],
    storeSegment: [],
  },
};

const portfolioGeoLevelFilter = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXECUTION_PORTFOLIO_TABLE_FILTERS_REQUEST:
      return {
        ...state,
        portfolioLoading: true,
        error: null,
      };
    case FETCH_EXECUTION_GEO_TABLE_FILTERS_REQUEST:
      return {
        ...state,
        geoLoading: true,
        error: null,
      };
    case SELECTED_EXECUTION_TABLE_FILTER:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload?.name]:
            typeof action.payload.value === "string"
              ? [action.payload.value]
              : action.payload.value,
        },
      };
    case GET_GEO_TABLE_FILTER_DATA:
      const responseGeoData = action.payload;
      let modifiedGeoResponse = {};
      for (let filtersName in responseGeoData) {
        modifiedGeoResponse[filtersName] = {
          ...responseGeoData[filtersName],
          defaultOption:
            state.selectedFilters[mapperForFilterName[filtersName]],
        };
      }
      return {
        ...state,
        geoFilterData: modifiedGeoResponse,
        geoLoading: false,
      };
    case GET_PORTFOLIO_TABLE_FILTER_DATA:
      const responseData = action.payload;
      let modifiedResponse = {};
      for (let filtersName in responseData) {
        modifiedResponse[filtersName] = {
          ...responseData[filtersName],
          defaultOption:
            state.selectedFilters[mapperForFilterName[filtersName]],
        };
      }
      return {
        ...state,
        portfolioFilterData: modifiedResponse,
        portfolioLoading: false,
      };
    case CLEAR_GEO_PORTFOLIO_LEVEL_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: {
          segment: [],
          brand: [],
          sku: [],
          channel: [],
          region: [],
          storeSegment: [],
        },
      };
    default:
      return state;
  }
};
export default portfolioGeoLevelFilter;
