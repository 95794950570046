import {
  FETCH_EXECUTION_PORTFOLIO_TABLE_FILTERS_REQUEST,
  FETCH_EXECUTION_GEO_TABLE_FILTERS_REQUEST,
  SELECTED_EXECUTION_TABLE_FILTER,
  GET_PORTFOLIO_TABLE_FILTER_DATA,
  GET_GEO_TABLE_FILTER_DATA,
  CLEAR_GEO_PORTFOLIO_LEVEL_SELECTED_FILTERS,
} from "../constants";

export const selectedTableFilters = (payload) => ({
  type: SELECTED_EXECUTION_TABLE_FILTER,
  payload,
});

export const clearGeoPortfolioLevelSelectedFilters = () => ({
  type: CLEAR_GEO_PORTFOLIO_LEVEL_SELECTED_FILTERS,
});

export const fetchExecutionGeoTableFiltersRequest = () => ({
  type: FETCH_EXECUTION_GEO_TABLE_FILTERS_REQUEST,
});

export const fetchExecutionPortfolioTableFiltersRequest = () => ({
  type: FETCH_EXECUTION_PORTFOLIO_TABLE_FILTERS_REQUEST,
});

export const getGeoTableFilterData = (payload) => ({
  type: GET_GEO_TABLE_FILTER_DATA,
  payload,
});

export const getPortfolioTableFilterData = (payload) => ({
  type: GET_PORTFOLIO_TABLE_FILTER_DATA,
  payload,
});
