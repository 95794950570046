import React from "react";

const filterableColumns = [
  "segment",
  "brand",
  "subBrand",
  "packSize",
  "skuName",
  "flavor",
  "checkoutSku",
  "rank",
  "modelOutput",
];

const sortableColumns = [
  "rank",
  "skuName",
  "totalScore",
  "incrementalRevenueScore",
  "incrementalNoPBTScore",
  "noPBTScore",
  "growthScore",
  "marketSizeScore",
  "marketGrowthScore",
  "capacityConstraintScore",
  "operativeComplexityScore",
  "inventoryTurnScore",
  "netIncrementalRevenueMix",
  "cumulativeNetIncrementalRevenueMix",
  "penetration",
  "manufacturerWalkRate",
  "revenueMix",
  "nopbtPercent",
  "sopNopbtMix",
  "sopRevenueMix"
];

const tableColumns = [
  {
    headerName: "Segment",
    field: "segment",
    width: 150,
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", textAlign: "center" }}>
        {params.value}
      </div>
    ),
  },
  {
    headerName: "Brand",
    field: "brand",
    width: 130,
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", textAlign: "center" }}>
        {params.value}
      </div>
    ),
  },
  {
    headerName: "Sub-Brand",
    field: "subBrand",
    width: 250,
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", textAlign: "center" }}>
        {params.value}
      </div>
    ),
  },
  {
    headerName: "Flavor",
    field: "flavor",
    width: 130,
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", textAlign: "center" }}>
        {params.value}
      </div>
    ),
  },
  {
    headerName: "Pack-Size",
    field: "packSize",
    width: 150,
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", textAlign: "center" }}>
        {params.value}
      </div>
    ),
  },
  {
    headerName: "SKU Name",
    field: "skuName",
    width: 450,
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: "break-spaces", textAlign: "center"}}>
        {params.value}
      </div>
    ),
  },
  {
    headerName: "Checkout SKU",
    field: "checkoutSku",
    width: 160,
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", textAlign: "center" }}>
        {params.value}
      </div>
    ),
  },
  {
    headerName: "Rank",
    field: "rank",
    width: 120,
    valueFormatter: (params) => {
      return params.value ? params.value.toFixed(0) : "0";
    },
    align: "center",
  },
  {
    headerName: "Model Output",
    field: "modelOutput",
    width: 180,
    align: "center",
  },

  {
    headerName: `Net Incremental Revenue Mix`,
    field: "netIncrementalRevenueMix",
    width: 260,
    valueFormatter: (params) => {
      return params.value ? `${params.value.toFixed(1)} %` : "0.0";
    },
    align: "center",
  },
  {
    headerName: "Cumulative Incremental Revenue Mix",
    field: "cumulativeNetIncrementalRevenueMix",
    width: 320,
    valueFormatter: (params) => {
      return params.value ? `${params.value.toFixed(1)} %` : "0.0";
    },
    align: "center",
  },
  {
    headerName: "Penetration (Distribution)",
    field: "penetration",
    width: 240,
    valueFormatter: (params) => {
      return params.value ? `${params.value.toFixed(1)} %` : "0.0";
    },
    align: "center",
  },
  {
    headerName: "Manufacturer Walk Rate",
    field: "manufacturerWalkRate",
    width: 230,
    valueFormatter: (params) => {
      return params.value ? `${params.value.toFixed(1)} %` : "0.0";
    },
    align: "center",
  },
  {
    headerName: "Revenue Mix",
    field: "revenueMix",
    width: 140,
    valueFormatter: (params) => {
      return params.value ? `${params.value.toFixed(1)} %` : "0.0";
    },
    align: "center",
  },
  {
    headerName: "Profit %",
    field: "nopbtPercent",
    width: 160,
    valueFormatter: (params) => {
      return params.value ? `${params.value.toFixed(1)} %` : "0.0";
    },
    align: "center",
  },
  {
    headerName: "Total Score",
    field: "totalScore",
    width: 130,
    valueFormatter: (params) => {
      return params.value ? params.value.toFixed(1) : "0.0";
    },
    align: "center",
  },
  {
    headerName: "Incremental Revenue Score",
    field: "incrementalRevenueScore",
    width: 270,
    valueFormatter: (params) => {
      return params.value ? params.value.toFixed(1) : "0.0";
    },
    align: "center",
  },
  {
    headerName: "Incremental NoPBT Score",
    field: "incrementalNoPBTScore",
    width: 250,
    valueFormatter: (params) => {
      return params.value ? params.value.toFixed(1) : "0.0";
    },
    align: "center",
  },
  {
    headerName: "Profit Score",
    field: "noPBTScore",
    width: 145,
    valueFormatter: (params) => {
      return params.value ? params.value.toFixed(1) : "0.0";
    },
    align: "center",
  },
  {
    headerName: "Growth Score",
    field: "growthScore",
    width: 150,
    valueFormatter: (params) => {
      return params.value ? params.value.toFixed(1) : "0.0";
    },
    align: "center",
  },
  {
    headerName: "Market Size Score",
    field: "marketSizeScore",
    width: 180,
    valueFormatter: (params) => {
      return params.value ? params.value.toFixed(1) : "0.0";
    },
    align: "center",
  },
  {
    headerName: "Market Growth Score",
    field: "marketGrowthScore",
    width: 210,
    valueFormatter: (params) => {
      return params.value ? params.value.toFixed(1) : "0.0";
    },
    align: "center",
  },
  {
    headerName: "Capacity Constraint Score",
    field: "capacityConstraintScore",
    width: 240,
    valueFormatter: (params) => {
      return params.value ? params.value.toFixed(1) : "0.0";
    },
    align: "center",
  },
  {
    headerName: "Operative Complexity Score",
    field: "operativeComplexityScore",
    width: 255,
    valueFormatter: (params) => {
      return params.value ? params.value.toFixed(1) : "0.0";
    },
    align: "center",
  },
  {
    headerName: "Inventory Turn Score",
    field: "inventoryTurnScore",
    width: 200,
    valueFormatter: (params) => {
      return params.value ? params.value.toFixed(1) : "0.0";
    },
    align: "center",
  },
];

export { filterableColumns, sortableColumns, tableColumns };
