export const mixGeoFilterConfig = {
    sub_chnl: {
        title: "Channel",
        options: [
            {
                id: "Channel 1",
                label: "Channel 1",
                value: "Channel 1",
                disable: true,
            }, {
                id: "Channel 2",
                label: "Channel 2",
                value: "Channel 2",
                disable: false
            },
            {
                id: "Channel 3",
                label: "Channel 3",
                value: "Channel 3",
                disable: false
            }
        ],
        defaultOption: ["Channel 2"]
    },
    geo_level : {
        title: "Region",
        options: [
            {
                id: "Region 1",
                label: "Region 1",
                value: "Region 1",
                disable: false
            }, {
                id: "Region 2",
                label: "Region 2",
                value: "Region 2",
                disable: false
            },
            {
                id: "Region 3",
                label: "Region 3",
                value: "Region 3",
                disable: false
            }
        ],
        defaultOption: []
    },
    store_segment: {
        title: "Store Segment",
        options: [
            {
                id: "StoreSegment 1",
                label: "StoreSegment 1",
                value: "StoreSegment 1",
                disable: false
            }, {
                id: "StoreSegment 2",
                label: "StoreSegment 2",
                value: "StoreSegment 2",
                disable: false
            },
            {
                id: "StoreSegment 3",
                label: "StoreSegment 3",
                value: "StoreSegment 3",
                disable: false
            }
        ],
        defaultOption: []
    },
};

export const responseOfDynamicFilter = {
    subchannel: {
        title: "Channel",
        options: [
            {
                id: "unique id",
                label: "SMKT",
                value: "SMKT",
                disable: false
            },
        ],
        defaultOption: []
    },
    geoLevel: {
        title: "Region",
        options: [
            {
                id: "unique id",
                label: "CENTRO",
                value: "CENTRO",
                disable: false
            },
            {
                id: "unique id",
                label: "NEROESTE",
                value: "NEROESTE",
                disable: false
            }
        ],
        defaultOption: []
    }
};

export const mapperForFilterName = {
    "sub_chnl": "channel",
    "geo_level": "region",
    "store_segment": "storeSegment",
    "level":"level"
}

export const mapperForOutputFilterName = {
    "Channel": "channel",
    "Region": "region",
    "StoreSegment": "storeSegment",
    "Level":"level"
}