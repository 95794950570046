import { Button, Switch, Typography, styled } from "@mui/material";

export const StyledTypography = styled(Typography)`
  font-family: Spartan, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: "20.16px";
`;

export const MandotoryTypography = styled(Typography)`
  font-family: Roboto, sans-serif;
  font-size: 9px;
  font-weight: 400;
  line-height: "11px";
`;

export const ManualSkuTypography = styled(Typography)`
  font-family: Mullish;
  font-size: 15px;
  font-weight: 800;
  line-height: 18.83px;
  color: #000000;
  letter-spacing: 0.4px;
  padding: 10px 13px 10px 25px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#08509A",
    "&.Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: "gray",
      },
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: "gray",
  },
}));

export const ViewEditButton = styled(Button)`
  background: ${({ disabled }) => (disabled ? '#ccc' : '#25d4cf')};
  color: ${({ disabled }) => (disabled ? '#666' : '#fff')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  
  &:hover {
    color: ${({ disabled }) => (disabled ? '#666' : '#fff')};
    background-color: ${({ disabled }) => (disabled ? '#ccc' : '#25d4cf')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
  
  font-size: 12px;
  padding: 5px 15px;
`;


export const ManualSkuButton = styled(Button)`
  background: #25d4cf;
  color: #fff;
  &:hover {
    color: #fff;
    background-color: #25d4cf;
  }
  font-size: 12px;
  padding: 5px 15px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 12,
  fontFamily:"Mulish",
  lineHeight:'15.06px'
}));

const DropdownTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    fontSize: 12,
    fontFamily:"Mulish",
    lineHeight:'15.06px',
    color:"black",
    opacity:"60%",
    marginBottom:"5px"
}));

const SectionTitle = styled(Typography)(() => ({
  color: "#0B0E1E",
  fontSize: 13,
  fontFamily:"Montserrat, sans-serif",
  fontWeight: "500"
}));

export {DropdownTitle, Title, SectionTitle}