import Highcharts from "highcharts";
import xrange from "highcharts/modules/xrange";
import draggable from "highcharts/modules/draggable-points";
import more from "highcharts/highcharts-more";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

if (typeof Highcharts === "object") {
  more(Highcharts);
  draggable(Highcharts);
  xrange(Highcharts);
}

export const formatValue = (value) => {
  const absValue = Math.abs(value);

  if (absValue >= 1e9) {
    return Highcharts.numberFormat(value / 1e9, 1) + "B";
  } else if (absValue >= 1e6) {
    return Highcharts.numberFormat(value / 1e6, 1) + "M";
  } else if (absValue >= 1e3) {
    return Highcharts.numberFormat(value / 1e3, 1) + "K";
  } else {
    return Highcharts.numberFormat(value, 1);
  }
};

export const findNearestMultiple = (number: number): number => {
  const orderOfMagnitude = Math.pow(10, Math.floor(Math.log10(number)));
  const magnitudes = [1, 2, 5];

  let result = number;

  magnitudes.forEach((magnitude) => {
    const nearestMultiple =
      Math.ceil(number / (orderOfMagnitude * magnitude)) *
      (orderOfMagnitude * magnitude);
    if (Math.abs(nearestMultiple - number) < Math.abs(result - number)) {
      result = nearestMultiple;
    }
  });

  return result;
};

export const calculateTickPositions = (
  max: number,
  min: number,
  ticks: number
): number[] => {
  const tickInterval: number = max / (ticks / 2);

  const positions: number[] = [];

  for (let i: number = Math.floor(ticks / 2); i >= 0; i--) {
    positions.push(-i * tickInterval);
  }

  positions.push(0);

  for (let i: number = 1; i <= Math.floor(ticks / 2); i++) {
    positions.push(i * tickInterval);
  }

  return positions;
};

export const QuadrantChartConfig = () => {
  return {
    chart: {
      type: "bubble",
      plotBorderWidth: 1,
      zoomType: "xy",
      backgroundColor: "#F1F5FE",
    },
    legend: {
      enabled: false,
    },
    zoomType: "xy",
    title: {
      text: "",
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    navigator: {
      enabled: true,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        alignTo: "spacingBox",
      },
    },
    navigation: {
      buttonOptions: {
        enabled: true,
      },
    },
    xAxis: {
      lineColor: "transparent",
      allowDecimals: true,
      showLastLabel: true,
      endOnTick: true,
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          const value = this.value;
          const absValue = Math.abs(value);

          if (absValue >= 1e9) {
            return Highcharts.numberFormat(value / 1e9, 2) + "B";
          } else if (absValue >= 1e6) {
            return Highcharts.numberFormat(value / 1e6, 2) + "M";
          } else if (absValue >= 1e3) {
            return Highcharts.numberFormat(value / 1e3, 2) + "K";
          } else {
            return Highcharts.numberFormat(value, 2);
          }
        },
      },
      plotLines: [
        {
          color: "#2F5597",

          width: 2,
          value: 76,
          label: {
            // rotation: 0,
            // y: 15,
            style: {
              fontStyle: "italic",
            },
            text: "Average X",
          },
          // zIndex: 3,
        },
      ],
    },
    yAxis: {
      allowDecimals: true,
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          const value = this.value;
          const absValue = Math.abs(value);

          if (absValue >= 1e9) {
            return Highcharts.numberFormat(value / 1e9, 1) + "B";
          } else if (absValue >= 1e6) {
            return Highcharts.numberFormat(value / 1e6, 1) + "M";
          } else if (absValue >= 1e3) {
            return Highcharts.numberFormat(value / 1e3, 1) + "K";
          } else {
            return Highcharts.numberFormat(value, 1);
          }
        },
      },
      maxPadding: 0.2,

      plotLines: [
        {
          color: "#2F5597",

          width: 2,
          value: 14,
          label: {
            align: "right",
            style: {
              fontStyle: "italic",
            },
            text: "Average Y",
            // x: -9,
          },
          // zIndex: 3,
        },
      ],
    },
    tooltip: {
      useHTML: true,
      headerFormat: "<table>",
      pointFormatter: function () {
        return (
          '<tr><th colspan="2"><h3>' +
          this.name +
          "</h3></th></tr>" +
          "<tr><td></td><td>" +
          this.xAxis +
          ": " +
          formatValue(this.x) +
          "</td></tr>" +
          "<tr><td></td><td>" +
          this.yAxis +
          ": " +
          formatValue(this.y) +
          "</td></tr>" +
          "<tr><td></td><td>" +
          this.bubbleSize +
          ": " +
          formatValue(this.z) +
          "</td></tr>"
        );
      },
      footerFormat: "</table>",
      followPointer: true,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
          format: "{point.name}",
        },
      },
      cursor: "pointer",
      point: {
        events: {
          click: function () {
            console.log("X: " + this.x + ", Y: " + this.y);
          },
        },
      },
    },
    // bubble: {
    //   cursor: 'pointer',
    //   point: {
    //     events: {
    //       click: function () {
    //         // Open a popup or perform any action on bubble click
    //         const xValue = this.x;
    //         const yValue = this.y;
    //         const popupContent = `Bubble clicked! X: ${xValue}, Y: ${yValue}`;

    //         // Example: Open a simple alert popup
    //         alert(popupContent);
    //       }
    //     }
    //   }
    // },
    series: [
      {
        data: data,
      },
    ],
  };
};

export const data = [];

export const colors = [
  "#1A99D5",
  "#EA1F28",
  "#F77B36",
  "#15A156",
  "#F2C2A6",
  "#F5A623",
  "#87D4F9",
  "#B3BCC7",
  "#50E3C2",
  "#50E3C2",
  "#2F5597",
  "#7C031A",
  "#F1F1F5",
  "#8B8BC6",
  "#48B1C8",
  "#DADC62",
  "#DC62BA",
  "#EE9B9B",
  "#2B7B71",
  "#5BC09C",
];

export const getRandomHexColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
