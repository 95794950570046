import {
  GET_YOUR_FAVORITE_DASH,
  GET_YOUR_FAVORITE_DASH_SUCCESS,
  GET_YOUR_FAVORITE_DASH_FAILURE,
} from "../../actions/YourDashboard/constant";

let favoriteDashInitialState = {
  data: null,
  loading: true,
  error: null,
};

const FavoriteDash = (
  state = favoriteDashInitialState,
  action
) => {
  switch (action.type) {
    case GET_YOUR_FAVORITE_DASH:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case GET_YOUR_FAVORITE_DASH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case GET_YOUR_FAVORITE_DASH_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default FavoriteDash;
