import Highcharts from 'highcharts';
import { formatNumber } from '../../../util/helper';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

export const ColumnDrilldownConfig = (xAxis, data) => {
  return {
    chart: {
      backgroundColor: 'transparent',
    },
    title: null,
    xAxis: {
      min: 0,
      gridLineWidth: 1,
      categories: data?.xAxisData,
      labels: {
        formatter: function () {
          return '$' + this.value;
        },
      },
    },
    yAxis: [
      {
        lineWidth: 1,
        title: {
          text: 'Unit Sales',
          style: {
            fontSize: 18,
            fontWeight: 600,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
        },
        labels: {
          formatter: function () {
            return formatNumber(this.value.toFixed(2));
          },
        },
      },
      {
        lineWidth: 1,
        opposite: true,
        title: {
          text: 'Number of Weeks at This Price Point',
          style: {
            fontSize: 18,
            fontWeight: 600,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
        },
        labels: {
          formatter: function () {
            return formatNumber(this.value.toFixed(2));
          },
        },
      },
    ],
    plotOptions: {
      series: {
        marker: {
          fillColor: '#FFF',
          lineWidth: 2,
          lineColor: null,
          symbol: 'circle',
        },
      },
      area: {
        color: '#F5B7D6',
      },
      column: {
        color: '#598ee3',
        pointWidth: 20,
      },
    },
    legend: {
      layout: 'horizontal',
      backgroundColor: 'transparent',
      align: 'center',
      verticalAlign: 'top',
      symbolHeight: 12,
      symbolWidth: 12,
      symbolRadius: 0,
    },
    series: [
      {
        type: 'area',
        name: 'Unit Sales',
        data: data?.areaData,
        tooltip: {
          pointFormatter: function () {
            return formatNumber(this.y);
          },
        },
      },
      {
        yAxis: 1,
        type: 'column',
        name: 'Number of Weeks at This Price Point',
        data: data?.columnData,
        tooltip: {
          pointFormatter: function () {
            return formatNumber(this.y);
          },
        },
      },
    ],
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    credits: false,
  };
};

export const ColumnDrilldownNewConfig = {
  chart: {
    backgroundColor: 'transparent',
  },
  title: null,
  xAxis: {
    min: 0,
    gridLineWidth: 1,
    categories: [],
    labels: {
      formatter: function () {
        return '$' + this.value;
      },
    },
  },
  yAxis: [
    {
      lineWidth: 1,
      title: {
        text: 'Unit Sales',
        style: {
          fontSize: 18,
          fontWeight: 600,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value.toFixed(2));
        },
      },
    },
    {
      lineWidth: 1,
      opposite: true,
      title: {
        text: 'Number of Weeks at This Price Point',
        style: {
          fontSize: 18,
          fontWeight: 600,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value.toFixed(2));
        },
      },
    },
  ],
  plotOptions: {
    series: {
      marker: {
        fillColor: '#FFF',
        lineWidth: 2,
        lineColor: null,
        symbol: 'circle',
      },
    },
    area: {
      color: '#F5B7D6',
    },
    column: {
      color: '#598ee3',
      pointWidth: 20,
    },
  },
  legend: {
    layout: 'horizontal',
    backgroundColor: 'transparent',
    align: 'center',
    verticalAlign: 'top',
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
  },
  series: [
    {
      type: 'area',
      name: 'Unit Sales',
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      yAxis: 1,
      type: 'column',
      name: 'Number of Weeks at This Price Point',
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  credits: false,
};

export const ColumnDrilldownNewConfigForBar = {
  chart: {
    height: 300,
    backgroundColor : '#F1F5FE'
  },
  title: null,
  xAxis: {
    min: 0,
    categories: [],
    labels: {
      formatter: function () {
        return this.value; 
      },
    },
  },
  yAxis: [
    {
      lineWidth: 0,
      title: {
        text: '',
        style: {
          fontSize: 18,
          fontWeight: 600,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
      },
      labels: {
        formatter: function () {
          return this.value;
        },
      },
    },
  ],
  plotOptions: {
    series: {
      marker: {
        fillColor: '#FFF',
        lineWidth: 2,
        lineColor: null,
        symbol: 'circle',
      },
      
    },
    column : {
      borderRadius : 5,
    }
  },
  legend: {
    layout: 'horizontal',
    backgroundColor: 'transparent',
    align: 'center',
    verticalAlign: 'bottom',
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 3,
    symbolPadding: 10,
  },
  series: [
    {
      type: 'column',
      name: 'Must have',
      data: [],
      color : '#F77B36',
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y) + '%';
        },
      },
      dataLabels: {
        enabled: true,
        color : '#F77B36',
        formatter : function(){
          return this.y + "%"
        }
      }
      
    },
    {
      type: 'column',
      name: 'Good to have',
      data: [],
      color : '#15A156',
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y) + '%';
        },
      },
      dataLabels: {
        enabled: true,
        color : '#15A156',
        formatter : function(){
          return this.y + "%"
        }
      }
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  credits: false,
};