import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 12,
  fontFamily:"Mulish",
  lineHeight:'15.06px'
}));

const DropdownTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    fontSize: 12,
    fontFamily:"Mulish",
    lineHeight:'15.06px',
    color:"black",
    opacity:"60%",
    marginBottom:"5px"
}));

const ExpandIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  background: theme.palette.mode === "dark" ? theme.palette.common.white : "#F2F8FC",
  color: theme.palette.common.black
}));

export { Title, DropdownTitle, ExpandIcon };

