import React, { useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import { DropdownTitle } from "./GeoFilters.Styled";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchAssortmentGeoFiltersData,
  fetchAssortmentGeoFiltersFailure,
  fetchAssortmentGeoFiltersRequest,
  fetchAssortmentGeoFiltersSuccess,
  fetchPenetrationAvgData,
  setAssortmentGeoFilterVisibility,
  setDefaultGeoFilters,
  setPenetrationViewMode,
} from "../../../../../store/actions//AssortmentTool/assortmentFilters.action";
import AssortmentDropdown from "../../../../../components/AssortmentDropdown";
import {
  fetchAssortmentSummaryData,
  setViewMode,
} from "../../../../../store/actions//AssortmentTool/assortmentDashboard.action";
import { makeStyles } from "@material-ui/core/styles";
import { fetchApi } from "../../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import { ApplyBtn, ClearBtn } from "../../../../../styles/Common.Styled";
import Skeletons from "../../../Skeletons/Skeletons";
import { hasNullOrBlankValue } from "../../CommonComponents/utils";
import { API_URL } from "../../../../../store/actions/constants";

const useStyles = makeStyles((theme) => ({
  asterik: { color: "red" },
}));

export const initialState = {
  channel: null,
  region: null,
  storeSegment: null,
};

const GeoFilters: React.FC<{
  data;
  onSelectFilters;
  selectedProductFilters?;
  isViewEdit;
}> = ({
  data,
  onSelectFilters,
  selectedProductFilters,
  isViewEdit,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const AssortmentFiltersData = useSelector(
    (state: any) => state.AssortmentFiltersReducer
  );
  const {
    uid,
    defaultProductFilters,
    penetrationLimit,
    isGeoFiltersVisible,
    isCSVUploaded,
  } = useSelector((state: any) => state.AssortmentFiltersReducer);
  const [prevAssortmentFiltersData, setPrevAssortmentFiltersData] = useState(
    AssortmentFiltersData
  );

  const [selectedGeoFilters, setSelectedGeoFilters] = useState({
    ...initialState,
  });

  const [disabledGeoFilters, setDisabledFilters] = useState({
    channel: false,
    region: false,
    storeSegment: false,
  });

  const [selectedValues, setSelectedValues] = useState({
    channel: "",
    region: "",
    storeSegment: "",
  });

  const [channels, setChannels] = useState("");
  const [regions, setRegions] = useState("");
  const [storeSegments, setStoreSegments] = useState("");

  const handleFilterChange = async (filterKey, handleFunction, data) => {
    try {
      const response = data[filterKey];
      const filterObjects = response.map((item) => ({
        id: item,
        label: item,
        value: item,
        default: false,
      }));
      handleFunction(filterObjects);
    } catch (error) {
      console.error(`Error handling ${filterKey} data:`, error);
    }
  };

  useEffect(() => {
    // Check if "region" data has changed
    if (
      AssortmentFiltersData.region !== prevAssortmentFiltersData.region &&
      AssortmentFiltersData.region !== null &&
      AssortmentFiltersData.region !== undefined &&
      AssortmentFiltersData.region !== ""
    ) {
      handleFilterChange("region", setRegions, AssortmentFiltersData);
    }

    // Check if "channel" data has changed
    if (
      AssortmentFiltersData.channel !== prevAssortmentFiltersData.channel &&
      AssortmentFiltersData.channel !== null &&
      AssortmentFiltersData.channel !== undefined &&
      AssortmentFiltersData.channel !== ""
    ) {
      handleFilterChange("channel", setChannels, AssortmentFiltersData);
    }

    // Check if "storeSegment" data has changed
    if (
      AssortmentFiltersData.storeSegment !==
        prevAssortmentFiltersData.storeSegment &&
      AssortmentFiltersData.storeSegment !== null &&
      AssortmentFiltersData.storeSegment !== undefined &&
      AssortmentFiltersData.storeSegment !== ""
    ) {
      handleFilterChange(
        "storeSegment",
        setStoreSegments,
        AssortmentFiltersData
      );
    }
  }, [AssortmentFiltersData, prevAssortmentFiltersData]);

  useEffect(() => {
    clearGeoFilter();
    setChannels(null);
    setRegions(null);
    setStoreSegments(null);
  }, [selectedProductFilters]);

  useEffect(() => {
    if (selectedGeoFilters) {
      onSelectFilters({ ...selectedGeoFilters });
    }
  }, [selectedGeoFilters]);

  useEffect(() => {
    if (isViewEdit) {
      const { country, dateTimePeriod, businessUnit } = defaultProductFilters;
      if (country === "" && dateTimePeriod === "" && businessUnit == "") {
        return;
      }
      dispatch(fetchAssortmentGeoFiltersRequest());
      dispatch(
        fetchApi(
          {
            default: true,
            level: penetrationLimit.chooseLevel,
            country: country,
            dateTimePeriod: dateTimePeriod,
            businessUnit: businessUnit,
            channel: "",
            region: "",
            storeSegment: "",
          },
          API_URL.ASSORTMENT.GEO_FILTERS,
          null
        )
      )
        .then((res) => {
          dispatch(setAssortmentGeoFilterVisibility(true));
          dispatch(fetchAssortmentGeoFiltersSuccess(res.data));
          handleFilterChange("channel", setChannels, res.data);
          handleFilterChange("region", setRegions, res.data);
          handleFilterChange("storeSegment", setStoreSegments, res.data);
          const { channel, region, storeSegment } = res.data;
          let updatedValues = {
            channel: channel[0],
            region: region[0],
            storeSegment: storeSegment[0],
          };
          setSelectedValues(updatedValues);
          setSelectedGeoFilters(updatedValues);
          dispatch(setDefaultGeoFilters(updatedValues));
          applyGeoFilters(updatedValues);
        })
        .catch((err) => {
          dispatch(fetchAssortmentGeoFiltersFailure(err.message));
        });
    }
  }, [defaultProductFilters]);

  const onChangeGeoLevel = (key, data) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [key]: data[0],
    }));

    handleChangesForOtherFilters({ [key]: data[0] }, key);
    const payload = { ...selectedGeoFilters, [key]: data[0] };

    if (key === "storeSegment") {
      setSelectedGeoFilters(payload);
    }
  };

  const handleChangesForOtherFilters = (data, key) => {
    let payload = {};
    if (key === "storeSegment") {
      const updatedValues = {
        channel: selectedValues.channel,
        region: selectedValues.region,
        storeSegment: selectedValues.storeSegment,
        ...data,
      };
      setSelectedValues(updatedValues);
      setSelectedGeoFilters(updatedValues);
    } else if (key === "region") {
      const updatedValues = {
        channel: selectedValues.channel,
        region: selectedValues.region,
        storeSegment: "",
        ...data,
      };
      setSelectedValues(updatedValues);
      setSelectedGeoFilters(updatedValues);
      payload = {
        ...selectedProductFilters,
        ...updatedValues,
        default: false,
        level: penetrationLimit.chooseLevel,
      };
      let finalPayload = { ...payload };
      dispatch(
        fetchAssortmentGeoFiltersData(finalPayload, "getAssortmentGeoFilter")
      );
      dispatch(setPenetrationViewMode(false));
    } else {
      const updatedValues = {
        channel: selectedValues.channel,
        region: "",
        storeSegment: "",
        ...data,
      };
      setSelectedValues(updatedValues);
      setSelectedGeoFilters(updatedValues);
      payload = {
        default: false,
        ...selectedProductFilters,
        ...updatedValues,
        level: penetrationLimit.chooseLevel,
      };
      let finalPayload = { ...payload };
      dispatch(
        fetchAssortmentGeoFiltersData(finalPayload, "getAssortmentGeoFilter")
      );
    }
    dispatch(setPenetrationViewMode(false));
    dispatch(setViewMode(false));
  };

  const applyGeoFilters = (filters) => {
    if (uid === null) return;
    const payload = {
      uid: uid,
      channel: filters.channel,
      region: filters.region,
      storeSegment: filters.storeSegment,
    };

    dispatch(fetchAssortmentSummaryData(payload,API_URL.ASSORTMENT.SUMMARY_DATA));
    dispatch(
      fetchPenetrationAvgData(
        payload,
      API_URL.ASSORTMENT.CURRENT_AVERAGE      )
    );
    dispatch(setPenetrationViewMode(true));
  };

  useEffect(() => {
    if (isCSVUploaded === true) {
      applyGeoFilters(selectedValues);
    }
  }, [isCSVUploaded]);

  const clearGeoFilter = () => {
    setSelectedGeoFilters({
      channel: null,
      region: null,
      storeSegment: null,
    });
    setSelectedValues({
      channel: "",
      region: "",
      storeSegment: "",
    });
    dispatch(setPenetrationViewMode(false));
    dispatch(setViewMode(false));
  };

  return (
    <Grid container>
      {!isGeoFiltersVisible ? (
        <Skeletons type={"GeoFilters"} />
      ) : (
        <>
          <Grid item style={{ marginLeft: 0 }}>
            <DropdownTitle>
              {data.channel.title}
              <span className={classes.asterik}>*</span>
            </DropdownTitle>
            <AssortmentDropdown
              disabled={disabledGeoFilters.channel}
              data={channels}
              onChange={(data) => onChangeGeoLevel("channel", data)}
              placeholder={data.channel.placeholder}
              multiple={data.channel.multiple}
              allOption={data.channel.all}
              defaultOption={
                selectedValues.channel ? [selectedValues.channel] : []
              }
            />
          </Grid>
          <Grid item style={{ marginLeft: "20px" }}>
            <DropdownTitle>
              {data.region.title}
              <span className={classes.asterik}>*</span>
            </DropdownTitle>
            <AssortmentDropdown
              disabled={disabledGeoFilters.region}
              data={regions}
              onChange={(data) => onChangeGeoLevel("region", data)}
              placeholder={data.region.placeholder}
              multiple={data.region.multiple}
              allOption={data.region.all}
              defaultOption={
                selectedValues.region ? [selectedValues.region] : []
              }
            />
          </Grid>
          <Grid item style={{ marginLeft: "20px" }}>
            <DropdownTitle>
              {data.storeSegment.title}
              <span className={classes.asterik}>*</span>
            </DropdownTitle>
            <AssortmentDropdown
              disabled={disabledGeoFilters.storeSegment}
              data={storeSegments}
              onChange={(data) => onChangeGeoLevel("storeSegment", data)}
              placeholder={data.storeSegment.placeholder}
              multiple={data.storeSegment.multiple}
              allOption={data.storeSegment.all}
              defaultOption={
                selectedValues.storeSegment ? [selectedValues.storeSegment] : []
              }
            />
          </Grid>
          <Grid container justifyContent="flex-end">
            {hasNullOrBlankValue(selectedProductFilters) ? (
              <>
                <Grid item>
                  <Skeleton
                    variant="rectangular"
                    height={30}
                    width={80}
                    style={{ padding: "10px" }}
                  />
                </Grid>
                <Grid style={{ marginLeft: "10px" }} item>
                  <Skeleton
                    variant="rectangular"
                    height={30}
                    width={80}
                    style={{ padding: "10px" }}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <ApplyBtn onClick={() => applyGeoFilters(selectedValues)}>
                    Apply
                  </ApplyBtn>
                </Grid>
                <Grid style={{ marginLeft: "10px" }} item>
                  <ClearBtn className="m-r-20" onClick={clearGeoFilter}>
                    Clear Filter
                  </ClearBtn>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default GeoFilters;
