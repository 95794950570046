import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";



const Title = styled(Typography)(() => ({
  color: "#7E7E7E",
  fontSize: 14,
  fontFamily:"Mulish, sans-serif",
  fontWeight: "700"
}));

const SectionTitle = styled(Typography)(() => ({
    color: "#0B0E1E",
    fontSize: 13,
    fontFamily:"Montserrat, sans-serif",
    fontWeight: "500"
  }));

export { Title,SectionTitle };
