import React, { useEffect, useRef, useState } from "react";
import { StyledTypography } from "../../AssortmentTool.Styled";
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import { chartConfigForAssortment } from "./chartConfig";
import { WaterfallChartAssortment } from "./WaterfallChartAssortment";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import Indicator from "../../../../components/Loader/Loader";

export const SizeOfPrize = ({
  isRevenue,
  filtersPayload,
  setisRevenueColumns,
  selectedGeoFilters,
}) => {
  const { sizeOfPrice, loaderForSizeOfPrize, viewMode } = useSelector(
    (state: any) => state.assortmentDashboardReducer
  );
  const dataForWaterfall = [
    {
      type: "column",
      name: `Current ${isRevenue ? "Revenue" : "Profit"}`,
      value: 10,
      percentValue: 0,
      color: "#1A99D5",
      level: "",
    },
    {
      type: "column",
      name: "Must have SKUs Incremental",
      value: 3,
      percentValue: 0,
      color: "#F2C2A6",
      level: "",
    },
    {
      type: "column",
      name: "Good to have SKUs Incremental",
      value: 2,
      percentValue: 0,
      color: "#F77B36",
      level: "",
    },
  ];
  const [waterfallData, setWaterfallData] = useState(dataForWaterfall);
  const levels = [
    {
      name: "Profit",
      value: "nopbt",
    },
    {
      name: "Revenue",
      value: "revenue",
    },
    {
      name: "Market Share",
      value: "marketShare",
    },
  ];

  const levelMapper = {
    nopbt: "NOPBT",
    revenue: "Revenue",
    marketShare: "Market Share",
  };

  const [selectedLevel, setSelectedLevel] = useState("nopbt");
  const chartRefWaterfall = useRef(null);

  const transformResponseToWaterfallData = (response) => {
    const dataForWaterfall = [
      {
        type: "column",
        name: `Current ${levelMapper[selectedLevel]}`,
        value:
          selectedLevel === "marketShare"
            ? parseFloat(
                response[
                  `current${
                    selectedLevel.charAt(0).toUpperCase() +
                    selectedLevel.slice(1)
                  }`
                ]
              ) * 100 || 0
            : parseFloat(
                response[
                  `current${
                    selectedLevel.charAt(0).toUpperCase() +
                    selectedLevel.slice(1)
                  }`
                ]
              ) || 0,
        percentValue: 0,
        color: "#1A99D5",
        level: selectedLevel,
      },
      {
        type: "column",
        name: "Must have SKUs Incremental",
        value:
          selectedLevel === "marketShare"
            ? parseFloat(response["mustHaveSkuIncremental"]) * 100 || 0
            : parseFloat(response["mustHaveSkuIncremental"]) || 0,
        percentValue:
          parseFloat(response["percentageMustHaveSkuIncremental"]) || 0,
        color: "#F2C2A6",
        level: selectedLevel,
      },
      {
        type: "column",
        name: "Good to have SKUs Incremental",
        percentValue:
          parseFloat(response["percentageGoodToHaveSkuIncremental"]) || 0,
        value:
          selectedLevel === "marketShare"
            ? parseFloat(response["goodToHaveSkuIncremental"]) * 100 || 0
            : parseFloat(response["goodToHaveSkuIncremental"]) || 0,
        color: "#F77B36",
        level: selectedLevel,
      },
    ];

    return dataForWaterfall;
  };

  useEffect(() => {
    if (sizeOfPrice) {
      let value = selectedLevel;
      const transformedData = transformResponseToWaterfallData(
        sizeOfPrice[`${value}`]
      );
      console.log("trrrrr", transformedData);
      setWaterfallData(transformedData);
    }
  }, [sizeOfPrice, isRevenue, selectedLevel]);

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
  };

  return (
    <>
      <StyledTypography>Size Of Prize (Avg. Monthly)</StyledTypography>
      <Card
        style={{
          marginTop: "1%",
          backgroundColor: "#F1F5FE",
          position: "relative",
        }}
      >
        <Indicator position="absolute" show={loaderForSizeOfPrize} />
        <CardContent>
          <Grid display="flex" justifyContent="flex-end">
            {!viewMode ? (
              <Grid
                style={{ marginBottom: "10px" }}
                display="flex"
                justifyContent="flex-end"
                container
              >
                <Skeleton variant="rectangular" width={170} height={30} />
              </Grid>
            ) : (
              <Box display="flex" alignItems="center">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    width: "240px",
                  }}
                >
                  <Typography
                    style={{
                      opacity: "0.6",
                      fontFamily: "Mullish",
                      fontSize: "12px",
                      fontWeight: "700",
                    }}
                    color={"black"}
                  >
                    View By:{" "}
                  </Typography>
                  <FormControl sx={{ m: 1, minWidth: 146}} size="small">
                    <Select
                      labelId="select-small-label"
                      id="select-small"
                      value={selectedLevel}
                      style={{height:30}}
                      onChange={handleLevelChange}
                    >
                      {levels.map((element) => {
                        return (
                          <MenuItem value={element.value}>
                            {element.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </Box>
            )}
          </Grid>
        </CardContent>
        {!viewMode ? (
          <Grid style={{ marginBottom: "15px" }} container>
            <Skeleton
              style={{ margin: "0 auto" }}
              variant="rectangular"
              width="98%"
              height={145}
            />
          </Grid>
        ) : (
          <WaterfallChartAssortment
            chartConfig={chartConfigForAssortment}
            chartRef={chartRefWaterfall}
            data={waterfallData}
            id={`historic-price-track-cause-of-business-dummy-waterfall`}
          />
        )}
      </Card>
    </>
  );
};
