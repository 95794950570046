import { useEffect } from "react";
import { API } from "../../../util/helper";
import {
  MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_REQUEST,
  MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_SUCCESS,
  MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_FAILURE,
  RESET_MIX_USER_CONSTRAINTS_CSV_DATA,
  MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_OPERATIVE_DATA_SUCCESS,
  MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_CAPACITY_DATA_SUCCESS,
  BACKEND_URL,
  UPLOAD_OPERATIVE_COMPLEXITY,
  UPLOAD_CAPACITIVE_COMPLEXITY,
} from "../constants";
import Papa from "papaparse";

export const fetchMixUserConstraintsDataRequest = () => ({
  type: MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_REQUEST,
});

export const fetchMixUserCSVForOperativeDataSuccess = (data) => ({
  type: MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_OPERATIVE_DATA_SUCCESS,
  payload: data,
});

export const fetchMixUserCSVForCapacityDataSuccess = (data) => ({
  type: MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_CAPACITY_DATA_SUCCESS,
  payload: data,
});

export const fetchOrDownloadMixUserConstraintsDataSuccess = (data) => ({
  type: MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_SUCCESS,
  payload: data,
});
export const resetMixConstrainCsvDataOfCapacityConstraintAndOperativeComplexity = () => ({
  type: RESET_MIX_USER_CONSTRAINTS_CSV_DATA,
});

export const fetchOrDownloadMixUserConstraintsDataFailure = (error) => ({
  type: MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_FAILURE,
  payload: error,
});

export const fetchOrDownloadMixUserConstraintsData = (type,payload, apiUrl) => {
  return async (dispatch) => {
    dispatch(fetchMixUserConstraintsDataRequest());
    
    try {
      const response = await API.post(
        `${BACKEND_URL}/api/output/${apiUrl}`,
        payload
        );
        const downloadedData = response.data;

        const lines = downloadedData.trim().split('\n').map(line => line.trim());
        const headerRow = lines[0].split(',');
        console.log(lines)
        // Adding 'id' as the first header
        const headers = ['id', ...headerRow];
        console.log("headers",headers)
        const jsonData:any = [];
        lines.slice(1).forEach((line, index) => {
            const data = line.split(',');
            const entry = {};
        
            headers.forEach((header, columnIndex) => {
                if (columnIndex === 0) {
                    entry[header] = String(index + 1);
                } else {
                    entry[header] = data[columnIndex-1];
                }
            });
        
            jsonData.push(entry);
        });
        console.log("jsonData",jsonData)

        if(type === "Capacity Constraint") {
          
          dispatch(fetchMixUserCSVForCapacityDataSuccess(jsonData))
        } else {
          dispatch(fetchMixUserCSVForOperativeDataSuccess(jsonData))
        }
      const csvContent = Papa.unparse(jsonData)
      // const csvData = response.data;
      const file = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = `${type}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      dispatch(fetchOrDownloadMixUserConstraintsDataFailure(error.message));
    }
  };
};

export const uploadMixUserConstraintsData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(fetchMixUserConstraintsDataRequest());
    
    try {
      const response = await API.post(
        `${BACKEND_URL}/api/output/${apiUrl}`,
        data
        );
      console.log("response", response);
      dispatch(fetchOrDownloadMixUserConstraintsDataSuccess(response.data));
    } catch (error) {
      dispatch(fetchOrDownloadMixUserConstraintsDataFailure(error.message));
    }
  };
};

export const uploadOperativeComplexityFile = (payload) => ({
  type : UPLOAD_OPERATIVE_COMPLEXITY,
  payload
});

export const uploadCapacitiveComplexityFile = (payload) => ({
  type : UPLOAD_CAPACITIVE_COMPLEXITY,
  payload
})
