export const GET_TRENDING_DASH = "GET_TRENDING_DASH";
export const GET_TRENDING_DASH_SUCCESS = "GET_TRENDING_DASH_SUCCESS";
export const GET_TRENDING_DASH_FAILURE = "GET_TRENDING_DASH_FAILURE";
export const POST_TRENDING = "POST_TRENDING"
export const POST_TRENDING_SUCCESS = "POST_TRENDING_SUCCESS"
export const  POST_TRENDING_FAILURE = "POST_TRENDING_FAILURE"
//Constants for Favorite Dashboard
export const GET_YOUR_FAVORITE_DASH = "GET_YOUR_FAVORITE_DASH";
export const GET_YOUR_FAVORITE_DASH_SUCCESS = "GET_YOUR_FAVORITE_DASH_SUCCESS";
export const GET_YOUR_FAVORITE_DASH_FAILURE = "GET_YOUR_FAVORITE_DASH_FAILURE";

export const GET_YOUR_SAVED_DASH = "GET_YOUR_SAVED_DASH";
export const GET_YOUR_SAVED_DASH_SUCCESS = "GET_YOUR_SAVED_DASH_SUCCESS";
export const GET_YOUR_SAVED_DASH_FAILURE = "GET_YOUR_SAVED_DASH_FAILURE";
