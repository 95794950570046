import React from "react";
import { CardContent, Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { SectionTitle, Title } from "./Constraints.Styled";
import TextFieldInput from "../../CommonComponents/TextFieldInput/TextFieldInput";

const useStyles = makeStyles((theme) => ({
  asterik: { color: "red" },
}));

const Constraints = ({ onInputChange, inputValues, setInputValues }) => {
  const classes = useStyles();

  const handleInputChange = (fieldName, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
    onInputChange(fieldName, value);
  };

  return (
    <>
      <CardContent sx={{ backgroundColor: "#F1F5FE", color: "#fff" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Title color="black">Constraints (in %)</Title>
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={1}>
              <SectionTitle color="black">
                Capacity Constraint<span className={classes.asterik}>*</span>
              </SectionTitle>
            </Grid>
            <Grid item xs={2}>
              <TextFieldInput
                defaultValue={inputValues.capacityConstraint}
                callback={(value) =>
                  handleInputChange("capacityConstraint", value)
                }
              />
            </Grid>
            <Grid item xs={1}>
              <SectionTitle>
                Operative Complexity<span className={classes.asterik}>*</span>
              </SectionTitle>
            </Grid>
            <Grid item xs={2}>
              <TextFieldInput
                defaultValue={inputValues.operativeComplexity}
                callback={(value) =>
                  handleInputChange("operativeComplexity", value)
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

export default Constraints;
