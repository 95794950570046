import {
    DISMISS_NOTIFICATION,
    DISMISS_NOTIFICATION_SUCCESS,
    DISMISS_NOTIFICATION_FAILURE,
  } from "../../actions/AlertAndNotification/constants";
  
  let DismissNotificationInitialState = {
    data: null,
    loading: true,
    error: null,
  };
  
  const DismissNotification = (state = DismissNotificationInitialState, action) => {
    switch (action.type) {
      case     DISMISS_NOTIFICATION:
        return {
          ...state,
          data: null,
          loading: true,
          error: null,
        };
      case DISMISS_NOTIFICATION_SUCCESS:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null,
        };
      case DISMISS_NOTIFICATION_FAILURE:
        return {
          ...state,
          data: null,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default DismissNotification;
  