import React, { useEffect, useRef } from 'react'

const MixGuidelinesComponent = ({maximumMarketShare,maximumProfitMargin,maximumNetRevenue}) => {
    const canvasRef = useRef(null);
    const createTriangle = (ctx, color, midpoint) => {
        ctx.beginPath();
        ctx.moveTo((midpoint - 5), 10);
        ctx.lineTo((midpoint + 5), 10);
        ctx.lineTo(midpoint, 20);
        ctx.lineTo((midpoint - 5), 10);
        ctx.strokeStyle = color;
        ctx.fillStyle = color;
        ctx.stroke()
        ctx.fill();
        ctx.closePath();
        ctx.beginPath();
        ctx.moveTo(midpoint, 20);
        ctx.lineTo(midpoint, 40);
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#FFF';
        ctx.stroke()
        ctx.fill();
        ctx.closePath();
    }
    useEffect(() => {
        const canvas: any = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const heightOfBar = 10;
        const cornerRadius = 5;
        const width = 200;
        ctx.beginPath();
        ctx.moveTo(cornerRadius, 30);
        ctx.lineTo(width - cornerRadius, 30)
        ctx.arc(width - cornerRadius, 30 - cornerRadius + heightOfBar, cornerRadius, 1.5 * Math.PI, 0.5 * Math.PI);
        ctx.lineTo(cornerRadius, 30 + heightOfBar);
        ctx.arc(cornerRadius, 30 + cornerRadius, cornerRadius, 0.5 * Math.PI, 1.5 * Math.PI);
        ctx.strokeStyle = '#25d4cf';
        ctx.fillStyle = '#25d4cf';
        ctx.fill();
        ctx.stroke();
        createTriangle(ctx, '#FB1414', 50);
        createTriangle(ctx, '#F5ED2C', 100);
        createTriangle(ctx, '#29F061', 150);


    }, []);

    return (
        <canvas ref={canvasRef} width={200} height={60}  />
    )
}

export default MixGuidelinesComponent