import React, { useRef, useState } from "react";
import HighchartContainer from "../../../../components/HighchartContainer";
import QuadrantChart from "../../../../components/charts/QuadrantChart";
import { Box, Grid, CardContent, Card, Skeleton } from "@mui/material";
import { StyledCard } from "../../../../components/MMPW/MMPW.Styled";
import QuadrantFilters from "../FilterComponents/QuadrantFilters";
import mockData from "../../../../mocks/assortmentMocks";
import Indicator from "../../../../components/Loader/Loader";
import { useSelector } from "react-redux";
import { StyledTypography } from "../../AssortmentTool.Styled";
import { LightBlueBtn } from "../../../../styles/Common.Styled";
import { DownloadIcon } from "../../../../assets/icons/assorticons";
import {
  convertToCSVForQuadrant,
  downloadInExcelCsvFormat,
} from "../CommonComponents/utils";

const QuadrantGraph = ({ onSelectFilters, id, selectedGeoFilters }) => {
  const chartRef = useRef(null);

  const { loaderForQuadrantChart, quadrantData } = useSelector(
    (state: any) => state.assortmentDashboardReducer
  );

  const { loaderForQuadrantFilters, defaultQuadrantFilters } = useSelector(
    (state: any) => state.AssortmentFiltersReducer
  );

  const [selectedQuadrantFilters, setSelectedQuadrantFilters] = useState({
    xAxis: null,
    yAxis: null,
    bubbleSize: null,
  });

  const [clearFilterData, setClearFilterData] = useState(false);

  const handleQuadrantFiltersChange = (selectedFilters) => {
    setSelectedQuadrantFilters(selectedFilters);
    onSelectFilters(selectedFilters);
  };

  const columnsForDownload = [
    { title: `SKU`, key: "sku" },
    { title: `Segment`, key: "segment" },
    { title: `${defaultQuadrantFilters.xAxis}`, key: "xAxis" },
    { title: `${defaultQuadrantFilters.yAxis}`, key: "yAxis" },
    { title: `${defaultQuadrantFilters.bubbleSize}`, key: "bubbleSize" },
  ];

  const downloadQuadrantData = () => {
    const csvData = convertToCSVForQuadrant(
      quadrantData["chartData"],
      columnsForDownload
    );
    downloadInExcelCsvFormat(csvData, "quadrant_chart");
  };

  return (
    <>
      <Grid
        container
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <Grid item xs={12} md={6} style={{}}>
          <StyledTypography>Quadrant Graph</StyledTypography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            whiteSpace: "nowrap",
            display: "flex",
            justifyContent: "end",
          }}
        >
          {defaultQuadrantFilters.bubbleSize === "" ||
          defaultQuadrantFilters.bubbleSize === null ? (
            <>
              <Skeleton
                style={{ backgroundColor: "white" }}
                variant="rectangular"
                width={170}
                height={30}
              />
            </>
          ) : (
            <>
              <LightBlueBtn onClick={downloadQuadrantData}>
                <img style={{ marginRight: "5px" }} src={DownloadIcon} alt="" />
                Download
              </LightBlueBtn>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <StyledCard
          sx={{ backgroundColor: "#F1F5FE", color: "#fff", marginTop: "1rem" }}
        >
          <Card
            style={{
              backgroundColor: "#F1F5FE",
              position: "relative",
            }}
          >
            <Indicator
              position="absolute"
              show={loaderForQuadrantChart || loaderForQuadrantFilters}
            />
            <CardContent>
              <Grid>
                <Grid
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  container
                  spacing={2}
                >
                  <Grid item alignItems={"center"} paddingTop={"0"}>
                    <i>
                      <b>*NOTE</b>: To zoom in, use cursor to highlight the
                      section.
                    </i>
                  </Grid>
                  <Grid item spacing={2}>
                    <div style={{ marginTop: "1rem" }}>
                      <QuadrantFilters
                        data={{ ...mockData.quadrantFilterV2Config }}
                        clearFilter={clearFilterData}
                        onSelectFilters={handleQuadrantFiltersChange}
                        selectedGeoFilters={selectedGeoFilters}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Box className="m-b-10">
                  <HighchartContainer chartRef={chartRef} id={id}>
                    <QuadrantChart
                      chartRef={chartRef}
                      quadrantFilters={selectedQuadrantFilters}
                      selectedGeoFilters={selectedGeoFilters}
                    />
                  </HighchartContainer>
                </Box>
              </Grid>
            </CardContent>
          </Card>
        </StyledCard>
      </Grid>
    </>
  );
};

export default QuadrantGraph;
