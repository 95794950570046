import React, { useEffect, useState } from "react";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMixPortfolioData,
  setMixPortfolioLevel,
} from "../../../../store/actions/MixSimulationTool/mixPortfolio.action";
import {
  fetchMixGeoLevelData,
  setMixGeoLevel,
} from "../../../../store/actions/MixSimulationTool/mixGeoLevel.action";
import { API_URL } from "../../../../store/actions/constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectInput = ({ isMultiple, levels }) => {
  const [selectedSingleLevel, setSelectedSingleLevel] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [selectedMultipleLevel, setSelectedMultipleLevel] = useState<
    { id: number; name: string }[]
  >([]);

  useEffect(() => {
    setSelectedSingleLevel({
      id: 1,
      name: "Brand",
    });
    setSelectedMultipleLevel([levels[0]]);
  }, []);

  const dispatch = useDispatch();
  const selectedFilterData = useSelector(
    (state: any) => state.mixSimulationAllFilter.selectedFilters
  );

  const { uid, selectedGoal } = useSelector(
    (state: any) => state.mixSimulationUserInput
  );

  const handleSingleChange = (event) => {
    const {
      target: { value },
    } = event;

    let payload = {
      uid: uid,
      levels: [value["name"].toLowerCase()],
      region: selectedFilterData.region,
      storeSegment: selectedFilterData.storeSegment,
      goal:selectedGoal
    };
    dispatch(setMixPortfolioLevel(value["name"].toLowerCase()));
    dispatch(
      fetchMixPortfolioData(
        payload,
        API_URL.MIXSIMULATION.GET_PORTFOLIO_DATA_AFTER_RUN_SIMULATION
      )
    );
    setSelectedSingleLevel(value);
  };

  const levelsToSendInPayload = (arr) => {
    return arr.map((str) =>
      str.toLowerCase().replace(/\s+(.)/g, (match, val) => val.toUpperCase())
    );
  };

  const handleChange = (event) => {
    const { value } = event.target;
    console.log("value", value);
    setSelectedMultipleLevel(value);
  };

  const levelMultipleFilterClose = () => {
    const level = selectedMultipleLevel.map((item) => item.name);
    let payload = {
      uid: uid,
      level: levelsToSendInPayload(level).concat("channel"),
      region: selectedFilterData.region,
      storeSegment: selectedFilterData.storeSegment,
      goal:selectedGoal,
      default: true,
    };
    dispatch(setMixGeoLevel(levelsToSendInPayload(level)));
    dispatch(
      fetchMixGeoLevelData(
        payload,
        API_URL.MIXSIMULATION.GET_GEO_LEVEL_SUMMARY_DATA_AFTER_RUN_SIMULATION
      )
    );
  };

  return isMultiple ? (
    <>
      <FormControl sx={{ m: 0, width: 200, height: 30 }} size="small">
        <Select
          multiple
          value={selectedMultipleLevel}
          onChange={handleChange}
          renderValue={(selected) => {
            return selected.map((x) => x.name).join(", ");
          }}
          MenuProps={MenuProps}
          onClose={levelMultipleFilterClose}
          sx={{
            "&.MuiInputBase-root": {
              fontSize: "12px",
            },
            "&..MuiSelect-root": {
              fontSize: "12px",
            },
            lineHeight: "1.6em",
          }}
        >
          <MenuItem disabled value="channel">
            <Checkbox checked disabled />
            <ListItemText primary="Channel" />
          </MenuItem>
          {levels.map((level) => (
            <MenuItem key={level.id} value={level}>
              <Checkbox
                checked={
                  selectedMultipleLevel.findIndex(
                    (item) => item.id === level.id
                  ) >= 0
                }
              />
              <ListItemText
                primary={level.name}
                sx={{
                  "& .MuiTypography-root": {
                    color: "black",
                  },
                }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  ) : (
    <>
      <FormControl sx={{ m: 0, width: 200, height: 30 }} size="small">
        <Select
          value={selectedSingleLevel}
          onChange={handleSingleChange}
          renderValue={(selected: any) => {
            const selectedOption = levels.find(
              (option) => option.id === selected.id
            );
            return selectedOption ? selectedOption.name : "";
          }}
          MenuProps={MenuProps}
          sx={{
            "&.MuiInputBase-root": {
              fontSize: "12px",
            },
            "&..MuiSelect-root": {
              fontSize: "12px",
            },
            lineHeight: "1.6em",
          }}
        >
          {levels.map((level) => (
            <MenuItem key={level.id} value={level}>
              <ListItemText
                primary={level.name}
                sx={{
                  "& .MuiTypography-root": {
                    color: "black",
                  },
                }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectInput;
