import { styled } from "@mui/material/styles";
import { Container, Paper, Typography} from "@mui/material";

const SummaryPaper = styled(Paper)(({ theme }) => ({
    width: 240,
    height: 104,
    padding: "12px",
    background:"white",
    borderRadius:"4px",
    marginTop:"25px",
    marginBottom:"15px",
  }));
  
  const SummaryTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    fontSize: 14,
    fontFamily: "Inter, sans-serif",
    height:"34px",
    color: "#93A3AB",
    lineHeight: '16.94px',
      textAlign: 'center',
      marginBottom:"15px"
  }));
  
  const SummaryCardValue = styled(Typography)(({ theme }) => ({
    fontWeight: "700",
    fontSize: "20px",
    fontFamily: "Inter, sans-serif",
    color: "#000000",
    lineHeight: '22px',
  letterSpacing: '0em',
  textAlign: 'center',
  paddingLeft:"25px"
  }));

  export { SummaryPaper, SummaryTitle, SummaryCardValue };
