import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import AssignWeight from "./AssignWeight/AssignWeight";
import AsssignPenetrationTarget from "./AsssignPenetrationTarget/AsssignPenetrationTarget";
import Constraints from "./Constraints/Constraints";
import RunSimulation from "./RunSimulation/RunSimulation";
import UserInputNotes from "./UserInputNotes/UserInputNotes";
import { StyledCard } from "../../../../components/MMPW/MMPW.Styled";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRunSimlationData,
  setPenetrationLimitLevel,
} from "../../../../store/actions//AssortmentTool/assortmentFilters.action";
import { Skeleton } from "@mui/material";
import PenetrationLimit from "./PenetrationLimit/PenetrationLimit";
import { hasNullOrBlankValue, keysToRemoveInUserInput } from "../CommonComponents/utils";

const UserInputs = ({ productFilters, isViewEdit }) => {
  const { channel, allWeights, penetrationLimit, defaultProductFilters } =
    useSelector((state: any) => state.AssortmentFiltersReducer);

  const [userInputValues, setUserInputValues] = useState({});

  const [totalVal, setTotalVal] = useState({});

  const [inputValues, setInputValues] = useState({});
  const [inputConstraintsValues, setInputConstraintsValues] = useState({});
  const [inputPentrationTargetValues, setInputPentrationTargetValues] =
    useState({});
  const [inputPentrationLimitValues, setInputPentrationLimitValues] = useState(
    {}
  );

  const handleInputChange = (fieldName, value) => {
    setUserInputValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    setTotalVal(UserInputTotalSum(userInputValues, keysToRemoveInUserInput));
  }, [userInputValues]);

  const updatedAssignData = (data, keys) => {
    let totalAssignData = {};
    let assignWeights = {};
    let assignPenetrationTarget = {};
    Object.keys(data).forEach((ele, i) => {
      let element = {
        [ele]: ele === "chooseLevel" ? data[ele] : `${Number(data[ele]) / 100}`,
      };

      if (keys.includes(ele)) {
        assignPenetrationTarget = { ...assignPenetrationTarget, ...element };
      } else {
        assignWeights = { ...assignWeights, ...element };
      }
    });
    totalAssignData = {
      ...productFilters,
      assignWeights: { ...assignWeights },
      minimumPenetrationForSku:
        assignPenetrationTarget["minimumPenetrationForSku"],
      chooseLevel: assignPenetrationTarget["chooseLevel"],
    };
    return totalAssignData;
  };

  const UserInputTotalSum = (
    data: Record<string, unknown>,
    keysToRemove: string[]
  ) => {
    if (!data || typeof data !== "object") {
      throw new Error("Input data must be a valid object.");
    }

    if (!keysToRemove || !Array.isArray(keysToRemove)) {
      throw new Error("keysToRemove must be a valid array of keys to remove.");
    }

    const filteredData: Record<string, unknown> = {};
    let sum = 0;
    Object.entries(data).forEach(([key, value]) => {
      if (!keysToRemove.includes(key)) {
        const stringValue = String(value);
        const parsedValue = parseFloat(stringValue);

        if (!isNaN(parsedValue)) {
          sum += parsedValue;
        } else {
          filteredData[key] = value;
        }
      }
    });

    return { filteredData, sum };
  };

  useEffect(() => {
    const {
      netRevenueIncremental,
      netIncrementalNopbt,
      nopbtIncremental,
      salesGrowth,
      marketGrowth,
      marketSize,
      inventoryTurn,
      capacityConstraint,
      operativeComplexity,
    } = allWeights;
    const { minimumPenetrationForSku, chooseLevel } = penetrationLimit;

    setInputValues({
      netRevenueIncremental,
      netIncrementalNopbt,
      nopbtIncremental,
      salesGrowth,
      marketGrowth,
      marketSize,
      inventoryTurn,
    });

    setInputConstraintsValues({
      capacityConstraint,
      operativeComplexity,
    });

    setInputPentrationLimitValues({
      minimumPenetrationForSku,
      chooseLevel,
    });

    setUserInputValues({ ...allWeights, ...penetrationLimit });
  }, [isViewEdit, JSON.stringify(allWeights), defaultProductFilters]);


  return (
    <Grid sx={{ marginTop: "1rem" }}>
      <StyledCard>
        {hasNullOrBlankValue(defaultProductFilters) ? (
          <Grid
            style={{ padding: "10px", marginTop: "2px" }}
            container
            spacing={2}
          >
            <Skeleton
              variant="rectangular"
              height={145}
              width="100%"
              style={{ padding: "10px" }}
            />
          </Grid>
        ) : (
          <Grid style={{ position: "relative" }}>
            <AssignWeight
              onInputChange={handleInputChange}
              inputValues={inputValues}
              setInputValues={setInputValues}
            />
            <Constraints
              onInputChange={handleInputChange}
              inputValues={inputConstraintsValues}
              setInputValues={setInputConstraintsValues}
            />
            <UserInputNotes val={totalVal} />
            <PenetrationLimit
              onInputChange={handleInputChange}
              inputValues={inputPentrationLimitValues}
              setInputValues={setInputPentrationLimitValues}
            />
            <Typography style={{ position: "absolute", bottom: 2, right: 15 }}>
              <span style={{ fontSize: 10, fontWeight: 700, color: "black" }}>
                *NOTE:{" "}
              </span>
              <span style={{ fontSize: 10, fontWeight: 400, color: "black" }}>
                Use Country level for Rationalization Recommendation Only.
              </span>
            </Typography>
          </Grid>
        )}
      </StyledCard>
      <RunSimulation
        data={updatedAssignData(userInputValues, keysToRemoveInUserInput)}
        val={totalVal}
        isViewEdit={isViewEdit}
      />
    </Grid>
  );
};

export default UserInputs;
