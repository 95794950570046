import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import mockData from "../../../../mocks/assortmentMocks";
import AssortmentFiltersAccordian from "../../../../components/AssortmentFiltersAccordian/AssortmentFiltersAccordian";
import Indicator from "../../../../components/Loader/Loader";
import { fetchApi } from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import OverallFilters from "../FilterComponents/OverallFilters";
import { BlackFilledInfo } from "../../../../assets/icons/assorticons";
import DataSourcePopup from "../CommonComponents/DataSourcePopup";
import { assortmentDataSource } from "../CommonComponents/utils";
import { API_URL } from "../../../../store/actions/constants";

const AssortmentOverallFilters: React.FC<{
  onSelectFilters;
  isViewEdit;
}> = ({
  onSelectFilters,
  isViewEdit,
}) => {

  const dispatch = useDispatch();
  const [selectedProductFilters, setSelectedProductFilters] = useState({
    country: null,
    businessUnit: null,
    dateTimePeriod: null,
  });

  const [accordianTimePeriod, setAccordianTimePeriod] = useState("");

  const handleProductFiltersChange = (selectedFilters) => {
    setSelectedProductFilters(selectedFilters);

    onSelectFilters(selectedFilters);
  };

  const { loaderForProductFilters, defaultProductFilters } = useSelector(
    (state: any) => state.AssortmentFiltersReducer
  );

  const getAccordianTitle = (res) => {
    return `${res.data.data}`;
  };

  useEffect(() => {
    const { dateTimePeriod, country } = defaultProductFilters;
    const payload = {
      country: country,
      dateTimePeriod: dateTimePeriod,
    };
    if (country !== "" && dateTimePeriod !== "") {
      dispatch(
        fetchApi(payload, API_URL.ASSORTMENT.DATE_TIME_PERIOD_DATA, null)
      ).then((res) => {
        if (res["status"] === 200) {
          setAccordianTimePeriod(getAccordianTitle(res));
        } else {
          console.log("error", res);
        }
      });
    }
  }, [defaultProductFilters.dateTimePeriod]);

  const [open, setOpen] = useState(false);
  const openDataSourceInfo = () => {
    setOpen((isOpen) => !isOpen);
  };
  return (
    <Card
      className="m-b-20"
      style={{
        marginTop: "3%",
        position: "relative",
        backgroundColor: "#F1F5FE",
      }}
    >
      <Indicator position="absolute" show={loaderForProductFilters} />
      {open && (
        <DataSourcePopup
          isOpen={open}
          setIsOpen={setOpen}
          data={assortmentDataSource}
        ></DataSourcePopup>
      )}
      <CardContent>
        <Grid
          style={{
            position: "absolute",
            fontFamily: "Mulish",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "17.57px",
            letterSpacing: "0.4px",
            right: "6px",
            zIndex: 10,
            color: "#7E7E7E",
          }}
        >
          Data Sources{" "}
          <img
            style={{ cursor: "pointer", marginRight: "5px" }}
            onClick={openDataSourceInfo}
            src={BlackFilledInfo}
            alt=""
          />
        </Grid>

        <AssortmentFiltersAccordian
          defaultOpen={true}
          title="Overall-Filters:"
          subTitle={accordianTimePeriod}
        >
          <div style={{ marginTop: "1rem" }}>
            <OverallFilters
              data={{ ...mockData.productFilterV2Config }}
              onSelectFilters={handleProductFiltersChange}
              isViewEdit={isViewEdit}
            />
          </div>
        </AssortmentFiltersAccordian>
      </CardContent>
    </Card>
  );
};

export default AssortmentOverallFilters;
