import { TextField } from '@mui/material';
import styled from 'styled-components';

export const CustomInputAdornment = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: flex-end
`;

export const CustomUpArrow = styled.div`
  font-size: 12px;
  line-height: 1;
  padding: 2px; 
  color: #305597;
  background-color: #DFDFDF;
  border: 1px solid #bdbdbd; 
  border-radius: 4px; 
  transition: background-color 0.3s ease; 

  &:hover {
    background-color: #c0c0c0;
  }
`;

export const CustomDownArrow = styled.div`
  font-size: 12px;
  line-height: 1;
  padding: 2px; 
  color: #305597;
  background-color: #DFDFDF;
  border: 1px solid #bdbdbd; 
  border-radius: 4px; 
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c0c0c0; /* Change background color on hover */
  }
`;

export const StyledTextField = styled(TextField)`
  & input {
    box-sizing: border-box;
  }

  & .MuiOutlinedInput-root {
    padding-right: 0; 
    width: 100px;
    height: 35px;
  }
  
  & .MuiInputAdornment-positionEnd {
    margin-right: 0;
  }
`;

