import React, { useEffect } from "react";
import { Grid, Skeleton, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { RedBtn } from "../../../AssortmentTool/components/AssortmentOverallFilters/AssortmentOverallFilters.Styled";
import {
  dispatchState,
  fetchApi,
  portfolioSummary,
  portfolioSummaryLoader,
} from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import { toast } from "../../../../store/actions/toast.action";
import {
  fetchExecutionPortfolioTableFiltersRequest,
  getPortfolioTableFilterData,
  selectedTableFilters,
} from "../../../../store/actions/ExecutionTool/portfolioGeoLevelFilter.action";
import DynamicFilter from "../CommonComponents/DynamicFilter";
import { BlueBtn } from "./portfolioTableStyled";
import { API_URL } from "../../../../store/actions/constants";

const PortfolioLevelFilters: React.FC<{}> = ({}) => {
  const { portfolioFilterData } = useSelector(
    (state: any) => state.portfolioGeoLevelFilter
  );
  const selectedFilters = useSelector(
    (state: any) => state.allFilter.selectedFilters
  );

  const selectedTableLevelFilters = useSelector(
    (state: any) => state.portfolioGeoLevelFilter.selectedFilters
  );

  const dispatch = useDispatch();
  const currentAverage = useSelector(
    (state: any) => state.executionUserInputs.currentAverage
  );
  const overallFilters = useSelector(
    (state: any) => state.allFilter.selectedFilters
  );
  const executionUserInputs = useSelector(
    (state: any) => state.executionUserInputs
  );
  const { selectedLevelForPortfolio, portfolioSummaryData } = useSelector(
    (state: any) => state.portfolioGeoLevelReducer
  );

  useEffect(() => {
    if (selectedFilters.storeSegment.length !== 0) {
      handlePortfolioLevelFilters();
    }
  }, [currentAverage]);

  const updateSelectedTableFilters = (filters) => {
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        const filterData = filters[key].options;
        const values = filterData
          .filter((option) => !option.checked)
          .map((option) => option.value);
        let obj = {
          name: key,
          value: values,
        };
        dispatch(
          selectedTableFilters({
            name: key,
            value: values,
          })
        );
      }
    }
  };

  const handlePortfolioLevelFilters = (key?, filterData?) => {
    dispatch(
      selectedTableFilters({
        name: key,
        value: filterData,
      })
    );
    dynamicApiFilter(key, filterData);
  };

  const filterMapper = {
    channel: "Channel",
    region: "Region",
    storeSegment: "StoreSegment",
  };

  const dynamicApiFilter = (key, filterData, isReset?) => {
    let payload = {};
    let previousSelection = {
      Channel: [],
      Region: [],
      StoreSegment: [],
    };
    if (isReset) {
      previousSelection = {
        ...previousSelection,
      };
    } else {
      previousSelection = {
        ...previousSelection,
        Channel: selectedTableLevelFilters.channel,
        Region: selectedTableLevelFilters.region,
        StoreSegment: selectedTableLevelFilters.storeSegment,
      };
    }
    payload = {
      availableDynamicFilters: ["Channel", "Region", "StoreSegment"],
      availableDynamicFiltersSelectedValues: {
        ...previousSelection,
        [filterMapper[key]]: filterData,
      },
      overallFiltersSelectedValues: {
        Country: selectedFilters.country,
        Period: selectedFilters.period,
        BusinessUnit: selectedFilters.businessUnit,
        channel: selectedFilters.channel,
        region: selectedFilters.region,
        storeSegment: selectedFilters.storeSegment,
      },
      toolName: "Execution",
      pageName: "Execution",
    };

    let dispatchAction: dispatchState = {
      loaderStateFetch: fetchExecutionPortfolioTableFiltersRequest(),
    };
    let geoFilterUrl = API_URL.EXECUTION.TRACKER_GEO_TABLE_FILTER;

    dispatch(fetchApi(payload, geoFilterUrl, dispatchAction))
      .then((res) => {
        updateSelectedTableFilters(res.data);
        dispatch(getPortfolioTableFilterData(res.data));
      })
      .catch((error) => {
        dispatch(toast("Something Went Wrong!", true, 2000, "error"));
        console.log("ERROR", error);
      });
  };

  const applyFilters = (isReset?:Boolean) => {
    const payloadForPortfolioLevel = {
      overallFilters: {
        period: overallFilters.period,
        businessUnit: overallFilters.businessUnit,
        country: [overallFilters.country[0].toUpperCase()],
      },
      geo: {
        channel: overallFilters.channel,
        region: overallFilters.region,
        storeSegment: overallFilters.storeSegment,
        store: overallFilters.store,
      },
      portfolioGeo: {
        channel: isReset ? [] : selectedTableLevelFilters.channel,
        region: isReset ? [] : selectedTableLevelFilters.region,
        storeSegment: isReset ? [] : selectedTableLevelFilters.storeSegment,
      },
      userInputFilter: {
        decisionVariable: {
          assortmentScenarioName:
            executionUserInputs.selectedData.assortment_scenario,
          mixScenarioName: executionUserInputs.selectedData.mix_scenario,
        },
        assortmentsTargets: {
          goodToHaveSkus: [executionUserInputs.weights.goodToHaveSku],
          mustHaveSKUs: [executionUserInputs.weights.mustHaveSku],
        },
      },
      level: selectedLevelForPortfolio,
    };

    const dispatchActions: dispatchState = {
      loaderStateFetch: portfolioSummaryLoader(true),
    };

    dispatch(
      fetchApi(
        payloadForPortfolioLevel,
        API_URL.EXECUTION.GET_PORTFOLIO_DATA_AFTER_EXECUTION,
        dispatchActions
      )
    )
      .then((res: any) => {
        dispatch(portfolioSummaryLoader(false));
        dispatch(portfolioSummary(res?.data?.portfolioData));
      })
      .catch((error) => {
        dispatch(portfolioSummaryLoader(false));
        console.log("ERROR", error);
      });
  };

  const isEmptyObject = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };

  const resetFilters = () => {
    dynamicApiFilter(null, null, true);
    applyFilters(true);
  };

  return (
    <>
      {portfolioSummaryData.length !== 0 &&
      !isEmptyObject(portfolioFilterData) ? (
        <>
          <Grid
            style={{ display: "flex",marginLeft:"5px", whiteSpace: "nowrap", marginTop: "15px" }}
          >
            <DynamicFilter
              filtersConfig={portfolioFilterData}
              onChangeFilter={handlePortfolioLevelFilters}
            />
            <Grid container justifyContent="flex-end" className="p-l-16">
              <BlueBtn className="m-r-20" onClick={()=>applyFilters(false)}>
                Apply Filter
              </BlueBtn>
              <RedBtn className="m-r-20" onClick={resetFilters}>
                Reset Filter
              </RedBtn>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {["Channel", "Region", "StoreSegment"].map((ele, index) => (
            <Grid
              style={{
                marginTop: "15px",
                display: "flex",
                marginRight: "10px",
                whiteSpace: "nowrap",
              }}
              key={index}
            >
              <Typography style={{ marginRight: "10px", marginTop: "5px" }}>
                {ele}
              </Typography>
              <Skeleton
                variant="rectangular"
                width={150}
                height={35}
                style={{ marginRight: "10x" }}
              />
            </Grid>
          ))}
          <Skeleton
            variant="rectangular"
            width={100}
            height={35}
            style={{
              marginRight: "10x",
              marginTop: "15px",
              marginLeft: "10px",
            }}
          />
        </>
      )}
    </>
  );
};

export default PortfolioLevelFilters;
