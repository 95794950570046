export const PriceElasticityConfig = (data) => ({
    chart: {
        type: 'bubble',
        plotBorderWidth: 1,
        zoomType: 'xy',
        backgroundColor: ''
    },
    credits: {
        enabled: false
    },
    legend: {
        enabled: false
    },
    exporting: {
        enabled: false
    },
    title: {
        text: ''
    },
    xAxis: {
        min : 0,
        max:3,
        step:0.155,
        gridLineWidth: 1,
        title: {
            text: data.length ? 'Price Elasticity' : '',
            style: {
                color: '#000000',
                fontSize: '1.5em'
            },
            margin: 50
        },
        labels: {
            format: '{value}',
            style: {
                color: '#000000',
                fontSize: '1.3em'
            }
        },
        plotLines: [{
            color: 'black',
            dashStyle: 'dot',
            width: 2,
            value: 1,
            label: {
                rotation: 0,
                y: 15,
                style: {
                    fontStyle: 'italic'
                },
                text: ''
            },
            zIndex: 3
        }]
    },
    yAxis: {
        min: -75,
        max:75,
        tickAmount: 10,
        title: {
            text: data.length ? 'Market Share - Fair Share (%)' : '',
            style: {
                color: '#000000',
                fontSize: '1.5em'
            },
            margin: 50,
        },
        labels: {
            format: '{value}',
            style: {
                color: '#000000',
                fontSize: '1.3em'
            }
        },
        maxPadding: 0.2,
        plotLines: [{
            color: 'black',
            dashStyle: 'dot',
            width: 2,
            value: 0,
            label: {
                align: 'right',
                style: {
                    fontStyle: ''
                },
                text: '',
                x: -10
            },
            zIndex: 3
        }]
    },
    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                format: '{point.z}%'
            },
            tooltip: {
                useHTML: true,
                headerFormat: "",
                pointFormatter: function () {
                  return (
                    `<span style="color: ${this.color}">●</span> <h3>${this.name}<h3/> <br/><br/>Current Mix : <b> ${this.z}%<b/>`
                  );
                },
                followPointer: true,
              },
        },
    },
    series: [{
        data,
        colorByPoint: true
    }],
    lang : {
        noData : 'No Data'
    }
})