import React, { useEffect, useState } from "react";
import {
  FilterLabel,
  StyledOption,
  StyledSelect,
} from "../ExecutionCommon.Styled";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExecutionFiltersData,
  selectedFilters,
  setLastTriggeredKey,
  setTimePeriod,
} from "../../../../store/actions/allFilter.action";
import {
  fetchExecutionInputDataFiltersData,
  setExecutionUserDataInitialState,
  setSelectedExecutionData,
} from "../../../../store/actions/ExecutionTool/executionUserInput.action";
import { makeStyles } from "@material-ui/core";
import { MenuProps } from "@mui/material/Menu";
import { fetchExecutionCurrentAvgData } from "../../../../store/actions/ExecutionTool/executionUserInput.action";
import { resetExecutionOverviewState } from "../../../../store/actions/ExecutionTool/executionOverview.action";
import {
  fetchApi,
  resetPortfolioSummaryState,
} from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import { clearGeoPortfolioLevelSelectedFilters } from "../../../../store/actions/ExecutionTool/portfolioGeoLevelFilter.action";
import { API_URL } from "../../../../store/actions/constants";

interface selectAllType {
  channel: boolean;
  region: boolean;
  storeSegment: boolean;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const menuProps: Partial<MenuProps> = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const useStyles = makeStyles(() => ({
  select: {
    backgroundColor: "none",
  },
  searchInput: {
    "& .MuiOutlinedInput-root": {
      "& .Mui-focused fieldset": {
        border: "2px solid #0000003b",
      },
    },
  },
  searchMenuItem: {
    backgroundColor: "#fff !important",
  },
}));

const SelectInput = ({ options, name, isMultiple, isFilter }) => {
  const classes = useStyles();
  const filters = useSelector((state: any) => state.allFilter.selectedFilters);
  const { selectedData } = useSelector(
    (state: any) => state.executionUserInputs
  );
  const [searchText, setSearchText] = useState("");
  const [dropdownData, setDropdownData] = useState(options);
  const previouslyselectedFilters = useSelector(
    (state: any) => state.allFilter.previouslyselectedFilters
  );
  const userInput = useSelector(
    (state: any) => state.executionUserInputs.selectedData
  );
  const dispatch = useDispatch();
  const [isVisible, setVisible] = useState(true);
  const [isAllSelected, setAllSelected] = useState({
    channel: false,
    region: false,
    storeSegment: false,
  });
  useEffect(() => {
    setDropdownData(options);
    if (options.length == 0) {
      setAllSelected((prev) => {
        return {
          ...prev,
          [name]: false,
        };
      });
    }
  }, [options]);

  const callFilterApiOnChange = (data: any) => {
    const checkValuesToSendInPayload = (lastTriggeredkey, givenKey) => {
      let keys = [
        "country",
        "period",
        "businessUnit",
        "channel",
        "region",
        "storeSegment",
      ];
      let availableKeys = splitArray(keys, lastTriggeredkey);
      if (availableKeys.includes(givenKey)) {
        return true;
      }
      return false;
    };

    if (name !== "storeSegment" || data["storeSegment"].length == 0) {
      let payload = {
        default: filters["country"].length > 0 ? false : true,
        overallFilters: {
          country: checkValuesToSendInPayload(name, "country")
            ? data.country
            : [],
          period: checkValuesToSendInPayload(name, "period")
            ? data.period[0]
            : "",
          businessUnit: checkValuesToSendInPayload(name, "businessUnit")
            ? data.businessUnit
            : [],
        },
        geo: {
          channel: checkValuesToSendInPayload(name, "channel")
            ? data.channel
            : [],
          region: checkValuesToSendInPayload(name, "region") ? data.region : [],
          storeSegment: [],
        },
      };
      if (checkFilterChanged(previouslyselectedFilters[name], filters[name])) {
        dispatch(setLastTriggeredKey(name));
        dispatch(
          fetchExecutionFiltersData(
            payload,
            API_URL.EXECUTION.EXECUTION_TRACKER_FILTER
          )
        );
        dispatch(clearGeoPortfolioLevelSelectedFilters());
        if (name === "period") {
          const timePeriodPayload = {
            country: data.country[0],
            dateTimePeriod: data.period[0],
          };
          dispatch(
            fetchApi(
              timePeriodPayload,
              "output/executionTracker/getDateTimePeriodDataExecution",
              null
            )
          ).then((res) => {
            if (res) {
              dispatch(setTimePeriod(res["data"]["data"]));
            } else {
              console.log("error", res);
            }
          });
        }
        resetData();
      }
    } else {
      const userInputsPayload = {
        default: true,
        overallFilters: {
          country: data.country,
          period: data.period[0] ? data.period[0] : "",
          businessUnit: data.businessUnit,
        },
        geo: {
          channel: data.channel,
          region: data.region,
          storeSegment: data.storeSegment,
        },
      };
      dispatch(
        fetchExecutionInputDataFiltersData(
          userInputsPayload,
          API_URL.EXECUTION.EXECUTION_TRACKER_USER_FILTER
        )
      );
      dispatch(clearGeoPortfolioLevelSelectedFilters());
    }
  };

  const resetData = () => {
    dispatch(setExecutionUserDataInitialState());
    dispatch(resetExecutionOverviewState());
    dispatch(resetPortfolioSummaryState());
  };

  function splitArray(allKeys, lastTriggeredKey) {
    const index = allKeys.findIndex((item) => item === lastTriggeredKey);

    if (index !== -1) {
      const unionKeys = allKeys.slice(0, index + 1);
      return unionKeys;
    }

    // If the element is not found, return the original array
    return allKeys;
  }
  const handleSingleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    console.log("checkFilters", filters, name);
    if (
      event.target.name == "assortment_scenario" ||
      event.target.name == "mix_scenario"
    ) {
      dispatch(setSelectedExecutionData(event.target));
      if (event.target.name == "mix_scenario") {
        const userInputsPayload = {
          overallFilters: {
            country: filters.country,
            period: filters.period,
            businessUnit: filters.businessUnit,
          },
          geo: {
            channel: filters.channel,
            region: filters.region,
            storeSegment: filters.storeSegment,
          },
          userInputFilter: {
            decisionVariable: {
              assortmentScenarioName: selectedData.assortment_scenario,
              mixScenarioName: [`${event.target.value}`],
            },
          },
        };
        dispatch(
          fetchExecutionCurrentAvgData(
            userInputsPayload,
            API_URL.EXECUTION.CURRENT_AVERAGES
          )
        );
        console.log("payload atfer scenarip", userInputsPayload);
      }
    } else {
      if (filters[name][0] === value) {
        dispatch(selectedFilters({ name: name, value: [] }));
        filters[name] = [];
      } else {
        dispatch(selectedFilters(event.target));
        filters[name] = [value];
      }
      resetData();
    }
  };
  const handleUserInputClose = (event) => {};

  const checkFilterChanged = (existingValue, updatedValue) => {
    if (existingValue.length !== updatedValue.length) {
      return true;
    }

    let isEqual = false;

    // Check each element of the arrays using forEach
    existingValue.forEach((element, index) => {
      if (element !== updatedValue[index]) {
        isEqual = true;
      }
    });

    return isEqual;
  };
  const onSearch = (e) => {
    const val = e.target.value;

    if (val) {
      setSearchText(val);
      setVisible(false);
      setDropdownData(
        dropdownData.filter((item) =>
          typeof item === "string"
            ? item.toLowerCase().includes(val.toLowerCase())
            : String(item).includes(val)
        )
      );
    } else {
      setVisible(true);
      setSearchText("");
      setDropdownData(options);
    }
  };
  const handleClose = (event) => {
    setSearchText("");
    setVisible(true);
    callFilterApiOnChange(filters);
  };
  const handleSelectAll = () => {
    setAllSelected((prev) => {
      if (!prev[name]) {
        dispatch(selectedFilters({ name: name, value: dropdownData }));
        filters[name] = dropdownData;
      } else {
        dispatch(selectedFilters({ name: name, value: [] }));
        filters[name] = [];
      }
      return {
        ...prev,
        [name]: !prev[name],
      };
    });
  };
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    if (
      typeof value === "string" ||
      (typeof value !== "string" && !value.includes("Select All"))
    ) {
      dispatch(selectedFilters(event.target));
      filters[name] = typeof value === "string" ? value.split(",") : value;

      setAllSelected((prev) => {
        return {
          ...prev,
          [name]: !checkFilterChanged(options, value),
        };
      });
    } else {
      // setAllSelected((prev) => {
      //     return {
      //         ...prev,
      //         [name]: !checkFilterChanged(options, value.filter(element=>element!="Select All"))
      //     };
      // });
      // setAllSelected((prev) => {
      //     if(!prev[name]){
      //         dispatch(selectedFilters({name:name,value:dropdownData}))
      //         filters[name] = dropdownData;
      //     }else{
      //         dispatch(selectedFilters({name:name,value:[]}))
      //         filters[name] = [];
      //     }
      //     return {
      //         ...prev,
      //         [name]: !prev[name]
      //     };
      // });
    }
    console.log("handleChange", filters);
    console.log("handleChange", isAllSelected[name]);
  };
  return isFilter ? (
    isMultiple ? (
      <div>
        <FormControl sx={{ m: 0, width: 200, height: 30 }} size="small">
          <Select
            labelId="select-label"
            id="select"
            multiple
            name={name}
            value={filters[name]}
            renderValue={(selected) => selected.join(", ")}
            onChange={handleChange}
            onClose={handleClose}
            disabled={options.length > 0 ? false : true}
            MenuProps={menuProps}
            sx={{
              "&::-webkit-scrollbar": {
                width: 12, // Adjust the width of the scrollbar as needed
              },
              "&.MuiInputBase-root": {
                fontSize: "12px",
              },
              "&..MuiSelect-root": {
                fontSize: "12px",
              },
              lineHeight: "1.6em",
            }}
            className={classes.select}
          >
            <MenuItem
              sx={{
                background: "#fff",
                "&:hover": {
                  backgroundColor: "#fff",
                },
              }}
              onKeyDown={(e) => e.stopPropagation()}
            >
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                onChange={onSearch}
                sx={{ width: "100%" }}
                type="text"
                autoComplete="off"
                placeholder="Search"
                value={searchText}
                onClick={(e) => e.stopPropagation()}
              />
            </MenuItem>
            {isVisible ? (
              <MenuItem
                key={"selectAll" + name}
                value={"Select All"}
                onClick={handleSelectAll}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox checked={isAllSelected[name]} />
                  <ListItemText primary="Select All" />
                </div>
              </MenuItem>
            ) : (
              <></>
            )}
            {dropdownData.map((optionItem: any) => (
              <MenuItem key={optionItem} value={optionItem}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    checked={
                      filters[name].indexOf(optionItem) > -1 ||
                      isAllSelected[name]
                    }
                  />
                  <ListItemText primary={optionItem} />
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    ) : (
      <div>
        <FormControl sx={{ m: 0, width: 200, height: 30 }} size="small">
          <Select
            labelId="select-label"
            id="select"
            renderValue={(selected) => selected}
            name={name}
            value={filters[name]}
            disabled={options.length > 0 ? false : true}
            onChange={handleSingleChange}
            onClose={handleClose}
            sx={{
              "&.MuiInputBase-root": {
                fontSize: "12px",
              },
              "&..MuiSelect-root": {
                fontSize: "12px",
              },
              lineHeight: "1.6em",
            }}
          >
            <MenuItem
              sx={{
                background: "#fff",
                "&:hover": {
                  backgroundColor: "#fff",
                },
              }}
              onKeyDown={(e) => e.stopPropagation()}
            >
              <TextField
                id="outlined-basic"
                InputProps={{ autoComplete: "off" }}
                variant="outlined"
                onChange={onSearch}
                type="text"
                autoComplete="off"
                placeholder="Search"
                value={searchText}
                style={{ width: "100%" }}
                onClick={(e) => e.stopPropagation()}
              />
            </MenuItem>
            {dropdownData.map((optionItem) => (
              <MenuItem key={optionItem} value={optionItem}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <Checkbox checked={filters[name].indexOf(optionItem) > -1} /> */}
                  <ListItemText primary={optionItem} />
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    )
  ) : (
    <div>
      <FormControl sx={{ m: 0, width: 200, height: 30 }} size="small">
        <Select
          labelId="select-label"
          id="select"
          renderValue={(selected) => selected}
          name={name}
          value={userInput[name]}
          disabled={options.length > 0 ? false : true}
          onChange={handleSingleChange}
          onClose={handleUserInputClose}
          sx={{
            "&.MuiInputBase-root": {
              fontSize: "12px",
            },
            "&..MuiSelect-root": {
              fontSize: "12px",
            },
            lineHeight: "1.6em",
          }}
        >
          <MenuItem
            sx={{
              background: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              onChange={onSearch}
              type="text"
              autoComplete="off"
              placeholder="Search"
              value={searchText}
              onClick={(e) => e.stopPropagation()}
            />
          </MenuItem>
          {dropdownData.map((optionItem) => (
            <MenuItem key={optionItem} value={optionItem}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <Checkbox checked={userInput[name].indexOf(optionItem) > -1} /> */}
                <ListItemText primary={optionItem} />
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectInput;
