import React from "react";
import OutputTable from "../OutputTable/OutputTable";
import { tableStyles } from "./PortFolio.Styled";
import {
  filterableColumns,
  levelsForPortFolio,
  portfolioData,
  sortableColumns,
  tableColumns,
} from "./PortfolioTableConfig";
import { Card, Grid, Skeleton, Typography } from "@mui/material";
import SelectInput from "../CommonComponents/SelectInput";
import { useSelector } from "react-redux";
import Indicator from "../../../../components/Loader/Loader";

const PortFolio = () => {
  const classes = tableStyles;
  const { portfolioTableLoader, portfolioTableData, portfolioLevel } =
    useSelector((state: any) => state.MixPortfolioReducer);

  const filterColumnsByPortfolioLevel = (data, portfolioLevel) => {
    const columnsToRemove = {
      segment: ["brand", "sku"],
      brand: ["sku"],
      sku: [],
    }[portfolioLevel];

    return data.filter((column) => !columnsToRemove.includes(column.field));
  };
  const updatedColumns = filterColumnsByPortfolioLevel(
    tableColumns,
    portfolioLevel
  );
  return (
    <Grid style={{ marginTop: "10px" }} container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography color={"white"} variant="h6">
          Portfolio
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{ whiteSpace: "nowrap", display: "flex", justifyContent: "end" }}
      >
        <Typography style={{ marginTop: "7px", marginRight: "8px", color:"white" }}>
          Level:
        </Typography>
        <SelectInput isMultiple={false} levels={levelsForPortFolio} />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card
          sx={{
            borderRadius: "5px",
            position: "relative",
            backgroundColor: "#F1F5FE",
          }}
        >
          <Indicator position="absolute" show={portfolioTableLoader} />
          {portfolioTableData === null ||
          (portfolioTableData && portfolioTableData.length === 0) ? (
            <Grid
              style={{ marginTop: "15px" }}
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Skeleton variant="rectangular" width="100%" height={145} />
              <Typography
                sx={{
                  zIndex: 1,
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                No Rows
              </Typography>
            </Grid>
          ) : (
            <Grid style={{ padding: "1%" }}>
              <OutputTable
                rowData={portfolioTableData}
                tableColumns={updatedColumns}
                filterableColumns={filterableColumns}
                sortableColumns={sortableColumns}
              />
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default PortFolio;
