import { Button, Grid } from "@mui/material";
import React from "react";
import CancelIcon from '@mui/icons-material/Cancel';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.log("error", error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
        <Grid display="flex" justifyContent="center" alignItems="center" style={{width: '100vw', height: '100vh'}}>
          <Grid display="flex" spacing={3}>
          <Grid item className="m-r-20">
            <CancelIcon style={{fontSize: 40, color: "red"}}/>
          </Grid>
          <Grid item>
              <h1><b>Unexpected Error</b></h1>
              <h3><p>An error has occurred</p></h3>
              <Grid display="flex" justifyContent="flex-end" className="m-t-20"> 
                <Button variant="contained" onClick={()=> window.location.reload()}>Reload</Button>
              </Grid>
          </Grid>
          </Grid>
        </Grid>
        )
      }
      return <>{this.props.children}</>
    }
  }