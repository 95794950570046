import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import {
  BubbleChartConfig,
  colors,
  getRandomHexColor,
} from "./BubbleChartConfig";
import {
  Container,
  ChartContainer,
  SegmentContainer,
  SectionTitle,
} from "./BubbleChart.Styled";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Indicator from "../../../../components/Loader/Loader";
import { DownloadIcon } from "../../../../assets/icons/assorticons";
import { LightBlueBtn } from "../../../../styles/Common.Styled";
import { columnsForPortfolioToDownload } from "../PortFolio/PortfolioTableConfig";
import { columnsForGeoLevelToDownload } from "../GeoLevel/GeoLevelTableConfig";
import { setSelectedBubbleChartOptionsMixSimulator } from "../../../../store/actions/MixSimulationTool/mixSimulatorFilter.action";

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    minWidth: 150,
    maxHeight: 33,
    padding: 0,
    borderRadius: 7,
    paddingLeft: 5,
  },
}));

const menuOptions = [
  { value: "portfolio", label: "Portfolio" },
  { value: "geolevel", label: "Geo Level" },
];

const GeoLevelMapper = {
  storeSegment: "storeSegment",
  channel: "subChannel",
  region: "geoLevel",
};

const PortfolioLevelMapper = {
  brand: "brand",
  segment: "segment",
  sku: "sku",
};

const BubbleChart = () => {
  const [segmentColorLabel, setSegmentColorLabel] = useState<{
    [key: string]: string;
  }>({});
  const [selectedOption, setSelectedOption] = useState("portfolio");
  const [bubbleData, setBubbleData] = useState({});
  const chartRef = useRef(null);
  const dispatch = useDispatch()
  const { portfolioTableData, portfolioLevel, portfolioTableLoader } =
    useSelector((state: any) => state.MixPortfolioReducer);
  const { geoLevelTableData, geoLevel, geoLevelTableLoader } = useSelector(
    (state: any) => state.MixGeoLevelReducer
  );
  const { selectedGoal } = useSelector(
    (state: any) => state.mixSimulationUserInput
  );
  const classes = useStyles();

  useEffect(() => {
    updateData(selectedOption);
  }, [portfolioTableData, geoLevelTableData]);

  const updateData = (val) => {
    dispatch(setSelectedBubbleChartOptionsMixSimulator(val))
    let data;
    if (val === "portfolio") {
      data =
        portfolioTableData != null
          ? responseToBubbleData(portfolioTableData, val)
          : [];
    } else {
      data =
        geoLevelTableData != null
          ? responseToBubbleData(geoLevelTableData, val)
          : [];
    }
    let colorLabel = generateSegmentColors(data);

    const result = BubbleChartConfig(data, colorLabel, selectedGoal);
    console.log("result", result)
    setSegmentColorLabel(colorLabel);
    setBubbleData(result);
  };

  const getData = (data: string | string[], levelData: Record<string, any>) => {
    if (Array.isArray(data)) {
      let result: any[] = [];
      for (const key of data) {
        const mappedKey = GeoLevelMapper[key] as keyof typeof levelData;

        if (mappedKey && levelData.hasOwnProperty(mappedKey)) {
          result.push(levelData[mappedKey]);
        }
      }
      return result.join(" X ");
    } else if (typeof data === "string") {
      let val = PortfolioLevelMapper[data];
      return levelData.hasOwnProperty(val) ? levelData[val] : null;
    }
    return null;
  };

  const responseToBubbleData = (data, val) => {
    const transformedData = data.map((item, index) => ({
      xAxis: item.mixDelta,
      yAxis: getYAxisValueOfBubbleData(item),
      bubbleSize: item.currentMix,
      segment: val == "portfolio" ? item.segment : item.subChannel,
      idealMix: item.idealMix,
      skuName:
        val == "portfolio"
          ? getData(portfolioLevel, item)
          : getData(geoLevel, item),
    }));
    return transformedData;
  };

  const getYAxisValueOfBubbleData = (data) => {
    console.log(data)
    if (selectedGoal == 'Revenue/KG') {
      return data.revenueSop
    } else if (selectedGoal == 'Profit/KG') {
      return data.noPBTSop
    } else {
      return parseFloat(data.shareSOP)
    }
  }

  const generateSegmentColors = (data) => {
    if (!Array.isArray(data)) {
      return {};
    }

    const segmentColorMapping = {};
    const usedColors = new Set();

    const uniqueSegments = [...new Set(data.map((item) => item.segment))];
    uniqueSegments.forEach((segment, index) => {
      let color;
      if (index < colors.length) {
        color = colors[index];
      } else {
        color = getRandomHexColor();
      }
      segmentColorMapping[segment as string] = color;
      usedColors.add(color);
    });

    return segmentColorMapping;
  };

  const convertToCSV = (data, selectedKeys) => {
    const columns =
      selectedOption === "portfolio"
        ? columnsForPortfolioToDownload
        : columnsForGeoLevelToDownload;
    const header = selectedKeys
      .map(
        (key) =>
          columns.find(
            (column) =>
              column.key === key && data.some((row) => row[key] !== null)
          )?.title
      )
      .filter((title) => title)
      .join(",");
    const rows = data
      .map((row) =>
        selectedKeys.reduce(
          (acc, key) =>
            row.hasOwnProperty(key) && row[key] !== null
              ? acc.concat(row[key])
              : acc,
          []
        )
      )
      .filter((row) => row.length > 0)
      .map((row) => row.join(","));
    return `${header}\n${rows.join("\n")}`;
  };

  const downloadBubbleData = () => {
    const keysArray = (
      selectedOption === "portfolio"
        ? columnsForPortfolioToDownload
        : columnsForGeoLevelToDownload
    ).map((column) => column.key);
    const data =
      selectedOption === "portfolio" ? portfolioTableData : geoLevelTableData;
    const csvData = convertToCSV(data, keysArray);
    const file = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(file);
    link.download = `${selectedOption}_bubble_chart.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    updateData(value);
  };

  return (
    <Grid style={{ marginTop: "10px" }} container spacing={2}>
      <Grid item xs={12} md={6} style={{}}>
        <Typography color={"white"} variant="h6">
          Bubble Chart
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{ whiteSpace: "nowrap", display: "flex", justifyContent: "end" }}
      >
        <>
          <LightBlueBtn onClick={downloadBubbleData}>
            <img style={{ marginRight: "5px" }} src={DownloadIcon} alt="" />
            Download
          </LightBlueBtn>
        </>
      </Grid>

      <Grid item xs={12} md={12}>
        <Card
          sx={{
            borderRadius: "5px",
            position: "relative",
            backgroundColor: "#F1F5FE",
          }}
        >
          <Indicator
            position="absolute"
            show={geoLevelTableLoader || portfolioTableLoader}
          />

          {!geoLevelTableData ||
            !portfolioTableData ||
            !geoLevelTableData.length ||
            !portfolioTableData.length ? (
            <Grid
              style={{ marginTop: "15px" }}
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Skeleton variant="rectangular" width="100%" height={145} />
              <Typography
                sx={{
                  zIndex: 1,
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                No Data
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid
                container
                alignItems="center"
                marginBottom={2}
                marginTop={5}
                justifyContent={"space-between"}
              >
                <Grid item marginLeft="20px" alignItems="center" >
                  <i><b>*NOTE</b>: To zoom in, use cursor to highlight the section.</i>
                </Grid>
                <Grid item>
                  <Grid container
                    alignItems="center"                    
                    justifyContent={"space-between"}>
                    <Grid item>
                      <Typography
                        variant="h6"
                        style={{
                          fontFamily: "Mulish",
                          fontWeight: 700,
                          fontSize: "12px",
                          marginRight: "10px",
                        }}
                      >
                        Choose Level:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      justifyContent="flex-end"
                      style={{ marginRight: "15px" }}
                    >
                      <Select
                        sx={{
                          backgroundColor:'null !important'
                        }}
                        value={selectedOption}
                        onChange={handleSelectChange}
                        className={classes.selectContainer}
                        fullWidth
                      >
                        {menuOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                options={bubbleData}
                key={`${selectedOption}-${JSON.stringify(bubbleData)}`} // Unique key based on selectedOption and bubbleData
              />
              <div
                style={{
                  width: "90%",
                  margin: "0 auto",
                  display: 'flex',
                }}
              >
                <ChartContainer>
                  {Object.entries(segmentColorLabel).map(([segment, color]) => (
                    <SegmentContainer key={segment}>
                      <div style={{ backgroundColor: color }} />
                      <Typography
                        style={{ marginRight: "10px", whiteSpace: "nowrap" }}
                      >
                        {segment}
                      </Typography>
                    </SegmentContainer>
                  ))}
                </ChartContainer>
              </div>
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default BubbleChart;
