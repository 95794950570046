import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, Skeleton } from "@mui/material";
import FilterAccordion from "../../../../components/Filters/Filters";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { initialState } from "../../../../components/GeoFilters-V2/GeoFilters-V2";
import mockData from "../../../../mocks/mixMocks";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import GeoFiltersV4 from "../../../../components/GeoFilters-V4/GeoFiltersV4";
import AssortmentFiltersAccordian from "../../../../components/AssortmentFiltersAccordian/AssortmentFiltersAccordian";
import ProductFiltersV4 from "../../../../components/ProductFilters-v4/ProductFilters-v4";

import Indicator from "../../../../components/Loader/Loader";
import MixFiltersAccordian from "../../../../components/MixFiltersAccordian/MixFiltersAccordian";
import { geoFilterV2Config } from "../../../../mocks/geoFilters";
import { RedBtn } from "../../../AssortmentTool/components/AssortmentOverallFilters/AssortmentOverallFilters.Styled";
import MixFilterSelectInput from "../MixFilterSelectInput/MixFilterSelectInput";
import {
  FilterGrid,
  FilterLabel,
} from "../../../ExecutionTool/components/ExecutionCommon.Styled";
import DynamicFilter from "../DynamicFilter/DynamicFilter";
import {
  mapperForFilterName,
  mixGeoFilterConfig,
} from "../../../../mocks/mixSiumulation";
import {
  fetchMixSimulationFiltersRequest,
  getGeoFilterData,
  selectedMixSimulationFilters,
} from "../../../../store/actions/MixSimulationTool/mixSimulatorFilter.action";
import {
  dispatchState,
  fetchApi,
} from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import { toast } from "../../../../store/actions/toast.action";
import { getMixDecisionVariables } from "../../../../store/actions/MixSimulationTool/mixsimulationUserInputs.action";
import { fetchMixSummaryData } from "../../../../store/actions/MixSimulationTool/mixSummary.action";
import { fetchMixGeoLevelData } from "../../../../store/actions/MixSimulationTool/mixGeoLevel.action";
import { fetchMixPortfolioData } from "../../../../store/actions/MixSimulationTool/mixPortfolio.action";
import { resetMixConstrainCsvDataOfCapacityConstraintAndOperativeComplexity } from "../../../../store/actions/MixSimulationTool/mixUserConstraints.action";
import { API_URL } from "../../../../store/actions/constants";

const FilterLabelMapper = {
  country: "Country",
  period: "Date Time Period",
  businessUnit: "Business Unit",
  channel: "Channel",
  region: "Region",
  storeSegment: "Store Segment",
  store: "Store",
};
const GEO_FILTER = "geo";
const MixGeoFilters: React.FC<{
  clearFilter;
  showSkeleton?: boolean;
  isOutputScreen?: boolean;
}> = ({ clearFilter, showSkeleton, isOutputScreen }) => {
  const { outputScreen, selectedFilters, geoFilterData, isEditMode } =
    useSelector((state: any) => state.mixSimulationAllFilter);
  const { portfolioLevel } = useSelector(
    (state: any) => state.MixPortfolioReducer
  );
  const { geoLevel } = useSelector((state: any) => state.MixGeoLevelReducer);
  const mixSimulationUserInputs = useSelector(
    (state: any) => state.mixSimulationUserInput
  );
  const loading = useSelector(
    (state: any) => state.mixSimulationAllFilter.loading
  );
  const selectedFilterData = useSelector(
    (state: any) => state.mixSimulationAllFilter.selectedFilters
  );
  const { uid, selectedGoal } = useSelector((state: any) => state.mixSimulationUserInput);

  const dispatch = useDispatch();
  
  // useEffect(()=>{
  //   allOutputApiCall();
  // },[mixSimulationUserInputs])
  
    const allOutputApiCall = (key?,filterData?)=>{
      if (outputScreen) {
        const mapperKey = {
          sub_chnl: "channel",
          geo_level: "region",
          store_segment: "storeSegment",
          level: "level",
        };
        let initialPayload = {
          uid: uid,
          goal:mixSimulationUserInputs.selectedGoal,
          region: selectedFilterData.region,
          storeSegment: selectedFilterData.storeSegment,
        };
        // console.log("allOutputApiCall",key)
        let outputPayload = key!=''?{...initialPayload,[mapperKey[key]]:filterData}:initialPayload;
        const geoLevelPayload = {
          ...outputPayload,
          level: geoLevel.length === 0 ? ["channel"] : geoLevel,
          goal:selectedGoal
        };
        const portfolioPayload = {
          ...outputPayload,
          levels: [portfolioLevel],
          goal:selectedGoal
        };
        dispatch(
          fetchMixSummaryData(
            outputPayload,
            API_URL.MIXSIMULATION.GET_MIX_SUMMARY_DATA
          )
        );
        dispatch(
          fetchMixPortfolioData(
            portfolioPayload,
            API_URL.MIXSIMULATION.GET_PORTFOLIO_DATA_AFTER_RUN_SIMULATION
          )
        );
        dispatch(
          fetchMixGeoLevelData(
            geoLevelPayload,
            API_URL.MIXSIMULATION.GET_GEO_LEVEL_SUMMARY_DATA_AFTER_RUN_SIMULATION
          )
        );
      }
    }
  const handleGeoLevelFilters = (key, filterData) => {
    // console.log(key);
    const previousSelection = {
      level: selectedFilters.level,
      sub_chnl: selectedFilters.channel,
      geo_level: selectedFilters.region,
      store_segment: selectedFilters.storeSegment,
    };

    dispatch(
      selectedMixSimulationFilters({
        name: mapperForFilterName[key],
        value: filterData,
      })
    );
    dynamicApiFilter(key,filterData)
    // console.log(selectedFilters);

  };

  const dynamicApiFilter = (key,filterData)=>{
    let payload = {};
    if (outputScreen) {
      const previousSelection = {
        Level: selectedFilters.level,
        Channel: selectedFilters.channel,
        Region: selectedFilters.region,
        StoreSegment: selectedFilters.storeSegment,
      };
      const mapperKey = {
        sub_chnl: "Channel",
        geo_level: "Region",
        store_segment: "StoreSegment",
        level: "Level",
      };

      // console.log("key",key)
      payload = {
        
        availableDynamicFilters: ["Level", "Channel", "Region", "StoreSegment"],
        availableDynamicFiltersSelectedValues: 
          key!=''?{...previousSelection, [mapperKey[key]]: filterData}:previousSelection
          
        ,
        overallFiltersSelectedValues: {
          Uid: [`${mixSimulationUserInputs.uid.toUpperCase()}`],
          Country: selectedFilters.country,
          TimePeriod: selectedFilters.period,
          BusinessUnit: selectedFilters.businessUnit,
        },
        toolName: "mix_optimizer_output",
        pageName: "mix_optimizer_output",
      };
    } else {
      const previousSelection = {
        level: selectedFilters.level,
        sub_chnl: selectedFilters.channel,
        geo_level: selectedFilters.region,
        store_segment: selectedFilters.storeSegment,
      };
      payload = {
        availableDynamicFilters: [
          "level",
          "sub_chnl",
          "geo_level",
          "store_segment",
        ],
        availableDynamicFiltersSelectedValues: {
          ...previousSelection,
          [key]: filterData,
        },
        overallFiltersSelectedValues: {
          country: selectedFilters.country,
          time_period: selectedFilters.period,
          business_unit: selectedFilters.businessUnit,
          objective_function: [
            mixSimulationUserInputs.selectedGoal
          ],
        },
        toolName: "mix_optimizer",
        pageName: "mix_optimizer",
      };
    }

    let dispatchAction: dispatchState = {
      loaderStateFetch: fetchMixSimulationFiltersRequest(),
    };
    let geoFilterUrl = outputScreen?"filter/mixOptimizerDynamicFilterForOutputScreen":"filter/mixOptimizerDynamicFilter";

    dispatch(
      fetchApi(payload, geoFilterUrl, dispatchAction)
    )
      .then((res) => {
        if(outputScreen){
          const mapperKeyResult = {
            channel: "sub_chnl",
            region: "geo_level",
            storeSegment: "store_segment",
            level: "level",
          };
          const result = Object.keys(res.data).reduce((acc, key) => {
            acc[mapperKeyResult[key]] = res.data[key];
            return acc;
        }, {});
          // console.log("responseGetGeoFilterData",res.data)
          dispatch(getGeoFilterData(result));

        }else{
          dispatch(getGeoFilterData(res.data));
          dispatch(resetMixConstrainCsvDataOfCapacityConstraintAndOperativeComplexity())
        }
        allOutputApiCall(key,filterData);
      })
      .catch((error) => {
        dispatch(toast("Something Went Wrong!", true, 2000, "error"));
        console.log("ERROR", error);
      });
  }

  return (
    <Card
      className="m-b-20"
      style={{
        marginTop: "3%",
        position: "relative",
        backgroundColor: "#F1F5FE",
      }}
    >
      <Indicator position="absolute" show={loading} />
      <CardContent>
        {!showSkeleton ? (
          <>
            <MixFiltersAccordian
              title="Geo-Filters:"
              disabled={Object.keys(geoFilterData).length == 0 ? true : false}
              defaultExpanded={true}
            >
              <DynamicFilter
                filtersConfig={geoFilterData}
                onChangeFilter={handleGeoLevelFilters}
              />
            </MixFiltersAccordian>
            <Grid container justifyContent="flex-end" className="p-l-16">
              {isEditMode?<></>:<RedBtn
                
                className="m-r-20"
                onClick={clearFilter}
              >
                Clear Filter
              </RedBtn>}
            </Grid>
          </>
        ) : (
          <>
            <MixFiltersAccordian showSkeleton>
              <Grid container spacing={2}>
                {Object.keys(geoFilterV2Config).map(() => (
                  <Grid item xs={12} sm={2}>
                    <Skeleton height={22} />
                    <Skeleton variant="rectangular" width={210} height={45} />
                  </Grid>
                ))}
              </Grid>
            </MixFiltersAccordian>
            <Grid className="m-b-20">
              <MixFiltersAccordian showSkeleton>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    key={`measures-dropdown-container-1`}
                  >
                    <DropdownTitle>
                      <Skeleton height={22} />
                    </DropdownTitle>
                  </Grid>
                </Grid>
              </MixFiltersAccordian>

              {outputScreen && (
                <Grid container justifyContent="flex-end" className="p-l-16">
                  <RedBtn className="m-r-20" onClick={clearFilter}>
                    Clear Filter
                  </RedBtn>
                </Grid>
              )}
            </Grid>
            <Grid className="p-l-16" container spacing={2}>
              <Skeleton
                variant="rectangular"
                className="m-r-20"
                width={99}
                height={45}
              />
              <Skeleton
                variant="rectangular"
                className="m-r-20"
                width={110}
                height={45}
              />
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default MixGeoFilters;
