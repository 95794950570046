import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CardContent, Grid, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { SectionTitle, Title } from "./AssignWeight.Styled";
import TextFieldInput from "../../CommonComponents/TextFieldInput/TextFieldInput";
import { CustomSwitch } from "../../../AssortmentTool.Styled";
import { setWalkRate } from "../../../../../store/actions/AssortmentTool/assortmentFilters.action";

const useStyles = makeStyles((theme) => ({
  asterik: { color: "red" },
}));

const AssignWeight = ({ onInputChange, inputValues, setInputValues }) => {
  const classes = useStyles();
  const { isViewEdit, includeWalkRate } = useSelector(
    (state: any) => state.AssortmentFiltersReducer
  );
  const [isWalkRate, setIsWalkRate] = useState(true);
  const dispatch = useDispatch();
  const handleInputChange = (fieldName, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
    onInputChange(fieldName, value);
  };

  useEffect(() => {
    if (isViewEdit) {
      setIsWalkRate(includeWalkRate == "ON" ? false : true);
    }
  }, []);

  const onChangeWalkRateToggle = () => {
    let updatedWalkRate = !isWalkRate;
    setIsWalkRate(updatedWalkRate);
    dispatch(setWalkRate(updatedWalkRate == false ? "ON" : "OFF"));
  };

  return (
    <CardContent sx={{ backgroundColor: "#F1F5FE", color: "#fff" }}>
      <Grid container spacing={3}>
        <Grid
          style={{ display: "flex", justifyContent: "space-between" }}
          item
          xs={12}
        >
          <Title color="black">Assign Weights (in %)</Title>
          <Box display="flex" alignItems="center">
            <Typography style={{ color: "#000", fontSize: "13px" }}>
              Include Walk Rate
            </Typography>
            {includeWalkRate == "OFF" && (
              <CustomSwitch checked={true} onChange={onChangeWalkRateToggle} />
            )}
            {includeWalkRate == "ON" && (
              <CustomSwitch checked={false} onChange={onChangeWalkRateToggle} />
            )}
            <Typography
              style={{ color: "#000", opacity: "0.6", fontSize: "13px" }}
            >
              Don't Include Walk Rate
            </Typography>
          </Box>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={1}>
            <SectionTitle color="black">
              Net Revenue Incremental<span className={classes.asterik}>*</span>
            </SectionTitle>
          </Grid>
          <Grid item xs={2}>
            <TextFieldInput
              defaultValue={inputValues.netRevenueIncremental}
              callback={(value) =>
                handleInputChange("netRevenueIncremental", value)
              }
            />
          </Grid>
          <Grid item xs={1}>
            <SectionTitle color="black">
              Net Incremental Profit<span className={classes.asterik}>*</span>
            </SectionTitle>
          </Grid>
          <Grid item xs={2}>
            <TextFieldInput
              defaultValue={inputValues.netIncrementalNopbt}
              callback={(value) =>
                handleInputChange("netIncrementalNopbt", value)
              }
            />
          </Grid>
          <Grid item xs={1}>
            <SectionTitle color="black">
            Profit %<span className={classes.asterik}>*</span>
            </SectionTitle>
          </Grid>
          <Grid item xs={2}>
            <TextFieldInput
              defaultValue={inputValues.nopbtIncremental}
              callback={(value) => handleInputChange("nopbtIncremental", value)}
            />
          </Grid>
          <Grid item xs={1}>
            <SectionTitle color="black">
              Revenue Growth<span className={classes.asterik}>*</span>
            </SectionTitle>
          </Grid>
          <Grid item xs={2}>
            <TextFieldInput
              defaultValue={inputValues.salesGrowth}
              callback={(value) => handleInputChange("salesGrowth", value)}
            />
          </Grid>
          <Grid item xs={1}>
            <SectionTitle color="black">
              Market Growth<span className={classes.asterik}>*</span>
            </SectionTitle>
          </Grid>
          <Grid item xs={2}>
            <TextFieldInput
              defaultValue={inputValues.marketGrowth}
              callback={(value) => handleInputChange("marketGrowth", value)}
            />
          </Grid>
          <Grid item xs={1}>
            <SectionTitle color="black">
              Market Size<span className={classes.asterik}>*</span>
            </SectionTitle>
          </Grid>
          <Grid item xs={2}>
            <TextFieldInput
              defaultValue={inputValues.marketSize}
              callback={(value) => handleInputChange("marketSize", value)}
            />
          </Grid>
          <Grid item xs={1}>
            <SectionTitle color="black">
              Inventory Turn
              <span className={classes.asterik}>*</span>
            </SectionTitle>
          </Grid>
          <Grid item xs={2}>
            <TextFieldInput
              defaultValue={inputValues.inventoryTurn}
              callback={(value) => handleInputChange("inventoryTurn", value)}
            />
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default AssignWeight;
