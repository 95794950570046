export const CREATE_NOTIFICATION =
  "CREATE_NOTIFICATION";
export const CREATE_NOTIFICATION_SUCCESS =
  "CREATE_NOTIFICATION_SUCCESS";
export const CREATE_NOTIFICATION_FAILURE =
  "CREATE_NOTIFICATION_FAILURE";

export const GET_NOTIFICATION =
  "GET_NOTIFICATION";
export const GET_NOTIFICATION_SUCCESS =
  "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILURE =
  "GET_NOTIFICATION_FAILURE";

export const DISMISS_NOTIFICATION =
  "DISMISS_NOTIFICATION";
export const DISMISS_NOTIFICATION_SUCCESS =
  "DISMISS_NOTIFICATION_SUCCESS";
export const DISMISS_NOTIFICATION_FAILURE =
  "DISMISS_NOTIFICATION_FAILURE";

export const   DELETE_NOTIFICATION =
  "  DELETE_NOTIFICATION";
export const   DELETE_NOTIFICATION_SUCCESS =
  "  DELETE_NOTIFICATION_SUCCESS";
export const   DELETE_NOTIFICATION_FAILURE =
  "  DELETE_NOTIFICATION_FAILURE";