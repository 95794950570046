import React from 'react';
import { Switch, Route, useHistory, BrowserRouter } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import {
  USER_CREATION,
  LOGIN_CALLBACK,
  ASSORTMENT_TOOL,
  EXECUTION_TOOL,
  SCENARIO_LIBRARY,
  MIX_SIMULATION_TOOL,
  MIX_SIMULATION_SCENARIO_LIBRARY
} from './CONSTANTS';
import PrivateRoute from './PrivateRoute';
import Indicator from '../components/Loader';
import AuthError from '../components/AuthError/AuthError';
import AssortmentTool from '../pages/AssortmentTool';
import ExecutionTool from '../pages/ExecutionTool/components/ExecutionTool';
import ScenarioLibrary from '../pages/AssortmentTool/components/ScenarioLibrary/ScenarioLibrary';
import MixSimulationTool from '../pages/MixSimulation/MixSimulation';
import MixScenarioLibrary from '../pages/MixSimulation/component/MixScenarioLibrary/MixScenarioLibrary';
import UserCreation from '../pages/User/UserCreation';

const Routes = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const onAuthResume = async () => {
    history.push('/');
  };

  const routes = () => {
    return (
      <>
        <PrivateRoute exact path={USER_CREATION} component={UserCreation} />
        <PrivateRoute uniqueKey='home' exact path='/' component={ScenarioLibrary} />
        <PrivateRoute uniqueKey='mix_scenarios' exact path={MIX_SIMULATION_SCENARIO_LIBRARY} component={MixScenarioLibrary} />
        <PrivateRoute uniqueKey='assortment_tool' path={ASSORTMENT_TOOL} component={AssortmentTool} />
        <PrivateRoute uniqueKey='execution_tool' path={EXECUTION_TOOL} component={ExecutionTool} />
        <PrivateRoute uniqueKey='scenario_library' path={SCENARIO_LIBRARY} component={ScenarioLibrary} />
        <PrivateRoute uniqueKey='mix_simultion_tool' path={MIX_SIMULATION_TOOL} component={MixSimulationTool} />
      </>
    );
  };

  return (
    <>
      {process.env.REACT_APP_SSO_ENABLE === 'No' ? (
          <Switch>
            <Route
              path={LOGIN_CALLBACK}
              render={(props) => (
                <LoginCallback {...props} errorComponent={AuthError} loadingElement={<Indicator show={true} />} onAuthResume={onAuthResume} />
              )}
            />
            {routes()}
          </Switch>
      ) : (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Switch>{routes()} </Switch>
        </BrowserRouter>
      )}
    </>
  );
};

export default Routes;
