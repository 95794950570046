// import { styled } from "@mui/material/styles";
import styled, { StyledComponent } from 'styled-components';
import { Button, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BlackInputTitle } from "../../../../styles/Common.Styled";

// const Title = styled(Typography)(({ theme }) => ({
//   fontWeight: 600,
//   fontSize: 16,
// }));

// const DropdownTitle = styled(BlackInputTitle)(({ theme }) => ({
//   fontSize: 14,
// }));

// const ExpandIcon = styled(ExpandMoreIcon)(({ theme }) => ({
//   background:
//     theme.palette.mode === "dark" ? theme.palette.common.white : "#F2F8FC",
//   color: theme.palette.common.black,
// }));
interface RedBtnProps {
  theme?: any;
}

const RedBtn: StyledComponent<typeof Button, any, RedBtnProps, never> = styled(Button)<RedBtnProps>(
  ({ theme,disabled }) => ({
    background:'#E81C0E',
    color: 'white',
    height: '32px',
    width: '78px',
    padding: '8px 10px 8px 10px',
    borderRadius: '8px',
    whiteSpace: 'nowrap',
    fontSize: '10px',
    fontFamily: 'Montserrat,sans-serif !important',
    fontWeight: '700',
    lineHeight: '16px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    "&:hover": {
      background:'#E81C0E',
    },
  })
);

export { RedBtn };
// export { Title, DropdownTitle, ExpandIcon, RedBtn };
