import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import { QuadrantPopupTitle } from "./QuadrantGraph.Styled";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import { closeIcon } from "../../../../assets/icons/common";
import { formatValue } from "../../../../components/charts/QuadrantChart/QuadrantChartConfig";

const useStyles = makeStyles(() => ({
  darkText: {
    color: "#000000 !important",
  },
}));

const QuadrantGraphPopup = ({ open = true, onCloseHandler, quadrantData }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onCloseHandler}
      style={{
        zIndex: 100,
        margin: "auto",
        width: "100%",
        minHeight: "500px",
        maxHeight: "80vh",
      }}
    >
      <DialogTitle
        style={{ position: "relative" }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        Quadrant Graph
        <img
          style={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }}
          src={closeIcon}
          alt="X"
          onClick={onCloseHandler}
        />
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {quadrantData.map((data, index) => (
                <TableRow key={index}>
                  <TableCell style={{ color: `black` }}>
                    {data.name} ({formatValue(data.x)}, {formatValue(data.y)},{" "}
                    {formatValue(data.z)})
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default QuadrantGraphPopup;
