import styled from "styled-components";

export const ProgressBarContainer = styled.div`
  role: progressbar;
  label: Warning example;
`;

export const ProgressBar = styled.div<{ width: number }>`
  background: #00BFFF;
  color: "black";
  width: ${(props) => props.width}%;
`;
export const ProgressHeader = styled.div<{ width: number }>`
  background: #00BFFF;
  color: "black";
  width: ${(props) => props.width}%;
`;
