import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import React, { useEffect, useState } from "react";
import {
  FilterGrid,
  FilterLabel,
  SubTitle,
  Title,
} from "../ExecutionCommon.Styled";
import SelectInput from "../CommonComponents/SelectInput";
import { useSelector } from "react-redux";

const GEO_FILTER = "geo";
const FilterMapper = {
  geo: "Geo Filters",
  overall: "Overall Filters",
};
const FilterLabelMapper = {
  country: "Country",
  period: "Date Time Period",
  businessUnit: "Business Unit",
  channel: "Channel",
  region: "Region",
  storeSegment: "Store Segment",
  store: "Store",
};

const FilterComponent = ({ filter, title }) => {
  const { timePeriod } = useSelector((state: any) => state.allFilter);

  return (
    <div style={{ padding: "10px" }}>
      <Accordion
        defaultExpanded
        sx={{ background: "#F1F5FE", border: "none", boxShadow: "none" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#000" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Title>{FilterMapper[title]} :</Title>
          <SubTitle
            style={{
              marginLeft: "10px",
              color: "#000000",
              opacity: 0.6,
              fontFamily: "Mulish",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "15.06px",
              textTransform:"none"
            }}
          >
            {title === "overall" && timePeriod !== "" ? (
              <span>{timePeriod}</span>
            ) : (
              ""
            )}
          </SubTitle>
        </AccordionSummary>
        <AccordionDetails>
          <FilterGrid>
            {Object.keys(filter).map((item) => {
              return (
                <>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FilterLabel>
                      {FilterLabelMapper[item]}
                      <span style={{ color: "red" }}>*</span>
                    </FilterLabel>
                    <SelectInput
                      options={filter[item]}
                      name={item}
                      isMultiple={title == GEO_FILTER ? true : false}
                      isFilter={true}
                    ></SelectInput>
                  </div>
                </>
              );
            })}
          </FilterGrid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FilterComponent;
