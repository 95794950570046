import { mapperForFilterName } from "../../../mocks/mixSiumulation";
import {
    CLEAR_MIX_SIMULATION_FILTERS_REQUEST,
    FETCH_MIX_SIMULATION_FILTERS_FAILURE,
    FETCH_MIX_SIMULATION_FILTERS_REQUEST,
    FETCH_MIX_SIMULATION_FILTERS_SUCCESS,
    SELECTED_MIX_SIMULATION_FILTER,
    SET_MIX_SIMULATION_LAST_TRIGGERED_KEY,
    SET_PREVIOUSLY_SELECTED_MIX_SIMULATION_FILTER,
    UPDATE_MIX_SIMULATION_FILTER,
    OUTPUT_SELECTED_MIX_FILTERS,
    SET_OUTPUT_SCREEN_TO_DISPLAY,
    GET_GEO_FILTER_DATA,
    RESET_MIX_SIMULATION_GEO_FILTERS_REQUEST,
    SECTION_FILTER_LOADER,
    GET_HISTORICAL_RANGE_DATA,
    GET_PRICE_ELASTICITY_DATA,
    LOADER_FOR_HISTORICAL_RANGE,
    LOADER_FOR_Elasticity,
    TOGGLE_FOR_PI,
    RESET_DATA_FOR_GRAPH,
    HANDLE_MIX_SIMULATION_EDIT_MODE,
    RESET_ALL_MIX_SIMULATION_FILTERS,
    SELECTED_BUBBLE_VALUE_SELECTION_FILTER_MIX_SIMULATOR,
    SELECTED_BUBBLE_CHART_OPTIONS_SELECTION_FILTER_MIX_SIMULATOR,
    SET_SELECTED_DATE_TIMEPERIOD_DETAILS,
    SET_MIX_SIMULATOR_PRICE_ELASTICITY_DOWNLOAD_DATA,
    SET_MIX_SIMULATOR_PRICE_ELASTICITY_UPLOAD_DATA
} from "../../actions/constants";


const initialState = {
    data: {
        overall: {
            country: [],
            period: [],
            businessUnit: []
        },
        geo: {
            channel: [],
            region: [],
            storeSegment: [],
        }
    },
    lastTriggeredKey: '',
    default: true,
    loading: false,
    error: null,
    outputScreen: false,
    isViewMode: false,
    isEditMode: false,
    previouslyselectedFilters: {
        country: [],
        period: [],
        businessUnit: [],
        level: [],
        channel: [],
        region: [],
        storeSegment: [],
    },
    selectedDateTimePeriodDetails: {
        startMonth: "",
        endMonth: "",
        startYear: "",
        endYear: ""
    },
    selectedFilters: {
        country: [],
        period: [],
        businessUnit: [],
        level: [],
        channel: [],
        region: [],
        storeSegment: [],
    },
    geoFilterData: {},
    sectionFilterLoaderState: false,
    loaderHistoricalRange: false,
    loaderPriceElasticty: false,
    historicalRangeFullData: {},
    historicalRangeData: {
        categories: [],
        data: []
    },
    priceElasticityData: [],
    selectedBubble: "",
    PriceElasticityDataUploaded:"",
    selectedBubbleChartOptions: "",
    priceElasticityDownloadData: []
};

const mixSimulationAllFilter = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MIX_SIMULATION_FILTERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };

        case HANDLE_MIX_SIMULATION_EDIT_MODE:
            return {
                ...state,
                isEditMode: action.payload,
            }
        case RESET_ALL_MIX_SIMULATION_FILTERS:
            return initialState;
        case RESET_MIX_SIMULATION_GEO_FILTERS_REQUEST:
            return {
                ...state,
                geoFilterData: {},
                selectedFilters: {
                    ...state.selectedFilters, level: [],
                    channel: [],
                    region: [],
                    storeSegment: [],
                },
                previouslyselectedFilters: {
                    ...state.previouslyselectedFilters, level: [],
                    channel: [],
                    region: [],
                    storeSegment: [],
                }
            };
        case FETCH_MIX_SIMULATION_FILTERS_SUCCESS:
            const { overall, geo } = action.payload.data;
            const existingOverall = state.data.overall;
            const existingGeo = state.data.geo;
            const lastTriggeredKey = state.lastTriggeredKey

            function splitArray(allKeys, lastTriggeredKey) {
                const index = allKeys.findIndex(item => item === lastTriggeredKey);

                if (index !== -1) {
                    const unionKeys = allKeys.slice(0, index + 1);
                    return unionKeys;
                }

                // If the element is not found, return the original array
                return allKeys;
            }


            const updateAllFilters = (existingFilters, incomingFilters) => {
                let updatedFilter = {}
                const allKeys = Array.from(new Set([...Object.keys(existingFilters), ...Object.keys(incomingFilters)]));
                const unionKeys = splitArray(allKeys, lastTriggeredKey)

                allKeys.forEach((key) => {
                    if (unionKeys.includes(key)) {
                        updatedFilter[key] = unionArraysForKey(existingFilters, incomingFilters, key);
                    } else {
                        console.log("incomingFilters", incomingFilters, key);

                        updatedFilter[key] = incomingFilters[key]
                    }
                });
                return updatedFilter
            }

            const unionArraysForKey = (existingObject, incomingObject, key) => {
                const set = new Set([...existingObject[key], ...incomingObject[key]]);
                return Array.from(set);
            };
            const updateSelectedFilters = (existingFilters, newFilters) => {
                const updatedFilters = { ...existingFilters };
                const newFiltersToBeUpated = splitArray(Object.keys(newFilters), lastTriggeredKey)
                for (const key in newFilters) {
                    if (newFiltersToBeUpated.includes(key)) {

                        if (Object.hasOwnProperty.call(newFilters, key) && newFilters[key].length == 0) {
                            updatedFilters[key] = newFilters[key];
                        }
                    } else {
                        updatedFilters[key] = [];
                    }
                }
                return updatedFilters;
            };
            const updatedAllFilterData: any = updateAllFilters({ ...existingOverall, ...existingGeo }, { ...overall, ...geo })
            return {
                ...state,
                data: {
                    ...state.data,
                    overall: {
                        ...state.data.overall,
                        country: updatedAllFilterData.country,
                        period: updatedAllFilterData.period,
                        businessUnit: updatedAllFilterData.businessUnit,
                    },
                    geo:
                    {
                        ...state.data.geo,
                        channel: updatedAllFilterData.channel,
                        region: updatedAllFilterData.region,
                        storeSegment: updatedAllFilterData.storeSegment,
                    },
                },
                //selectedFilters: updateSelectedFilters(state.selectedFilters, { ...overall, ...geo }),
                default: false,
                loading: false,
                error: null
            };
        case SET_MIX_SIMULATION_LAST_TRIGGERED_KEY:
            return {
                ...state,
                lastTriggeredKey: action.payload
            }
        case SET_PREVIOUSLY_SELECTED_MIX_SIMULATION_FILTER:
            return {
                ...state,
                previouslyselectedFilters: state.selectedFilters
            }
        case CLEAR_MIX_SIMULATION_FILTERS_REQUEST:
            return {
                ...state,
                data: {
                    ...state.data,
                    overall: action.payload.data.overall,
                    geo: action.payload.data.geo
                },
                selectedFilters: {
                    ...state.selectedFilters,
                    country: [],
                    period: [],
                    businessUnit: [],
                    channel: [],
                    region: [],
                    storeSegment: [],
                },
                geoFilterData: {},
                default: false,
                loading: false,
                error: null
            }
        case FETCH_MIX_SIMULATION_FILTERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case SELECTED_MIX_SIMULATION_FILTER:
            return {
                ...state,
                selectedFilters: { ...state.selectedFilters, [action.payload?.name]: typeof (action.payload.value) === 'string' ? [action.payload.value] : action.payload.value }
            }
        case UPDATE_MIX_SIMULATION_FILTER:
            return {
                ...state,
                selectedFilters: action.payload
            }
        case OUTPUT_SELECTED_MIX_FILTERS:
            return {
                ...state,
                data: {
                    overall: {
                        country: state.selectedFilters.country,
                        period: state.selectedFilters.period,
                        businessUnit: state.selectedFilters.businessUnit,
                    },
                    geo:
                    {
                        channel: state.selectedFilters.channel,
                        region: state.selectedFilters.region,
                        storeSegment: state.selectedFilters.storeSegment,
                    },
                },
                selectedFilters: state.selectedFilters,
                previouslyselectedFilters: state.selectedFilters,
                default: false,
                loading: false,
                error: null
            };
        case SET_OUTPUT_SCREEN_TO_DISPLAY:
            return {
                ...state,
                outputScreen: action.payload,
                loading: false,
                historicalRangeFullData: {},
                historicalRangeData: {
                    categories: [],
                    data: []
                },
                priceElasticityData: []
            };
        case GET_GEO_FILTER_DATA:
            const responseData = action.payload
            let modifiedResponse = {}
            for (let filtersName in responseData) {
                modifiedResponse[filtersName] = { ...responseData[filtersName], defaultOption: state.selectedFilters[mapperForFilterName[filtersName]] }
            }
            return {
                ...state,
                geoFilterData: modifiedResponse,
                loading: false
            }
        case SECTION_FILTER_LOADER:
            return {
                ...state,
                sectionFilterLoaderState: action.payload
            }
        case GET_HISTORICAL_RANGE_DATA:
            const categories: any = [];

            function requiredDataForPi(data) {
                return data.map((ele) => {
                    categories.push(ele?.xAxisSkuGroupShort);
                    return ({
                        low: ele?.yAxisPiHistMin,
                        q1: ele?.yAxisPiHistMin,
                        median: ele?.yAxisPiCurr,
                        high: ele?.yAxisPiHistMax,
                        q3: ele?.yAxisPiHistMax,
                        name: ''
                    })
                })
            }

            function requiredDataForDistribution(data) {
                return data.map(ele => {
                    categories.push(ele?.xAxisSkuGroupShort);
                    return ({
                        low: ele?.yAxisDistributionHistMin,
                        q1: ele?.yAxisDistributionHistMin,
                        median: ele?.yAxisDistributionCurr,
                        high: ele?.yAxisDistributionHistMax,
                        q3: ele?.yAxisDistributionHistMax,
                        name: ''
                    })
                })
            }

            const getDataForChart = action.payload?.isDistibution ? requiredDataForDistribution(action.payload['distribution']) : requiredDataForPi(action.payload['priceIndex']);

            return {
                ...state,
                historicalRangeData: { data: getDataForChart, categories },
                historicalRangeFullData: action.payload
            }
        case GET_PRICE_ELASTICITY_DATA:
            function reqFieldsForCharts(data) {
                return data.map((ele: any) => {
                    return (
                        {
                            x: ele.pe,
                            y: Math.round(ele.marketShareMinusFairSharePercentage*100)/100,
                            z: Math.round(ele.skuVolumePercentage *100)/100,
                            name: ele.skuGroupShort
                        }
                    )
                })
            }
            return {
                ...state,
                priceElasticityData: reqFieldsForCharts(action.payload)
            }
        case LOADER_FOR_HISTORICAL_RANGE:
            return {
                ...state,
                loaderHistoricalRange: action.payload
            }
        case LOADER_FOR_Elasticity:
            return {
                ...state,
                loaderPriceElasticty: action.payload
            }
        case TOGGLE_FOR_PI:
            const categoriesReq: any = [];
            function requiredDataForPiReq(data) {
                return data.map((ele) => {
                    categoriesReq.push(ele?.xAxisSkuGroupShort);
                    return ({
                        low: ele?.yAxisPiHistMin,
                        q1: ele?.yAxisPiHistMin,
                        median: ele?.yAxisPiCurr,
                        high: ele?.yAxisPiHistMax,
                        q3: ele?.yAxisPiHistMax,
                        name: ''
                    })
                })
            }

            function requiredDataForDistributionReq(data) {
                return data.map(ele => {
                    categoriesReq.push(ele?.xAxisSkuGroupShort);
                    return ({
                        low: ele?.yAxisDistributionHistMin,
                        q1: ele?.yAxisDistributionHistMin,
                        median: ele?.yAxisDistributionCurr,
                        high: ele?.yAxisDistributionHistMax,
                        q3: ele?.yAxisDistributionHistMax,
                        name: ''
                    })
                })
            }
            const getData = action.payload.isDistibution ? JSON.parse(JSON.stringify(state.historicalRangeFullData['distribution'])) : JSON.parse(JSON.stringify(state.historicalRangeFullData['priceIndex']));
            const getDataForChartReq = action.payload.isDistibution ? requiredDataForDistributionReq(getData) : requiredDataForPiReq(getData);

            return {
                ...state,
                historicalRangeData: { data: getDataForChartReq, categories: categoriesReq },
            }
        case RESET_DATA_FOR_GRAPH:
            return {
                ...state,
                historicalRangeFullData: {},
                historicalRangeData: {
                    categories: [],
                    data: []
                },
                priceElasticityData: [],
                priceElasticityDownloadData:[],

            }
        case SELECTED_BUBBLE_VALUE_SELECTION_FILTER_MIX_SIMULATOR:
            return {
                ...state,
                selectedBubble: action.payload
            }
        case SELECTED_BUBBLE_CHART_OPTIONS_SELECTION_FILTER_MIX_SIMULATOR:
            return {
                ...state,
                selectedBubbleChartOptions: action.payload
            }
        case SET_MIX_SIMULATOR_PRICE_ELASTICITY_DOWNLOAD_DATA:
            return {
                ...state,
                priceElasticityDownloadData: action.payload
            }
        case SET_MIX_SIMULATOR_PRICE_ELASTICITY_UPLOAD_DATA:
                return {
                    ...state,
                    PriceElasticityDataUploaded: action.payload
                }
            
        case SET_SELECTED_DATE_TIMEPERIOD_DETAILS:
            return {
                ...state,
                selectedDateTimePeriodDetails: action.payload
            }
        default:
            return state;
    }
}
export default mixSimulationAllFilter;