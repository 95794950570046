import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";

const ExpandIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  background:
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.common.white,
  color: "#08509A",
}));

const AccordianTitle = styled(Typography)(({ theme }) => ({
  // background:
  //   theme.palette.mode === "dark"
  //     ? theme.palette.common.white
  //     : theme.palette.common.white,
  color: "#000000",
  fontFamily: "Mulish",
  fontWeight: 800,
  fontSize: "16px",
  lineHeight: "20.08px",
}));

const AccordianSubTitle = styled(Typography)(({ theme }) => ({
  // background:
  //   theme.palette.mode === "dark"
  //     ? theme.palette.common.white
  //     : theme.palette.common.white,
  color: "#000000",
  opacity: 0.6,
  fontFamily: "Mulish",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "15.06px",
}));

export { ExpandIcon, AccordianSubTitle, AccordianTitle };
