import React from "react";

const filterableColumns = ["subChannel", "geoLevel", "storeSegment"];

const sortableColumns = [
  "currentMix",
  "idealMix",
  "mixDelta",
  "currentMarketShare",
  "idealMarketShare",
  "netRevenuePerKg",
  "noPbtPerKg",
  "revenueSop",
  "noPBTSop",
  "shareSOP",
];

const tableColumns = [
  {
    headerName: "Channel",
    field: "subChannel",
    width: 150,
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", textAlign: "center" }}>
        {params.value}
      </div>
    ),
  },
  {
    headerName: "Region",
    field: "geoLevel",
    width: 150,
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", textAlign: "center" }}>
        {params.value}
      </div>
    ),
  },
  {
    headerName: "Store Segment",
    field: "storeSegment",
    width: 180,
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", textAlign: "center" }}>
        {params.value}
      </div>
    ),
  },
  {
    headerName: "Current Mix",
    field: "currentMix",
    width: 160,
    valueFormatter: (params) => {
      return params.value ? `${params.value.toFixed(2)} %` : "0.00 %";
    },
    align: "center",
  },
  {
    headerName: "Ideal Mix",
    field: "idealMix",
    width: 160,
    valueFormatter: (params) => {
      return params.value ? `${params.value.toFixed(2)} %` : "0.00 %";
    },
    align: "center",
  },
  {
    headerName: "Mix Delta",
    field: "mixDelta",
    width: 160,
    valueFormatter: (params) => {
      return params.value ? `${params.value.toFixed(2)} %` : "0.00 %";
    },
    align: "center",
  },
  {
    headerName: "Current Market Share",
    field: "currentMarketShare",
    width: 200,
    valueFormatter: (params) => {
      if (params.value === '-') {
        return params.value;
      } else {
        return `${formatNumberWithSuffix(params.value)} %`;
      }
    },
    align: "center",
  },
  {
    headerName: "Ideal Market Share",
    field: "idealMarketShare",
    width: 160,
    valueFormatter: (params) => {
      if (params.value === '-') {
        return params.value;
      } else {
        return `${formatNumberWithSuffix(params.value)} %`;
      }
    },
    align: "center",
  },
  {
    headerName: "Net Revenue Per Kg",
    field: "netRevenuePerKg",
    width: 200,
    valueFormatter: (params) => {
      return params.value ? `${params.value.toFixed(2)}` : "0.00";
    },
    align: "center",
  },
  {
    headerName: "Profit Per Kg",
    field: "noPbtPerKg",
    width: 160,
    valueFormatter: (params) => {
      return params.value ? `${params.value.toFixed(2)}` : "0.00";
    },
    align: "center",
  },
  {
    headerName: "Revenue SOP",
    field: "revenueSop",
    width: 160,
    valueFormatter: (params) => {
      return formatNumberWithSuffix(params.value);
    },
    align: "center",
  },
  {
    headerName: "Profit SOP",
    field: "noPBTSop",
    width: 160,
    valueFormatter: (params) => {
      return formatNumberWithSuffix(params.value);
    },
    align: "center",
  },
  {
    headerName: "Share SOP",
    field: "shareSOP",
    width: 160,
    valueFormatter: (params) => {
      if (params.value === '-') {
        return params.value;
      } else {
        return `${formatNumberWithSuffix(params.value)} %`;
      }
    },
    align: "center",
  },
];

const formatNumberWithSuffix = (value) => {
  const suffixes = ["", "K", "M", "B", "T", "Q"];
  const baseFactor = 1000;

  let numericValue;
  if (value === "-") {
    return value;
  } else {
    numericValue = typeof value === "string" ? parseFloat(value) : value;
  }

  if (!isNaN(numericValue)) {
    let isNegative = numericValue < 0;
    if (isNegative) {
      numericValue = Math.abs(numericValue);
    }

    let suffixIndex = 0;
    while (numericValue >= baseFactor && suffixIndex < suffixes.length - 1) {
      numericValue /= baseFactor;
      suffixIndex++;
    }

    numericValue = parseFloat(numericValue.toFixed(2)).toString();
    numericValue += suffixes[suffixIndex];

    if (isNegative) {
      numericValue = "-" + numericValue;
    }
  }
  return numericValue;
};

export { filterableColumns, sortableColumns, tableColumns };

export const columnsForGeoLevelToDownload = [
  { title: "Channel", key: "subChannel" },
  { title: "Region", key: "geoLevel" },
  { title: "Store Seg", key: "storeSegment" },
  { title: "Current Mix(%)", key: "currentMix" },
  { title: "Ideal Mix(%)", key: "idealMix" },
  { title: "Mix Delta(%)", key: "mixDelta" },
  { title: "Current Market Share(%)", key: "currentMarketShare" },
  { title: "Ideal Market Share(%)", key: "idealMarketShare" },
  { title: "Net Revenue per KG", key: "netRevenuePerKg" },
  { title: "Profit per KG", key: "noPbtPerKg" },
  { title: "Revenue SOP", key: "revenueSop" },
  { title: "Profit SOP", key: "noPBTSop" },
  { title: "Share SOP(%)", key: "shareSOP" },
];

export const levelsForGeoLevel = [
  {
    id: 1,
    name: "Region",
  },
  {
    id: 2,
    name: "Store Segment",
  },
];

export const geoLevelData = [
  {
    id: 0,
    uid: "d60f2dc1-d4ef-40f4-b8b6-09fbaacd5b32",
    subChannel: "SMKT",
    geoLevel: "REGION 1",
    storeSegment: null,
    selectedLevel: null,
    level: ["GeoLevel", "Channel"],
    skuVolume: 140197.921875,
    skuSales: 2411762.75,
    skuOptimisedVolume: 908374,
    optimisedRevenue: 16398202,
    optimisedProfit: 2123896.5,
    penetration: 0.10000000149011612,
    noPBT: 315161.96875,
    currentMix: 100,
    idealMix: 100,
    mixDelta: 0,
    netRevenuePerKg: 18.0522582108251,
    noPBTSop: 2.33812999931746,
    revenueSop: 13986439.25,
    noPbtPerKg: 1808734.531,
  },
];
