import { Box, Button, Snackbar, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { StyledTextField } from './MinMaxInputs.Styled';
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from '../../../../store/actions/toast.action';
const useStyles = makeStyles((theme) => ({
  asterik: { color: "red" },
  inputRoot: {
    '& input[type="number"]': {
      "-moz-appearance": "textfield",
      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&:hover::-webkit-inner-spin-button, &:hover::-webkit-outer-spin-button":
      {
        backgroundColor: 'none',
      },
    },
  },

  adornment: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  arrowButton: {
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "0",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
}));
const ApplyToAll: React.FC<{
  callback;
  defaultFilters?;
  onSelectApply?;
  handleReset;
}> = ({ callback, defaultFilters, onSelectApply,handleReset }) => {
  const classes = useStyles();
  const [maxChange, setMaxChange] = useState(0.5);
  const [minChange, setMinChange] = useState(0.5);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useDispatch()

  const handleMaxChange = (event) => {
    const maxChangeValue = Math.max(0, parseFloat(event.target.value));
    setMaxChange(maxChangeValue);
  };

  const handleMinChange = (event) => {
    const minChangeValue = Math.max(0,parseFloat(event.target.value));
    setMinChange(minChangeValue)
  };

  const handleBlur=(event,type)=>{
    const roundedValue = Math.round(parseFloat(event.target.value)*10)/10
    // console.log("handleBlur",event,type);
    if(type=='minChange'){
      setMinChange(roundedValue)
    }else{
    setMaxChange(roundedValue);

    }
  }
  
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const onApplyToAll = () => {
    callback(minChange, maxChange);
  };
   const onReset=()=>{
    handleReset();
    setMinChange(0.5);
    setMaxChange(0.5);
   }

  return (
    <div
      style={{
        width: '250px',
        border: '2px solid #D8D8D8',
        borderRadius: '6px',
        paddingLeft: '5px',
        paddingRight: '5px',
        borderColor: '#D8D8D8',
        borderWidth: '2px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'start',
        paddingTop: '8px',
        paddingBottom: '8px',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '10px', gap: '8px' }}>
        <Typography
          style={{
            fontFamily: 'Mulish',
            fontSize: '14px',
            fontWeight: '400',
            textAlign: 'left',
            color: 'black',
            marginRight: '10px',
            whiteSpace: 'nowrap'
          }}
        >
          Change Min Mix (%)
        </Typography>
        <StyledTextField
          type="number"
          value={minChange}
          className={classes.inputRoot}
          onChange={handleMinChange}
          onBlur={(event)=>handleBlur(event,'minChange')}

        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '4px', gap: '5px' }}>
        <Typography
          style={{
            fontFamily: 'Mulish',
            fontSize: '14px',
            fontWeight: '400',
            textAlign: 'left',
            color: 'black',
            marginRight: '10px',
            whiteSpace: 'nowrap'

          }}
        >
          Change Max Mix (%)
        </Typography>
        <StyledTextField
          type="number"
          value={maxChange}
          className={classes.inputRoot}
          onChange={handleMaxChange}
          onBlur={(event)=>handleBlur(event,'maxChange')}
        />
      </div>
      <Box width="100%" mt="5px" display="flex" justifyContent="space-around" alignItems="center">
        <Button style={{ backgroundColor: '#08509A', color: 'white', width: '96px', height: '25px', fontSize: '10px', borderRadius: '10px' }}  onClick={onApplyToAll}>
          Apply to All
        </Button>
        <Button style={{ backgroundColor: '#08509A', color: 'white', width: '96px', height: '25px', fontSize: '10px', borderRadius: '10px' }}  onClick={onReset}>
          Reset Default
        </Button>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};

export default ApplyToAll;
