import React, { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FilterListIcon from "@mui/icons-material/FilterList";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StyledTableContainer } from "../../../PriceSettingTool/components/VisualizationContainer/components/VisualizationTable/VisualizationTable.Styled";
import { Button, Card, CardContent, FormControl, FormControlLabel, FormGroup, Grid, Popover, TextField } from "@mui/material";
import {
  CardContentRight,
  StyledTable,
  StyledTableCell,
  StyledTableRow,
  StyledTableTextField,
} from "./MixTable.Styled";
import ResetDefault from "../ResetDefault/MixGuidelineLabel";
import ApplyToAll from "../MinMaxInputs/MinMaxInputs";
import MixGuidelineLabel from "../ResetDefault/MixGuidelineLabel";
import {
  fetchMixSimulationConstraintTableData,
  isMixSimulationConstraintTableDataChanged,
  setMixSimulationConstraintIntermediateTableData,
  setMixSimulationConstraintTableData,
  setMixSimulationConstraintTableFilter,
} from "../../../../store/actions/MixSimulationTool/mixSimulationConstraintTable.action";
import { StyledTextField } from "../../../../components/UI-components/TextFieldInput/TextFieldInput.Styled";
import RunSimulationButton from "../RunSimulationButton/MixRunSimulation";
import MixConstBar from "../MixContsBar/MixConstBar";
import Indicator from "../../../../components/Loader/Loader";
import MixGuidelinesComponent from "./MixGuidelinesComponent";
import MinMaxInputs from "../MinMaxInputs/MinMaxInputs";
import { toast } from "../../../../store/actions/toast.action";
import MixTableFilters from "./MixTableFilters";
import _ from "lodash";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface Column {
  id:
  | "channel"
  | "region"
  | "store_segment"
  | "segment"
  | "brand"
  | "subbrand"
  | "sku"
  | "mixguidelines"
  | "pack_size"
  | "flavour"
  | "min_mix"
  | "max_mix";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

interface Data {
  channel: string;
  region: string;
  storesegment: string;
  segment: string;
  brand: string;
  subbrand: string;
  sku: string;
  packsize: string;
  // mixguidelines: number;
  min_mix: number;
  max_mix: number;
}

const columns: Column[] = [
  // { id: 'channel', label: 'Channel', minWidth: 70 },
  // { id: 'region', label: 'Region', minWidth: 70 },
  // { id: 'store_segment', label: 'Store Segment', minWidth: 100 },
  { id: 'segment', label: 'Segment', minWidth: 100 },
  { id: 'brand', label: 'Brand', minWidth: 100 },
  { id: 'subbrand', label: 'Sub Brand', minWidth: 100 },
  { id: 'pack_size', label: 'Pack Size', minWidth: 100 },
  { id: 'flavour', label: 'Flavor', minWidth: 100 },
  { id: 'sku', label: 'SKU', minWidth: 90 },
  // { id: 'mixguidelines', label: 'Mix Guidelines', minWidth: 170 },
  { id: "min_mix", label: "Min Mix(%)", minWidth: 10 },
  { id: "max_mix", label: "Max Mix(%)", minWidth: 10 },
];

const filterableColumns = ['segment','brand','subbrand','pack_size','flavour','sku'];

const inputField = ["min_mix", "max_mix"];
const isValidMixRange = (minMix, maxMix) => {
  return minMix <= maxMix;
};

function ControlledInput({ columnName, row, handleChangeInput, id }) {


  const mixInputRef = useRef("");
  const dispatch = useDispatch();
  const [mixValue, setMixValue] = useState(Number(row[columnName]));
  const [mixError, setMixError] = useState("");



  useEffect(() => {
    setMixValue(Number(row[columnName]));
    setMixError("");
  }, [row[columnName]]);

  const handleMixChange = (e) => {
    const value = parseFloat(e.target.value);
    setMixValue(value);
    // if (columnName === "min_mix") {
    //   // Validate mix range for min_mix input
    //   if (!isValidMixRange(value, row["max_mix"])) {
    //     setMixError("Invalid range");
    //   } else {
    //     setMixError("");
    //   }
    // } else if (columnName === "max_mix") {
    //   // Validate mix range for max_mix input
    //   if (!isValidMixRange(row["min_mix"], value)) {
    //     setMixError("Invalid range");
    //   } else {
    //     setMixError("");
    //   }
    // }
  };

  const handleBlur = (e) => {
    console.log("handleBlur",e.target.value,typeof(e.target.value))
    // const roundedValue = Math.round(parseFloat(e.target.value)*100)/100
    const roundedValue = parseFloat(e.target.value);
    
    if (columnName === "min_mix") {
      // Validate mix range for min_mix input
      if (!isValidMixRange(mixValue, row["max_mix"])) {
        setMixError("Invalid range");
        dispatch(
          toast("Min Mix should be smaller than Max Mix", true, 2000, "error")
        );
        //  setMixValue(previousMixValue);
        setMixValue(0);
      } else {
        setMixValue(roundedValue);
        handleChangeInput(columnName, row.srno, roundedValue, id);
        setMixError('');
      }
    } else if (columnName === "max_mix") {
      // Validate mix range for max_mix input
      if (!isValidMixRange(row["min_mix"], mixValue)) {
        // setMixValue(previousMixValue);
        setMixError("Invalid range");
        dispatch(
          toast("Max Mix should be greater than Min Mix", true, 2000, "error")
        );
      } else {
        // setMixValue(value);
        setMixValue(roundedValue);
        handleChangeInput(columnName, row.srno, roundedValue, id);
        setMixError('');
      }
    }
  };

  return (
    <div>
      <StyledTableTextField
      sx={{
        p:'1px 3px'
      }}
        variant="outlined"
        value={Number.parseFloat(mixValue.toFixed(2))}
        onChange={handleMixChange}
        onBlur={handleBlur}
        inputRef={mixInputRef}
        type="number"
        inputProps={{
          step: 0.5,
          style: { textAlign: 'center' }
        }}
      />
      {mixError && <div style={{ color: "red" }}>{mixError}</div>}
    </div>
  );
}

export default function MixTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentColumn, setCurrentColumn] = useState<string>("");
  const [filters, setFilters] = useState<{ [key: string]: string[] }>({
    channel: [],
    region: [],
    storesegment: [],
    segment: [],
    brand: [],
    subbrand: [],
    sku: [],
  });
  const { tableData, loading } = useSelector(
    (state: any) => state.mixSimulationConstraintTable
  );
  const columnsToShow = [
    "channel",
    "region",
    "store Segment",
    "segment",
    "brand",
    "sub Brand",
    "sku",
    "min Mix",
    "max Mix",
  ];
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [tableRows, setTableRows] = useState(tableData);
  const [clearFilters, setClearFilters] = useState<boolean>(false);
  const [applytoallButton, setApplyToAllButton] = useState(true);
  const dispatch = useDispatch();
  const [anchorEls, setAnchorEls] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [originalFilterValues, setOriginalFilterValues] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const [searchText, setSearchText] = useState("");
  const [filteredRows, setFilteredRows] = useState(tableRows);



  useEffect(() => {
    setTableRows(tableData);

  }, [tableData]);
  useEffect(() => {
    // console.log(_.isEqual(tableData, tableRows));
    const originalValues = updationOnColumnFilters(columns, tableRows);
    setOriginalFilterValues(originalValues);
    setFilterValues(originalValues);
    setFilteredRows(tableRows);
    if (_.isEqual(tableData, tableRows)) {
      dispatch(isMixSimulationConstraintTableDataChanged(false));
    } else {
      dispatch(setMixSimulationConstraintIntermediateTableData(tableRows));
      dispatch(isMixSimulationConstraintTableDataChanged(true));
    }
  }, [tableRows]);

  useEffect(() => {
    const filtered = tableRows.filter((row) => {
      for (const [id, values] of Object.entries(selectedFilters)) {
        if (values.length > 0 && !values.includes(row[id])) {
          return false;
        }
      }
      return true;
    });
  
    const processedRows = filtered.map((row) => {
      const processedRow = {};
      Object.keys(row).forEach((key) => {
        if (typeof row[key] === 'string') {
          processedRow[key] = row[key];
        } else {
          processedRow[key] = isNaN(parseFloat(row[key]))
            ? row[key]
            : parseFloat(row[key]);
        }
      });
      return processedRow;
    });
  
    setFilteredRows(
      processedRows.filter((row) =>
        Object.values(row).some((val) =>
          String(val).toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  }, [selectedFilters, searchText, tableRows]);

  const updationOnColumnFilters = (tableColumns, data) => {
    const distinctValues = {};
    tableColumns.forEach((column) => {
      const values = new Set();
      data.forEach((row) => {
        values.add(row[column.id]);
      });
      distinctValues[column.id] = Array.from(values);
    });
    return distinctValues;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    columnId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentColumn(columnId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);

  const handleReset = () => {
    // console.log(tableData)
    setTableRows(tableData);
    dispatch(isMixSimulationConstraintTableDataChanged(false));
  };

  
  const handleChangeFilter = (event, column, value) => {
    console.log(event.target.checked, column, value)
    if (value === "selectAll") {
      setSelectedFilters({
        ...selectedFilters,
        [column.id]: event.target.checked ? filterValues[column.id] : [],
        [`selectAll${column.id}`]: event.target.checked,
      });
    } else {
      console.log(event.target.checked)
      setSelectedFilters({
        ...selectedFilters,
        [column.id]: event.target.checked
          ? [...selectedFilters[column.id], value]
          : selectedFilters[column.id].filter((val) => val !== value),
        [`selectAll${column.id}`]: false,
      });
    }
  };
  const handleSearch = (e, column) => {
    const { value } = e.target;
    setSearchText(value);

    if (value.trim() === "") {
      setFilterValues((prevFilterValues) => ({
        ...prevFilterValues,
        [column.id]: originalFilterValues[column.id].sort(),
      }));
    } else {
      const filteredOptions = originalFilterValues[column.id].filter(
        (option) => option.toLowerCase().includes(value.toLowerCase())
      );
      setFilterValues((prevFilterValues) => ({
        ...prevFilterValues,
        [column.id]: filteredOptions.sort(),
      }));
    }
  };

  const onApplyToAll = (minChange, maxChange) => {
    console.log("minChange",minChange)
    console.log("maxChange",maxChange)
    setApplyToAllButton(false);
    setClearFilters(true);
    setTableRows((prevState) =>
      prevState.map((element) => {
        // let minPercentage = (element["min_mix"] + Number.parseInt(minChange))
        // let maxPercentage = (element["max_mix"] + Number.parseInt(maxChange))
        let newMinMixValue =
          (Number(element["min_mix"]) + Number.parseFloat(minChange));
        let newMaxMixValue =
          (Number(element["max_mix"]) + Number.parseFloat(maxChange));
          console.log("newMinMixValue",newMinMixValue);
          console.log("newMaxMixValue",newMaxMixValue);
        if (newMinMixValue <= newMaxMixValue) {
          return {
            ...element,
            min_mix: newMinMixValue,
            max_mix: newMaxMixValue,
          };
        } else {
          dispatch(
            toast("Min Mix should be smaller than Max Mix", true, 2000, "error")
          );
          return { ...element };
        }
      })
    );
    dispatch(isMixSimulationConstraintTableDataChanged(true));
  };

  // const handleApplyToAllChange = (selectedFilters) => {
  //   setFilters(selectedFilters);
  // };
  const handleFilterClick = (event, column)=>{
    console.log("handleFilterClick",event, column)

      setAnchorEls({
        ...anchorEls,
        [column.id]: event.currentTarget,
      });
      if (!selectedFilters[column.id]) {
        setSelectedFilters({
          ...selectedFilters,
          [column.id]: [],
        });
    
    };

  }

  const handleClose = (e,column) => {
    // console.log(e)
    setSearchText("");
    setFilterValues((prevFilterValues) => ({
      ...prevFilterValues,
      [column.id]: originalFilterValues[column.id].sort(),
    }))
    setAnchorEls({});
  };
  const handleChangeInput = (colID: string, row, mixValue, id) => {
    setTableRows((prevState) =>
      prevState.map((ele) => {
        if (ele.id == id) {
          dispatch(isMixSimulationConstraintTableDataChanged(true));

          return { ...ele, [colID]: mixValue };
        } else {
          return { ...ele };
        }
      })
    );
  };

  return (
    <Grid style={{ position: "relative" }}>
      <Indicator position="absolute" show={loading} />
      <Grid>
        <MixConstBar tableData={tableRows} />
      </Grid>
      <Grid>
        <Card style={{ backgroundColor: "white" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              padding: "20px 20px",
            }}
          >
            <MixTableFilters />
            {/* <MixGuidelineLabel /> */}
            <CardContentRight>
              {/* <Button
                style={{
                  backgroundColor: "#08509A",
                  color: "white",
                  width: "200px",
                }}
                className="m-r-20"
                onClick={handleReset}
              >
                Reset Default
              </Button> */}

              {tableData.length > 0 ? (
                <>
                  <ApplyToAll
                    callback={onApplyToAll}
                    defaultFilters={setFilters}
                    handleReset={handleReset}
                  />
                </>
              ) : (
                <></>
              )}
            </CardContentRight>
          </div>
          {tableData.length > 0 ? (
            <StyledTableContainer
              style={{
                backgroundColor: "#F1F5FE",
                margin: "auto",
                marginTop: "0.6rem",
                maxHeight: "70vh",
              }}
              isSidebarOpen={sidebarOpen}
            >
              <StyledTable stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          whiteSpace: "nowrap",
                          background: "white",
                          minWidth: column.minWidth,
                        }}
                      >
                        <div style={{ display:"flex",alignItems:"center",justifyContent:'center',color: "#82868C" }}>
                          {column.label} {filterableColumns.includes(column.id) && (<FilterListIcon style={{cursor:'pointer'}} onClick={(event) => handleFilterClick(event, column)}/>)} </div>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRows
                    .map((row, index) => (
                      <StyledTableRow role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              background: "white",
                            }}
                          >
                            {column.id == "mixguidelines" ? (
                              <MixGuidelinesComponent
                                maximumMarketShare={row["maximum_market_share"]}
                                maximumProfitMargin={
                                  row["maximum_profit_margin"]
                                }
                                maximumNetRevenue={
                                  row["maximum_net_revenue_per_kg"]
                                }
                              />
                            ) : inputField.includes(column.id) ? (
                              <ControlledInput
                                columnName={column.id}
                                row={row}
                                handleChangeInput={handleChangeInput}
                                id={row.id}
                              />
                            ) : (
                              String(row[column.id])
                            )}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
          ) : (
            <div
              style={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              No rows
            </div>
          )}
        </Card>
      </Grid>
      {columns.map((column) => (
        <Popover
          key={column.id}
          open={Boolean(anchorEls[column.id])}
          anchorEl={anchorEls[column.id]}
          onClose={(e)=>handleClose(e,column)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          style={{ color: "black" }}
        >
          <div
            style={{
              maxHeight: "200px",
              maxWidth: "400px",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search..."
              onChange={(e) => handleSearch(e, column)}
              style={{ marginBottom: "8px", padding: "8px" }}
            />

            <FormControl component="fieldset">
              <FormGroup style={{ padding: "8px" }}>
                {filterValues && filterValues[column.id] && (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            selectedFilters[`selectAll${column.id}`] ||
                            (selectedFilters[column.id]?.length ===
                              filterValues[column.id]?.length &&
                              filterValues[column.id]?.length > 0)
                          }
                          onChange={(event) =>
                            handleChangeFilter(event, column, "selectAll")
                          }
                          name={`selectAll${column.id}`}
                        />
                      }
                      label={
                        <span style={{ color: "black", marginLeft: "8px" }}>
                          Select All
                        </span>
                      }
                      style={{ margin: 0 }}
                    />
                    {filterValues[column.id].sort().map((value) => (
                      <FormControlLabel
                        key={value}
                        control={
                          <Checkbox
                            checked={selectedFilters[column.id]?.includes(
                              value
                            )}
                            onChange={(event) =>
                              handleChangeFilter(event, column, value)
                            }
                            name={value}
                            value={value}
                          />
                        }
                        label={
                          <span style={{ color: "black", paddingLeft: "8px" }}>
                            {value}
                          </span>
                        }
                        style={{ margin: 0 }}
                      />
                    ))}
                  </>
                )}
              </FormGroup>
            </FormControl>
          </div>
        </Popover>
      ))}
      <Grid>
        <RunSimulationButton tableData={tableRows} />
      </Grid>
    </Grid>
  );
}
