import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  TableRow,
  Typography,
  TypographyProps,
} from "@mui/material";
const Title = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 600,
}));
const ThemeTitle = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 600,
}));
const Icon = styled("img")(() => ({
  width: 35,
  cursor: "pointer",
  marginLeft: 10,
}));
const BlackInputTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.common.black,
}));
const InputTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color:
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.common.black,
}));
const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color:
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.common.black,
}));
const OppTypography = styled(Typography)(({ theme }) => ({
  //Opisite color typography
  color:
    theme.palette.mode === "dark"
      ? theme.palette.common.black
      : theme.palette.common.white,
}));
const PrimaryBtn = styled(Button)`
  background: #25d4cf;
  color: #fff;
  &:hover {
    color: #fff;
    background-color: #25d4cf;
  }
`;
const GrayBtn = styled(Button)`
  background: gray;
  color: #fff;
  &:hover {
    color: #fff;
    background-color: #25d4cf;
  }
`;
const OrangeBtn = styled(Button)`
  background: #f77b36;
  color: #fff;
  &:hover {
    color: #fff;
    background-color: grey;
  }
`;

const LightBlueBtn = styled(Button)`
  background: white;
  color: #25d4cf;
  cursor: pointer;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    color: grey;
    background: grey;
  }
  &:hover {
  color: #2f5597;
  background-color: #f1f5fe;
  }
`;

const ApplyBtn = styled(Button)`
  background: #43659f;
  color: #ffffff;
  cursor: pointer;
  border-radius: 8px;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 8px;
  width: 59px !important;
  height: 24px !important;
  padding: 8px 10px 8px 10px;
  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
    color: #000;
  }
  &:hover {
    color: #ffffff;
    background-color: #43659f;
  }
`;

const ClearBtn = styled(Button)`
  background: #e81c0e;
  color: #ffffff;
  cursor: pointer;
  width: 59px !important;
  height: 24px !important;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 8px;
  white-space: nowrap;
  padding: 8px 10px 8px 10px;
  border-radius: 8px;
  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
    color: #000;
  }
  &:hover {
    color: #ffffff;
    background-color: #e81c0e;
  }
`;

const DisableBtn = styled(Button)`
  background: gray;
  color: black;
  cursor: not-allowed;
  pointer-event : none
  &:hover {
    color: black;
    background: gray;
    cursor: not-allowed;
  }
`;

const BlackTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
}));
interface IGraphLabel extends TypographyProps {
  color?: string;
  bgcolor?: string;
  padding?: string;
}
const GraphLabel = styled(Typography)<IGraphLabel>`
  padding: ${(props) => props.padding || "8px"};
  color: ${(props) => props.color};
  fontsize: "12px";
  backgroundcolor: ${(props) => props.bgcolor};
`;
const BlackTextBtn = styled(Button)(({ theme }) => ({
  textTransform: "inherit",
  fontSize: 14,
  color: theme.palette.common.black,
}));
const CloseButton = styled(Box)`
  font-size: 12px;
  border-radius: 50%;
  background: #fff;
  color: #858c94;
  border: 1px solid #858c94;
  text-align: center;
  position: absolute;
  right: -5px;
  top: -5px;
  cursor: pointer;
`;
const NoData = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 500,
  color: theme.palette.common.black,
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));
export {
  Icon,
  BlackInputTitle,
  FooterText,
  Title,
  InputTitle,
  OppTypography,
  PrimaryBtn,
  BlackTitle,
  GrayBtn,
  GraphLabel,
  OrangeBtn,
  BlackTextBtn,
  CloseButton,
  NoData,
  ThemeTitle,
  StyledTableRow,
  LightBlueBtn,
  DisableBtn,
  ApplyBtn,
  ClearBtn,
};
