import { createStyles } from "@mui/material/styles";

const tableStyles = createStyles({
  tableContainer: {
    width: "1377px",
    height: "500px",
    overflow:"scroll",
    color:"#F1F5FE",
  },
  
  table: {
    color:"#F1F5FE",
    width:'400px',
    overflow:"scroll",
    scrollbarWidth: 'none', 
    '&::-webkit-scrollbar': {
      display: 'none', 
    },
  },
  columnWidth: "100px",
  columnHeight: "20px"
});

export {tableStyles};
