import {
  styled,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
} from "@mui/material";

const StyledCard = styled(Card)({
  height: "270px",
  borderRadius: "3px",
  background:"white"
});

const StyledCardContent = styled(CardContent)({});

const LabelTypography = styled(Typography)({
  fontWeight: "600",
  fontSize: "12px",
  fontFamily: "Montserrat, sans-serif",
  color:"#0B0E1E",
  opacity:0.5
});

const TitleTypography = styled(Typography)({
  fontWeight: "600",
  fontSize: "14px",
  fontFamily: "Montserrat",
  color:"#333333",
  textAlign:"center"
});

const ValueTypography = styled(Typography)({
    fontWeight: "700",
    fontSize: "16px",
    fontFamily: "Montserrat, sans-serif",
    color:"#0B0E1E"
  });

const StyledDivider = styled(Divider)({
  borderStyle: "line",
  borderWidth: "1px",
  color: "black",
  margin: "12px 0",
});

const StyledGrid = styled(Grid)({});

export {
  StyledCard,
  StyledCardContent,
  StyledDivider,
  StyledGrid,
  TitleTypography,
  LabelTypography,
  ValueTypography
};
