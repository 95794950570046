import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { saveMixSimulationScenario, setMixSimulationGoal } from "../../../../store/actions/MixSimulationTool/mixsimulationUserInputs.action";
import { fetchMixSimulationConstraintTableData } from "../../../../store/actions/MixSimulationTool/mixSimulationConstraintTable.action";
import MixSkeletons from "../../MixSkeletons/MixSkeletons";
import { fetchMixSimulationFiltersRequest, getGeoFilterData, resetGeoLevelFilter } from "../../../../store/actions/MixSimulationTool/mixSimulatorFilter.action";
import { dispatchState, fetchApi } from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import { toast } from "../../../../store/actions/toast.action";
import { resetMixConstrainCsvDataOfCapacityConstraintAndOperativeComplexity } from "../../../../store/actions/MixSimulationTool/mixUserConstraints.action";
import { API_URL } from "../../../../store/actions/constants";

const Item = styled(Paper)(({ theme }) => ({

  textAlign: "center",
  color: "#08509",
}));

const GridItemWithOutline = styled(Grid)(({ theme }) => ({
  border: "1px solid #08509",
  height: "33px",
}));



export default function Goals() {
  
  const allFilterData = useSelector((state: any) => state.mixSimulationAllFilter.selectedFilters);
  const selectedGoalState = useSelector((state: any) => state.mixSimulationUserInput.selectedGoal);
  const isEditMode = useSelector((state: any) => state.mixSimulationAllFilter.isEditMode)
  const dispatch = useDispatch()
  const [selectedGoal, setSelectedGoal] = useState(selectedGoalState);


  const makeGeoFilterCall =(goal) =>{
    const payloadOfDynamicFilter = {

      "availableDynamicFilters": [
        "level",
        "sub_chnl",
        "geo_level",
        "store_segment"
      ],
      "availableDynamicFiltersSelectedValues": {
        "level":[],
        "sub_chnl": [],
        "geo_level": [],
        "store_segment": []
      },
      "overallFiltersSelectedValues": {
        "country": allFilterData.country,
        "time_period": allFilterData.period,
        "business_unit": allFilterData.businessUnit,
        objective_function: [
          goal
        ],
      },
      "toolName": "mix_optimizer",
      "pageName": "mix_optimizer"
    };

    let dispatchAction: dispatchState = {
      loaderStateFetch: fetchMixSimulationFiltersRequest()
    }
    dispatch(fetchApi(payloadOfDynamicFilter,API_URL.MIXSIMULATION.MIX_OPTIMIZER_DYNAMIC_FILTER,dispatchAction))
    .then(res => {
      dispatch(getGeoFilterData(res.data))
    })
    .catch(err => {
      dispatch(toast('Something Went Wrong!', true, 2000, 'error'));
    })
  }
  useEffect(()=>{
    setSelectedGoal((prevGoal) => (selectedGoalState))
    console.log("selectedGoal",selectedGoal)
  },[selectedGoalState])
  const handleButtonClick = (goal) => {
    setSelectedGoal((prevGoal) => {

      if (prevGoal != goal) {
        
        // let payload = {
        //   uid: "",
        //   scenarioName: "",
        //   overallFilters: {
        //     country: allFilterData.country[0],
        //     dataTimePeriod: allFilterData.period[0],
        //     businessUnit: allFilterData.businessUnit[0]
        //   },
        //   geoFilters: {
        //     channel: allFilterData.channel,
        //     region: allFilterData.region,
        //     storeSegment: allFilterData.storeSegment
        //   },
        //   goal: GoalsMapper[goal],
        //   flag: false
        // }
        dispatch(resetGeoLevelFilter());
        dispatch(resetMixConstrainCsvDataOfCapacityConstraintAndOperativeComplexity())
        makeGeoFilterCall(goal);
       // dispatch(saveMixSimulationScenario(payload, 'saveMixScenario'))
        let tablePayload = {
          overallFilters: {
            country: allFilterData.country[0],
            dataTimePeriod: allFilterData.period[0],
            businessUnit: allFilterData.businessUnit[0]
          },
          geoFilters: {
            channel: [],
            region: [],
            storeSegment: []
          },
          goal: goal
        }
        dispatch(setMixSimulationGoal( goal))
        // dispatch(fetchMixSimulationConstraintTableData('8F86A118-983B-47A0-BD54-B7CD953E4314', 'getMixContraintTableData'))
      }else{
        dispatch(resetGeoLevelFilter())
      }

      return goal

    });
  };
  
  return (
    <Grid container style={{pointerEvents: isEditMode?'none':'auto', opacity:isEditMode?'0.7':'1'}} >
      <Grid style={{ marginTop: "10px",padding:"0px 16px" }}>
        <Typography
          style={{
            fontFamily: "Mulish, sans-serif",
            fontSize: "16px",
            fontWeight: "800",
            lineHeight:"20px",
            
          }}
        >
          Goal
        </Typography>
        <Grid container style={{ marginTop: 0 }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item>
          {allFilterData.businessUnit.length==0 ? (
         <MixSkeletons type={'goals'}/>
      ) : (
        <Button   
        variant="outlined"
        style={{
          backgroundColor: selectedGoal === "Revenue/KG" ? "#08509A" : "transparent",
          color: selectedGoal === "Revenue/KG" ? "#ffffff" : "#08509A",
        }}
        onClick={() => handleButtonClick("Revenue/KG")}
      >
        Maximum Revenue Per Kg
      </Button>
      )} 
          </Grid>
          <Grid item>
          {allFilterData.businessUnit.length==0 ? (
        <MixSkeletons type={'goals'}/>
      ) : (
        <Button
        variant="outlined"
        style={{
          backgroundColor: selectedGoal === "Profit/KG" ? "#08509A" : "transparent",
          color: selectedGoal === "Profit/KG" ? "#ffffff" : "#08509A",
        }}
        onClick={() => handleButtonClick("Profit/KG")}
      >
        Maximum Profit Margin
      </Button>
      )}
           
          </Grid>
          <Grid item>
          {allFilterData.businessUnit.length==0 ? (
        <MixSkeletons type={'goals'}/>
      ) : (
        <Button
        variant="outlined"
        style={{
          backgroundColor: selectedGoal === "Market/Share" ? "#08509A" : "transparent",
          color: selectedGoal === "Market/Share" ? "#ffffff" : "#08509A",
        }}
        onClick={() => handleButtonClick("Market/Share")}
      >
        Maximum Market Share
      </Button>
      )}
           
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
  }


