import _ from "lodash";
import {
  FETCH_EXECUTION_SALES_DATA_FAILURE,
  FETCH_EXECUTION_SALES_DATA_SUCCESS,
  FETCH_EXECUTION_OPPORTUNITIES_FAILURE,
  FETCH_EXECUTION_OPPORTUNITIES_SUCCESS,
  FETCH_EXECUTION_OVERVIEW_SCORE_FAILURE,
  FETCH_EXECUTION_OVERVIEW_OPPORTUNITIES_REQUEST,
  FETCH_EXECUTION_OVERVIEW_SCORE_REQUEST,
  FETCH_EXECUTION_OVERVIEW_SALES_DATA_REQUEST,
  FETCH_EXECUTION_OVERVIEW_SCORE_SUCCESS,
  LOADER_STATE_FOR_RUN_SIMULATION,
  RESET_STATE_FOR_EXECUTION_OVERVIEW_SCORE,
} from "../../actions/constants";

const initialState = {
  overviewscoredata: {
    overallScore: "0",
    assortment: {
      overallScore: "0",
      status: "0",
      mustHaveSKUsDistribution: "0",
      goodToHaveSKUsDistribution: "0",
    },
    mix: {
      overallScore: "0",
      status: "0",
      volumeMix: "0",
      netRevenue: "0",
      nopbt: "0",
    },
    price: {
      overallScore: "0",
      status: "0",
      netPriceRealisation: "0",
      priceChangeVsRestOfMarket: "0",
    },
    shelfAndMerchandising: {
      overallScore: "0",
      status: "0",
      shelfSpace: "0",
      exhibition: "0",
    },
  },
  mixopportunities: {},
  assortmentopportunities: {},
  salesData: {
    netRevenue: [
      {
        netRevenueValue: "0",
        percentageChange: "0",
      },
    ],
    noPBT: [
      {
        noPBTValue: "0",
        percentageChange: "0",
      },
    ],
    volume: [
      {
        volume: "0",
        percentageChange: "0",
      },
    ],
  },
  loading: {
    overviewscore: true,
    opportunities: true,
    salesData: true,
  },
  error: null,
  loadingStateForAll: false,
};

const overviewscore = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATE_FOR_EXECUTION_OVERVIEW_SCORE:
      return initialState;
    case FETCH_EXECUTION_OVERVIEW_SCORE_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          overviewscore: true,
        },
        loadingStateForAll: true,
        error: null,
      };
    case FETCH_EXECUTION_OVERVIEW_OPPORTUNITIES_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          opportunities: true,
        },
        error: null,
      };
    case FETCH_EXECUTION_OVERVIEW_SALES_DATA_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          salesData: true,
        },
        error: null,
      };
    case FETCH_EXECUTION_OVERVIEW_SCORE_SUCCESS:
      return {
        ...state,
        overviewscoredata: action.payload.data,
        loading: {
          ...state.loading,
          overviewscore: false,
        },
        error: null,
      };
    case FETCH_EXECUTION_OVERVIEW_SCORE_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          overviewscore: false,
        },
        error: action.payload,
      };
    case FETCH_EXECUTION_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        mixopportunities: _.fromPairs(
          _.take(
            Object.entries(action.payload.data.opportunities.mixOpportunities),
            10
          )
        ),
        assortmentopportunities: _.fromPairs(
          _.take(
            Object.entries(
              action.payload.data.opportunities.assortmentOpportunities
            ),
            10
          )
        ),
        loading: {
          ...state.loading,
          opportunities: false,
        },
        error: null,
      };
    case FETCH_EXECUTION_OPPORTUNITIES_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          opportunities: false,
        },
        error: action.payload,
      };
    case FETCH_EXECUTION_SALES_DATA_SUCCESS:
      return {
        ...state,
        salesData: action.payload.data,
        loading: {
          ...state.loading,
          salesData: false,
        },
        error: null,
      };
    case FETCH_EXECUTION_SALES_DATA_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          salesData: false,
        },
        error: action.payload,
      };
    case LOADER_STATE_FOR_RUN_SIMULATION:
      return {
        ...state,
        loadingStateForAll: action.payload,
      };
    default:
      return state;
  }
};
export default overviewscore;
