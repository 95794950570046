import { multiplyValuesBy100 } from "../../../util/helper";
import {
  FETCH_ASSORTMENT_PRODUCT_FILTERS_REQUEST,
  FETCH_ASSORTMENT_PRODUCT_FILTERS_SUCCESS,
  FETCH_ASSORTMENT_PRODUCT_FILTERS_FAILURE,
  FETCH_ASSORTMENT_GEO_FILTERS_REQUEST,
  FETCH_ASSORTMENT_GEO_FILTERS_SUCCESS,
  FETCH_ASSORTMENT_GEO_FILTERS_FAILURE,
  FETCH_ASSORTMENT_QUADRANT_FILTERS_REQUEST,
  FETCH_ASSORTMENT_QUADRANT_FILTERS_SUCCESS,
  FETCH_ASSORTMENT_QUADRANT_FILTERS_FAILURE,
  FETCH_ASSORTMENT_RUN_SIMULATION_DATA_REQUEST,
  FETCH_ASSORTMENT_RUN_SIMULATION_DATA_SUCCESS,
  FETCH_ASSORTMENT_RUN_SIMULATION_DATA_FAILURE,
  FETCH_ASSORTMENT_PENETRATION_AVG_DATA_REQUEST,
  FETCH_ASSORTMENT_PENETRATION_AVG_DATA_SUCCESS,
  FETCH_ASSORTMENT_PENETRATION_AVG_DATA_FAILURE,
  FETCH_ASSORTMENT_MANUAL_CSV_DATA_REQUEST,
  FETCH_ASSORTMENT_MANUAL_CSV_DATA_SUCCESS,
  FETCH_ASSORTMENT_MANUAL_CSV_DATA_FAILURE,
  FETCH_ASSORTMENT_UPLOAD_CSV_DATA_REQUEST,
  FETCH_ASSORTMENT_UPLOAD_CSV_DATA_SUCCESS,
  FETCH_ASSORTMENT_UPLOAD_CSV_DATA_FAILURE,
  GET_SCENARIO_DETAILS,
  LOADER_FOR_SCENARIO_DETAILS,
  VIEW_EDIT_SCENARIO,
  LOADER_FOR_SCENARIO_SAVE,
  SET_DEFAULT_PRODUCT_FILTERS,
  SET_DEFAULT_GEO_FILTERS,
  SET_SCENARIO_NAME,
  SET_PENETRATION_LIMIT_LEVEL,
  SET_PENETRATION_VIEW_MODE,
  SET_GEO_FILTER_VISIBILITY,
  SET_DEFAULT_QUADRANT_FILTERS,
  SET_WALK_RATE,
  RESET_STATE_FOR_ASSORTMENT_FILTERS
} from "../../actions/constants";

// Initial state
const initialState = {
  scenarioName: "",
  country: null,
  businessUnit: null,
  dateTimePeriod: null,
  channel: null,
  region: null,
  storeSegment: null,
  xAxis: null,
  yAxis: null,
  bubbleSize: null,
  uid: null,
  downloadCSV: null,
  isCSVUploaded: false,
  loaderForProductFilters: false,
  loaderForGeoFilters: false,
  loaderForQuadrantFilters: false,
  loaderForRunSimulation: false,
  loaderForAvgPenetration: false,
  loaderForCsvDownload: false,
  loaderForCsvUpload: false,
  error: null,
  allWeights: {
    netRevenueIncremental: 40,
    netIncrementalNopbt: 25,
    nopbtIncremental: 0,
    salesGrowth: 15,
    marketGrowth: 10,
    marketSize: 10,
    inventoryTurn: 0,
    capacityConstraint: 0,
    operativeComplexity: 0,
    mustHaveSku: 0,
    goodToHaveSku: 0,
  },
  penetrationLimit: {
    minimumPenetrationForSku: 3,
    chooseLevel: "storeSegment",
  },
  penetrationTarget: {
    mustHaveSku: 0,
    goodToHaveSku: 0,
  },
  penetrationTargetAverage: {
    goodToHaveSkuCurrentAverage: 0,
    mustHaveSkuCurrentAverage: 0,
  },
  loaderForSave: false,
  isViewEdit: false,
  defaultProductFilters: {
    country: "",
    businessUnit: "",
    dateTimePeriod: "",
  },
  defaultGeoFilters: {
    channel: "",
    region: "",
    storeSegment: "",
  },
  defaultQuadrantFilters: {
    xAxis: "",
    yAxis: "",
    bubbleSize: "",
  },
  penetrationViewMode: false,
  isGeoFiltersVisible: false,
  includeWalkRate: "ON"
};

const AssortmentFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATE_FOR_ASSORTMENT_FILTERS:
      return initialState;
    case FETCH_ASSORTMENT_PRODUCT_FILTERS_REQUEST:
      return {
        ...state,
        error: null,
        loaderForProductFilters: true,
      };
    case FETCH_ASSORTMENT_GEO_FILTERS_REQUEST:
      return {
        ...state,
        error: null,
        loaderForGeoFilters: true,
      };
    case FETCH_ASSORTMENT_QUADRANT_FILTERS_REQUEST:
      return {
        ...state,
        error: null,
        loaderForQuadrantFilters: true,
      };
    case FETCH_ASSORTMENT_RUN_SIMULATION_DATA_REQUEST:
      return {
        ...state,
        error: null,
        loaderForRunSimulation: true,
      };
    case FETCH_ASSORTMENT_PENETRATION_AVG_DATA_REQUEST:
      return {
        ...state,
        error: null,
        loaderForAvgPenetration: true,
      };
    case FETCH_ASSORTMENT_MANUAL_CSV_DATA_REQUEST:
      return {
        ...state,
        error: null,
        loaderForCsvDownload: true,
      };
    case FETCH_ASSORTMENT_UPLOAD_CSV_DATA_REQUEST:
      return {
        ...state,
        error: null,
        loaderForCsvUpload: true,
      };
    case FETCH_ASSORTMENT_PRODUCT_FILTERS_SUCCESS:
      return {
        ...state,
        businessUnit: action.payload.businessUnit,
        country: action.payload.country,
        dateTimePeriod: action.payload.dateTimePeriod,
        loaderForProductFilters: false,
      };
    case FETCH_ASSORTMENT_GEO_FILTERS_SUCCESS:
      return {
        ...state,
        channel: action.payload.channel,
        region: action.payload.region,
        storeSegment: action.payload.storeSegment,
        loaderForGeoFilters: false,
      };
    case FETCH_ASSORTMENT_QUADRANT_FILTERS_SUCCESS:
      return {
        ...state,
        xAxis: action.payload.xAxis,
        yAxis: action.payload.yAxis,
        bubbleSize: action.payload.bubbleSize,
        loaderForQuadrantFilters: false,
      };
    case FETCH_ASSORTMENT_RUN_SIMULATION_DATA_SUCCESS:
      return {
        ...state,
        loaderForRunSimulation: false,
        uid: action.payload.uid,
      };
    case FETCH_ASSORTMENT_PENETRATION_AVG_DATA_SUCCESS:
      return {
        ...state,
        loaderForAvgPenetration: false,
        penetrationTargetAverage: action.payload,
      };
    case FETCH_ASSORTMENT_MANUAL_CSV_DATA_SUCCESS:
      return {
        ...state,
        loaderForCsvDownload: false,
      };
    case FETCH_ASSORTMENT_UPLOAD_CSV_DATA_SUCCESS:
      return {
        ...state,
        isCSVUploaded: action.payload,
        loaderForCsvUpload: false,
      };
    case FETCH_ASSORTMENT_PRODUCT_FILTERS_FAILURE:
      return {
        ...state,
        loaderForProductFilters: false,
        error: action.payload,
      };
    case FETCH_ASSORTMENT_GEO_FILTERS_FAILURE:
      return {
        ...state,
        loaderForGeoFilters: false,
        error: action.payload,
      };
    case FETCH_ASSORTMENT_QUADRANT_FILTERS_FAILURE:
      return {
        ...state,
        loaderForQuadrantFilters: false,
        error: action.payload,
      };
    case FETCH_ASSORTMENT_RUN_SIMULATION_DATA_FAILURE:
      return {
        ...state,
        loaderForRunSimulation: false,
        error: action.payload,
      };
    case FETCH_ASSORTMENT_PENETRATION_AVG_DATA_FAILURE:
      return {
        ...state,
        loaderForAvgPenetration: false,
        error: action.payload,
      };
    case FETCH_ASSORTMENT_MANUAL_CSV_DATA_FAILURE:
      return {
        ...state,
        loaderForCsvDownload: false,
        error: action.payload,
      };
    case FETCH_ASSORTMENT_UPLOAD_CSV_DATA_FAILURE:
      return {
        ...state,
        loaderForCsvUpload: false,
        error: action.payload,
      };
    case GET_SCENARIO_DETAILS:
      return {
        ...state,
        allWeights: multiplyValuesBy100(action.payload.weights),
        uid: action.payload.uid,
        defaultProductFilters: {
          country: action.payload.country,
          businessUnit: action.payload.businessUnit,
          dateTimePeriod: action.payload.dateTimePeriod,
        },
        penetrationLimit: {
          minimumPenetrationForSku:
            Number(action.payload.minimumPenetrationForSku) * 100,
          chooseLevel: action.payload.chooseLevel,
        },
        includeWalkRate: action.payload.includeWalkRate
      };
    case SET_DEFAULT_PRODUCT_FILTERS:
      return {
        ...state,
        defaultProductFilters: {
          country: action.payload.country,
          businessUnit: action.payload.businessUnit,
          dateTimePeriod: action.payload.dateTimePeriod,
        },
        loaderForProductFilters: false,
      };
    case SET_GEO_FILTER_VISIBILITY:
      return {
        ...state,
        isGeoFiltersVisible: action.payload,
      };
    case SET_DEFAULT_GEO_FILTERS:
      return {
        ...state,
        defaultGeoFilters: {
          channel: action.payload.channel,
          region: action.payload.region,
          storeSegment: action.payload.storeSegment,
        },
        loaderForGeoFilters: false,
      };
      case SET_DEFAULT_QUADRANT_FILTERS:
        return {
          ...state,
          defaultQuadrantFilters: {
            xAxis: action.payload.xAxis,
            yAxis: action.payload.yAxis,
            bubbleSize: action.payload.bubbleSize,
          },
        };
    case SET_SCENARIO_NAME:
      return {
        ...state,
        scenarioName: action.payload,
      };
    case SET_PENETRATION_LIMIT_LEVEL:
      return {
        ...state,
        penetrationLimit: {
          ...state.penetrationLimit,
          chooseLevel: action.payload,
        },
      };
    case SET_PENETRATION_VIEW_MODE:
      return {
        ...state,
        penetrationViewMode: action.payload,
      };
    case VIEW_EDIT_SCENARIO:
      if (action.payload) {
        return {
          ...state,
          isViewEdit: true,
        };
      } else {
        return {
          ...state,
          uid: null,
          isViewEdit: false,
          allWeights: {
            netRevenueIncremental: 40,
            netIncrementalNopbt: 25,
            nopbtIncremental: 0,
            salesGrowth: 15,
            marketGrowth: 10,
            marketSize: 10,
            inventoryTurn: 0,
            capacityConstraint: 0,
            operativeComplexity: 0,
            mustHaveSku: 0,
            goodToHaveSku: 0,
          },
          penetrationLimit: {
            minimumPenetrationForSku: 3,
            chooseLevel: "storeSegment",
          },
        };
      }
    case LOADER_FOR_SCENARIO_DETAILS:
      return {
        ...state,
      };
    case LOADER_FOR_SCENARIO_SAVE:
      return {
        ...state,
        loaderForRunSimulation: action.payload,
      };
      case SET_WALK_RATE:
        return {
          ...state,
          includeWalkRate: action.payload,
        };
    default:
      return state;
  }
};

export default AssortmentFiltersReducer;
