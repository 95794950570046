import {
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
} from "../../actions/AlertAndNotification/constants";

let DismissNotificationInitialState = {
  data: null,
  loading: true,
  error: null,
};

const DeleteNotification = (
  state = DismissNotificationInitialState,
  action
) => {
  switch (action.type) {
    case DELETE_NOTIFICATION:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_NOTIFICATION_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default DeleteNotification;
