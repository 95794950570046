import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Skeleton } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandIcon } from './MixFiltersAccordian.Styled';
import { BlackTitle } from '../../styles/Common.Styled';
import { AccordianSubTitle } from '../AssortmentFiltersAccordian/AssortmentFiltersAccordian.Styled';
const useStyles = makeStyles((theme) => ({
  m_0: { margin: '0px !important', boxShadow: 'none !important' },
}));

const MixFiltersAccordian: React.FC<{ title?; children; showSkeleton?: boolean,disabled?:boolean,defaultExpanded?:boolean,subTitle? }> = ({ title, children, showSkeleton,subTitle = false,disabled,defaultExpanded }) => {
  const classes = useStyles();
  return (
    <Accordion
      data-testid='accordion'
      className={classes.m_0}
      key={`${title}-accordion`}
      sx={{ '&:before': { backgroundColor: '#F1F5FE', opacity: 0.1 } }}
      disabled={disabled || false}
      defaultExpanded={defaultExpanded || false}
    >
      <AccordionSummary sx={{ backgroundColor: '#F1F5FE' }} expandIcon={<ExpandIcon />}>
        {showSkeleton ? (
          <Skeleton variant='rectangular' width={210} height={40} />
        ) : (
          <><BlackTitle className={'f-w-5'}>
              <span style={{ fontFamily: 'Mulish,sans-serif', fontWeight: '800', fontSize: '16px', lineHeight: '20.08px', letterSpacing: '0.4 px' }} dangerouslySetInnerHTML={{ __html: title }}></span>
            </BlackTitle><AccordianSubTitle style={{ display:'flex',justifyContent:'center', alignItems:'center', marginLeft:'10px' }}>{subTitle}</AccordianSubTitle></>
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: '#F1F5FE' }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default MixFiltersAccordian;
