import React, { useState, useEffect } from "react";
import {
  ProgressBarContainer,
  ProgressBar,
  ProgressHeader,
} from "./ProgressBar.Styled";
import { useSelector } from "react-redux";

interface ProgressBarProps {
  data: any;
  header: string;
}

const DynamicProgressBar: React.FC<ProgressBarProps> = ({
  data,
  header,
}) => {

  const {sopMaxValue} = useSelector(
    (state) => state.assortmentDashboardReducer
  );
  return (
    <ProgressBarContainer style={{ paddingLeft: "10px" }}>
      {header === "sopRevenueMix" && (
        <ProgressBar
          style={{
            color: "black",
            textAlign: "center",
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",
            display: "flex",
            alignItems: "center",
            height: "28px",
          }}
          width={
            data > 0
              ? Math.floor((data / sopMaxValue["sopRevenueMix"]) * 100 * 1) / 1
              : 0
          }
        >
          <span style={{ marginLeft: "45px" }}>
            {Math.floor(data * 10) / 10}%
          </span>
        </ProgressBar>
      )}
      {header === "sopNopbtMix" && (
        <ProgressHeader
          style={{
            color: "black",
            textAlign: "center",
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",
            display: "flex",
            alignItems: "center",
            height: "28px",
          }}
          width={
            data > 0
              ? Math.floor((data / sopMaxValue["sopNopbtMix"]) * 100 * 1) / 1
              : 0
          }
        >
          <span style={{ marginLeft: "45px" }}>
            {Math.floor(data * 10) / 10}%
          </span>
        </ProgressHeader>
      )}
    </ProgressBarContainer>
  );
};

export default DynamicProgressBar;