import { createStyles } from "@mui/material/styles";

const listTableStyles = createStyles({
  grid: {},
  typography: {},
  tableContainer: {
    width: "1377px",
    height: "718px",
    overflow:"scroll",
    color:"#F1F5FE",
  },
  
  table: {
    color:"#F1F5FE",
    
    width:'400px',
    // minWidth: "600px",
    overflow:"scroll",
    scrollbarWidth: 'none', 
    '&::-webkit-scrollbar': {
      display: 'none', 
    },
  },
  columnWidth: "100px",
  columnHeight: "20px"
});

export default listTableStyles;
