import { Avatar, Card, CardContent, Divider, FormControl, Grid, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, Switch, Tooltip, Typography, withStyles } from '@mui/material';
import React, { useEffect, useState } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    CardGrid,
    SalesCardGrid,
    SubTitle,
    SalesCardTitle,
    SalesCardValue,
    Title,
    StyledKPIheading,
    SalesCardPercentageDecrease,
    SalesCardPercentageIncrease,
    StyleTotalScore
} from '../ExecutionCommon.Styled';
import OverviewCard, { HeadingMapper } from './OverviewCard/OverviewCard';
import ImageIcon from '@mui/icons-material/Image';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useSelector } from 'react-redux';
import { TypographyTheme } from '../CommonComponents/commonStyled';
import Skeletons from '../../Skeletons/Skeletons';
import zIndex from '@mui/material/styles/zIndex';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const OverViewMapper = {
    shelfAndMerchandising: 'Shelf & Merchandising',
    mustHaveSKUsDistribution: 'Must-Have SKUs distribution',
    goodToHaveSKUsDistribution: 'Good-to-Have SKUs distribution',
    volumeMix: 'Volume Mix',
    netRevenue: 'Net Revenue',
    nopbt: 'Profit',
    netPriceRealisation: 'Net Price Realisation',
    priceChangeVsRestOfMarket: 'Price Change vs Rest of Market',
    realInvestmentVsTargetInvestment: 'Price change vs Inflation',
    shelfSpace: 'Shelf Space',
    exhibition: 'Exhibition',
    priceChangeVsInflation: 'Price change vs Inflation'

}

const SalesMapper = {
    profit: 'Profit % of NR',
    netRevenue: 'net Revenue',
    volume: 'volume'
}


const OverviewComponent = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:960px)');
    const isLargeScreen = useMediaQuery('(min-width:961px)');
    // console.log('isSmallScreen',isSmallScreen)
    // console.log('isMediumScreen',isMediumScreen)
    // console.log('isLargeScreen',isLargeScreen)
    const containerHeight = isSmallScreen ? '800px' : (isMediumScreen ? '700px' : '540px');
    const [isChecked, setChecked] = useState(false)
    const assortmentopportunities = useSelector((state: any) => state.overviewscore.assortmentopportunities);
    const mixopportunities = useSelector((state: any) => state.overviewscore.mixopportunities)
    const overviewScoreDataFromState = useSelector((state: any) => state.overviewscore.overviewscoredata);
    const salesData = useSelector((state: any) => state.overviewscore.salesData);
    const percentage = Math.round(overviewScoreDataFromState['overallScore']*10)/10;
    const overviewscoreloading = useSelector((state: any) => state.overviewscore.loading);
    const [selectedOpportunitiesOptions, setSelectedOpportunitiesOptions] = useState('assortment')
    const opportunitiesOptions = [{
        name: 'Assortment',
        value: 'assortment'
    },
    {
        name: 'Mix',
        value: 'mix'
    },
    ]

    const [opportunitiesData, setOpportunitiesData] = useState(assortmentopportunities)


    useEffect(() => {
        isChecked ?
            setOpportunitiesData(mixopportunities) :
            setOpportunitiesData(assortmentopportunities)

    }, [assortmentopportunities, mixopportunities])
    const transformStateToOverviewData = (data: any) => {
        // console.log("transformStateToOverviewData", data)
        let keys = Object.keys(data).filter((ele) => ele !== 'overallScore' && ele !== 'currentAverage')
        let transformedData: any = []
        keys.map((key) => {
            let title = key.toUpperCase();
            let score = data[key]['overallScore'];
            let status = data[key]['status'];
            let KPIKeys = Object.keys(data[key]).filter((el) => el != 'overallScore' && el != 'status');
            // console.log()
            let KPI = KPIKeys.map((kpiKey) => {
                return {
                    [OverViewMapper[kpiKey]]: Number(data[key][kpiKey]).toFixed(2)
                }
            })
            // console.log(KPI)
            transformedData.push({ 'title': title, 'score': score, 'status': status, 'kpi': KPI });
        })
        return transformedData
    }
    const formatNumber = (value, decimalPoints) => {
        let absValue = Math.abs(value);
        const suffixes = ["", "K", "M", "B", "T"];
        let suffixIndex = 0;

        while (absValue >= 1000 && suffixIndex < suffixes.length - 1) {
            absValue /= 1000;
            suffixIndex++;
        }
        const formattedValue = value >= 0 ? absValue : -absValue;
        const suffix = suffixes[suffixIndex];

        return `${formattedValue.toFixed(decimalPoints)} ${suffix}`;
    }

    const transformSalesData: any = (data: any) => {
        let keys = Object.keys(data);
        // console.log("keystransformSalesData",keys);
        let transformedValues = keys.map((key) => {
            let name = SalesMapper[key];
            let value = Object.values(data[key][0])[0]
            let percentage = Object.values(data[key][0])[1]

            return { name: name, value: value, percentage: percentage }
        })

        return transformedValues;
    }
    const handleOpportunitiesChange = (event) => {
        setSelectedOpportunitiesOptions(event.target.value);
        if (event.target.value == 'assortment') {
            setOpportunitiesData(assortmentopportunities)
        } else {
            setOpportunitiesData(mixopportunities)

        }
        // console.log("opportunitiesData", opportunitiesData)
    }
    const salesCardData = transformSalesData(salesData)
    const overviewScoreData = transformStateToOverviewData(overviewScoreDataFromState)
    // console.log("overviewScoreData",overviewScoreData)

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <TypographyTheme>Overview</TypographyTheme>
                </div>
                <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                    <Grid item>
                        <TypographyTheme variant="body2">SOP : </TypographyTheme>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                            <Select
                                labelId="select-small-label"
                                id="select-small"
                                value={selectedOpportunitiesOptions}
                                label=''
                                onChange={handleOpportunitiesChange}
                            >{
                                    opportunitiesOptions.map((element) => {
                                        return <MenuItem value={element.value}>{element.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

            </div>
            <Grid container spacing={2} style={{ height: containerHeight }} >
                <Grid item xs={9} style={{ height: '100%' }}>
                    <Card style={{ height: '100%' }}>
                        {overviewscoreloading.overviewscore ? <Skeletons type={'overviewscore'} /> :
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                        <Card sx={{ border: "none", boxShadow: "none", zIndex: "2", padding: '0px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} ><SubTitle>Execution Score</SubTitle><span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {console.log("overviewScoreData",overviewScoreData)}
                                                {/* <Tooltip title={
                                                    <div>
                                                        The following weightages are <br/>
                                                        used to calculate overall<br/>
                                                        execution score.
                                                        <div style={{height:'10px'}}></div>
                                                        {overviewScoreData.map((item:any)=>{
                                                            return <div> {HeadingMapper[item.title] + " : " + formatNumber(item.score,1)}<div> </div> </div>
                                                        })}
                                                    </div>
                                                } placement="top" componentsProps={{
                                                    tooltip: {
                                                      sx: {
                                                        color: "black",
                                                        backgroundColor: "white",
                                                        fontSize: "12px",
                                                        padding:'10px',
                                                        opacity:'0.5',
                                                        borderRadius:'10px'
                                                      }
                                                    },
                                                    arrow:{
                                                        sx:{
                                                            color:"white",
                                                            
                                                        }
                                                    }
                                                  }} arrow>
                                                <InfoOutlinedIcon>
                                                    
                                                </InfoOutlinedIcon>
                                                </Tooltip> */}
                                                </span></div>
                                            <CardContent sx={{ padding: '0px' }}>
                                                <div style={{
                                                    position: 'relative',
                                                    width: '100%',
                                                    height: '100%'
                                                }}>

                                                    <CircularProgressbar styles={{
                                                        path: {
                                                            stroke: 'rgba(47, 85, 151, 1)',
                                                        },
                                                        text: {
                                                            fill: '#333',
                                                            fontSize: '18px',
                                                        },
                                                    }}
                                                        value={percentage} text={`${percentage}`} />
                                                    <div style={{ zIndex: '100', position: 'absolute', top: '56%', left: '80%', transform: 'translate(-50%, -80%)' }}><StyleTotalScore>/100</StyleTotalScore></div>
                                                    <div style={{ zIndex: '100', position: 'absolute', top: '65%', left: '50%', transform: 'translate(-50%, -50%)' }}><StyledKPIheading>Overall Score</StyledKPIheading></div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={8} lg={9} xl={10} >
                                        <Card sx={{ border: "none", boxShadow: "none" }}>
                                            <CardContent>
                                                <CardGrid>
                                                    {overviewScoreData.map((item: any, index: number) => {
                                                        return <OverviewCard key={index} data={item} />
                                                    })}
                                                </CardGrid>

                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </CardContent>}
                    </Card>
                </Grid>
                <Grid item xs={3} style={{ height: '100%' }}>
                    <Card style={{ height: '100%', overflowY: 'auto' }} >
                        {overviewscoreloading.opportunities ? <Skeletons type={'opportunities'} /> : <CardContent>
                            <SubTitle>Size of Prize (Avg. Monthly)</SubTitle>
                            {
                                Object.keys(opportunitiesData).map((item, index) => {
                                    return <>
                                        <ListItem key={`${index}-${item}-${index}`}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ImageIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                                <ListItemText primaryTypographyProps={{
                                                    color: '#7a7a7a',
                                                    fontWeight: 'medium',
                                                    variant: 'body2',
                                                }}
                                                    secondaryTypographyProps={{
                                                        color: '#0f2744',
                                                        variant: 'body2'
                                                    }} primary={item.toString().toUpperCase()} secondary={<>SOP Revenue : <span style={{ color: '#335899', whiteSpace:"nowrap" }}>{formatNumber(opportunitiesData[item]['sop'], 1)}</span></>} />
                                                <Typography sx={{ color: '#0f2744' }} variant="body2">{`SOP Profit: ${formatNumber(opportunitiesData[item]['nopbt'], 1)}`}</Typography>
                                            </div>
                                            {/* <ListItemText  secondary={`NOPBT: ${item['nopbt']}`} /> */}
                                        </ListItem>
                                        <Divider variant="inset" />
                                        {/* `SOP: ${formatNumber(opportunitiesData[item]['sop'])}` */}
                                    </>
                                })
                            }
                        </CardContent>}
                    </Card>
                </Grid>
            </Grid>

            <div>
                {overviewscoreloading.salesData ? <Skeletons type={'salescard'} /> : <SalesCardGrid>
                    {salesCardData.map((item) => {
                        return <>
                            <Card>
                                <CardContent>

                                    <SalesCardTitle>{item.name}</SalesCardTitle>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <SalesCardValue>{item.name == 'Profit % of NR' ? formatNumber(item.value, 2) + "%" : formatNumber(item.value, 2)}</SalesCardValue>
                                        {
                                            item.percentage.includes("-") ?
                                                <SalesCardPercentageDecrease>{Math.round(item.percentage * 10) / 10}%</SalesCardPercentageDecrease>
                                                : <SalesCardPercentageIncrease>{"+" + Math.round(item.percentage * 10) / 10}%</SalesCardPercentageIncrease>
                                        }
                                    </div>
                                </CardContent>
                            </Card>
                        </>
                    })
                    }
                </SalesCardGrid>}
            </div>
        </>
    )
}

export default OverviewComponent
