import {
  HOME,
  COMING_SOON,
  PRICE_ARCHITECTURE,
  HISTORIC_PRICE,
  SUPER_MARKET,
  PROFIT_PARABOLA_DASHBOARD,
  API_PREDICTIVE_DASHBOARD,
  SELL_OUT_DASHBOARD,
  ALERT_AND_NOTIFICATION,
  USER_LIST,
  MACRO_ECON,
  REVENUE_MAP,
  ELASTICITY_PRICING_TRACK,
  PRICING_POTENTIAL,
  BRAND_LADDER,
  PRICE_LADDER,
  PACK_ECONOMICS,
  PRICE_SETTING_TOOL,
  PRICE_RANGE_PIANO,
  GEO_XREF,
  PROD_XREF,
  GRAMMAGE_PER_PACK_SIZE,
  TRADE_MANAGEMENT,
  MIX_MANAGEMENT,
  INTEGRATED_STRATEGY,
  DATA_SOURCE_REFRESH,
  USAGE_COUNT_REPORT,
  USAGE_ACTIVITY_REPORT,
} from "../router/CONSTANTS";
import { SideMenuItem } from "../types/common";
import IconDashboard from "@material-ui/icons/Dashboard";
import {
  PriceIcon,
  TradeManagementIcon,
  MixManagementIcon,
  IntegratedtIcon,
  QueryToolsIcon,
  DatabaseIcon,
  SupportTrainingIcon,
  AlertIcon,
  HomeIcon,
  GlobalAnalytics,
  UserGroup,
  APIVsVolume,
  ProfitParabola,
  PriceRange,
  PackEcon,
  PriceLadder,
  BrandLadder,
  RevenueMap,
  Sellout,
  SalesAtPricePoint,
  MacroEcon,
  PriceSettingTool,
  PricingPotential,
  HPCPE,
  EPT,
  SRP,
  Reporting,
  UsageReports,
} from "../assets/icons/sideMenuIcons";

export const sidedrawerItems: SideMenuItem[] = [
  {
    title: "Home",
    key: "home",
    link: HOME,
    Icon: HomeIcon,
    IconActive: "",
    active: true,
    isAccess: true,
  },
  {
    title: "Price Architecture",
    key: "price_architecture",
    link: PRICE_ARCHITECTURE,
    Icon: PriceIcon,
    IconActive: "",
    active: true,
    isAccess: true,
    items: [
      {
        title: "API VS Volume",
        key: "api_vs_volume",
        link: API_PREDICTIVE_DASHBOARD,
        Icon: APIVsVolume,
        IconActive: "",
        active: true,
        isAccess: true,
      },
      {
        title: "Price Range Piano",
        key: "price_range_piano",
        link: PRICE_RANGE_PIANO,
        Icon: PriceRange,
        IconActive: "",
        active: true,
        isAccess: true,
      },
      {
        title: "Pack Economics",
        key: "pack_economics",
        link: PACK_ECONOMICS,
        Icon: PackEcon,
        IconActive: "",
        active: true,
        isAccess: true,
      },
      {
        title: "Price Ladder",
        key: "price_ladder",
        link: PRICE_LADDER,
        Icon: PriceLadder,
        IconActive: "",
        active: true,
        isAccess: true,
      },
      {
        title: "Sell Out Price Tracking",
        key: "sellout_price_tracking",
        link: SELL_OUT_DASHBOARD,
        Icon: Sellout,
        IconActive: "",
        active: true,
        isAccess: true,
      },
      {
        title: "Brand Ladder",
        key: "brand_ladder",
        link: BRAND_LADDER,
        Icon: BrandLadder,
        IconActive: "",
        active: true,
        isAccess: true,
      },
      {
        title: "Revenue Map",
        key: "revenue_map",
        link: REVENUE_MAP,
        Icon: RevenueMap,
        IconActive: "",
        active: true,
        isAccess: true,
      },
    ],
  },
  {
    title: "Trade Management",
    key: "trade_management",
    link: TRADE_MANAGEMENT,
    Icon: TradeManagementIcon,
    IconActive: "",
    active: true,
    isAccess: true,
    items: [
      {
        title: "Sales By Price Point",
        key: "sales_by_price_point",
        link: SUPER_MARKET,
        Icon: SalesAtPricePoint,
        IconActive: "",
        active: true,
        isAccess: true,
      },
      {
        title: "Profit Parabola",
        key: "profit_parabola",
        link: PROFIT_PARABOLA_DASHBOARD,
        Icon: ProfitParabola,
        IconActive: "",
        active: true,
        isAccess: true,
      },
    ],
  },
  {
    title: "Mix Management",
    key: "mix_management",
    link: MIX_MANAGEMENT,
    Icon: MixManagementIcon,
    IconActive: "",
    active: true,
    isAccess: true,
    items: [
    ],
  },
  {
    title: "Integrated Strategy",
    key: "integrated_strategy",
    link: INTEGRATED_STRATEGY,
    Icon: IntegratedtIcon,
    IconActive: "",
    active: true,
    isAccess: true,
    items: [
      {
        title: "Macro Econ ",
        key: "macro_econ",
        link: MACRO_ECON,
        Icon: MacroEcon,
        IconActive: "",
        active: true,
        isAccess: true,
      },
      {
        title: "Price Setting Tool ",
        key: "price_setting_tool",
        link: PRICE_SETTING_TOOL,
        Icon: PriceSettingTool,
        IconActive: "",
        active: true,
        isAccess: true,
      },
      {
        title: "Historic Price and Cross-Price Elasticities",
        key: "hpcpe",
        link: HISTORIC_PRICE,
        Icon: HPCPE,
        IconActive: "",
        active: true,
        isAccess: true,
      },
      {
        title: "Pricing Potential ",
        key: "pricing_potential",
        link: PRICING_POTENTIAL,
        Icon: PricingPotential,
        IconActive: "",
        active: true,
        isAccess: true,
      },
      {
        title: "Elasticity Pricing Track",
        key: "ept",
        link: ELASTICITY_PRICING_TRACK,
        Icon: EPT,
        IconActive: "",
        active: true,
        isAccess: true,
      },
      // {
      //   title: "Connect the Dots:  Combine Saved Scenarios",
      //   link: COMING_SOON,
      //   Icon: IconDashboard,
      //   IconActive: "",
      //   active: true,
      //   isAccess: true,
      // },
      // {
      //   title: "Automated NRR & Mix Tracker",
      //   link: COMING_SOON,
      //   Icon: IconDashboard,
      //   IconActive: "",
      //   active: true,
      //   isAccess: true,
      // },
      // {
      //   title: "Connect the Dots Predictive",
      //   link: COMING_SOON,
      //   Icon: IconDashboard,
      //   IconActive: "",
      //   active: true,
      //   isAccess: true,
      // },
      // {
      //   title: "Price Elasticity Model Tool (Analytics Console)",
      //   link: COMING_SOON,
      //   Icon: IconDashboard,
      //   IconActive: "",
      //   active: true,
      //   isAccess: true,
      // },
      // {
      //   title: "Strategic Optimization",
      //   link: COMING_SOON,
      //   Icon: IconDashboard,
      //   IconActive: "",
      //   active: true,
      //   isAccess: true,
      // },
      // {
      //   title: "Tactical Optimization",
      //   link: COMING_SOON,
      //   Icon: IconDashboard,
      //   IconActive: "",
      //   active: true,
      //   isAccess: true,
      // },
    ],
  },
  {
    title: "Query Tool",
    key: "query_tool",
    link: `${process.env.REACT_APP_QUERY_TOOL_URL}`,
    external: true,
    target: "_blank",
    Icon: QueryToolsIcon,
    IconActive: "",
    active: true,
    isAccess: true,
  },
  {
    title: "Global Analytics Hub",
    key: "global_analytics_hub",
    link: "https://pepsico.sharepoint.com/sites/globalnrm/SitePages/PepsiCo.aspx",
    target: "_blank",
    external: true,
    Icon: GlobalAnalytics,
    IconActive: "",
    active: true,
    isAccess: true,
  },
  // {
  //   title: "Reporting",
  //   key: "usage_report1",
  //   link: REPORTING,
  //   Icon: PriceRange,
  //   IconActive: "",
  //   active: true,
  //   isAccess: true,
  // },
  {
    title: "Reporting",
    key: "usage_count_report",
    link: USAGE_COUNT_REPORT,
    Icon: UsageReports,
    IconActive: "",
    active: true,
    isAccess: true,
    items: [
      {
        title: "Usage Count Report",
        key: "usage_count_report",
        link: USAGE_COUNT_REPORT,
        Icon: PriceRange,
        IconActive: "",
        active: true,
        isAccess: true,
      },
      {
        title: "Usage Activity Report",
        key: "usage_activity_report",
        link: USAGE_ACTIVITY_REPORT,
        Icon: PriceRange,
        IconActive: "",
        active: true,
        isAccess: true,
      },
    ],
  },
  {
    title: "Data Base Update & Data Quality",
    key: "database_update_data_quality",
    link: COMING_SOON,
    Icon: DatabaseIcon,
    IconActive: "",
    active: true,
    isAccess: true,
    items: [
      {
        title: "Geo XREF",
        key: "geo_xref",
        link: GEO_XREF,
        Icon: IconDashboard,
        IconActive: "",
        active: true,
        isAccess: true,
      },
      {
        title: "Prod XREF",
        key: "prod_xref",
        link: PROD_XREF,
        Icon: IconDashboard,
        IconActive: "",
        active: true,
        isAccess: true,
      },
      {
        title: "Grammage Per Pack Size",
        key: "grammage_per_pack_size",
        external: true,
        target: "_blank",
        link: " https://pepsico.sharepoint.com/teams/RevolutioM-2.0/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=lPHUo3&cid=35eb6226%2D6264%2D4c4f%2D8983%2D2dad49dcb8ff&RootFolder=%2Fteams%2FRevolutioM%2D2%2E0%2FShared%20Documents%2FPacksizes%20RevolutioM2%2E0&FolderCTID=0x0120009D145FCF9768244E90E8CAC0926C8B7D&OR=Teams%2DHL&CT=1671007916286&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMjExMzAwNDEwMCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D",
        Icon: IconDashboard,
        IconActive: "",
        active: true,
        isAccess: true,
      },
      {
        title: "Data Source Refresh",
        key: "data_source_refresh",
        link: DATA_SOURCE_REFRESH,
        Icon: IconDashboard,
        IconActive: "",
        active: true,
        isAccess: true,
      },
    ],
  },
];
export const sidedrawerBottomItems = [
  {
    title: "Support and Training",
    key: "support_and_training",
    external: true,
    target: "_blank",
    link: "https://pepsico.sharepoint.com/:f:/r/teams/RevolutioM-2.0/Shared%20Documents/Support%20and%20Training?csf=1&web=1&e=k95oQQ",
    Icon: SupportTrainingIcon,
    IconActive: "",
    active: true,
    isAccess: true,
  },
  {
    title: "System Alert & Notifications",
    key: "system_alerts_notifications",
    link: ALERT_AND_NOTIFICATION,
    Icon: AlertIcon,
    IconActive: "",
    active: true,
    isAccess: true,
  },
  {
    title: "User Management",
    key: "user_management",
    link: USER_LIST,
    Icon: UserGroup,
    IconActive: "",
    active: true,
    isAccess: true,
  },
];
