import {
  styled,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D3D3D3",
    padding: 0,
  },
  color: theme.palette.common.black,
  border: "1px solid #D3D3D3",
  alignContent: "center",
  padding: 0,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledNoBorderEmptyCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px solid #D3D3D3",
  alignContent: "center",
  padding: 0,
  background: '#e8e8e8'
}));

const StyledTableContainer = styled(TableContainer)<any>`
  max-width: ${(props) => props.isSidebarOpen? 'calc(100vw - 355px)' : 'calc(100vw - 190px)'};
`;

export { StyledTableCell, StyledTableRow, StyledNoBorderEmptyCell, StyledTableContainer };
