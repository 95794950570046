const USER_CREATION = "/user-creation";
const HOME = "/home";
const DASHBOARD = "/dashboard";
const PRICE_ARCHITECTURE = "/price-architecture";
const INTEGRATED_STRATEGY = "/integrated-Strategy";
const TRADE_MANAGEMENT = "/trade-management";
const MIX_MANAGEMENT = "/mix-management";
const ALERT_AND_NOTIFICATION = "/system-alert-notifications";
const NOTIFICATION_DETAILS = "/notifications-details";
const USER_SETTINGS = "/user-settings";
const API_PREDICTIVE_DASHBOARD = "/api-vs-volume";
const PROFIT_PARABOLA_DASHBOARD = "/profit-parabola-dashboard";
const SELL_OUT_DASHBOARD = "/sell-out-dashboard";
const SUPER_MARKET = "/sales-by-price-point";
const HISTORIC_PRICE = "/historic-price";
const COMING_SOON = "/coming-soon";
const MACRO_ECON = "/macro-econ-dashboard";
const MMPW_TEST = "/mmpw-test";
const USER_LIST = "/users";
const USER_CREATE = "/user";
const USER_EDIT = "/user/:id";
const REVENUE_MAP = "/revenue-map";
const ELASTICITY_PRICING_TRACK = "/elasticity-pricing-track";
const PRICING_POTENTIAL = "/pricing-potential";
const BRAND_LADDER = "/brand-ladder";
const PACK_ECONOMICS = "/pack-economics";
const PRICE_SETTING_TOOL = "/price-setting-tool";
const PRICE_RANGE_PIANO = "/price-range-piano";
const PRICE_LADDER = "/price-ladder";
const GEO_XREF = "/geo-xref";
const PROD_XREF = "/prod-xref";
const GRAMMAGE_PER_PACK_SIZE = "/grammage-per-pack-size";
const DATA_SOURCE_REFRESH = "/data-source-refresh";
const LOGIN_CALLBACK = "/signin-oidc";
const USAGE_ACTIVITY_REPORT = "/usage-activity-report";
const USAGE_COUNT_REPORT = '/usage-count-report';
const ASSORTMENT_TOOL = "/assortment-tool";
const EXECUTION_TOOL = "/execution-tool"
const SCENARIO_LIBRARY = "/scenario-library";
const MIX_SIMULATION_TOOL = "/mix-simulation-tool";
const MIX_SIMULATION_SCENARIO_LIBRARY = "/mix-simulation-scenarios";
const LOGIN_DP = "/user-login"
export {
  USER_CREATION,
  HOME,
  USER_EDIT,
  DASHBOARD,
  PRICE_ARCHITECTURE,
  INTEGRATED_STRATEGY,
  NOTIFICATION_DETAILS,
  ALERT_AND_NOTIFICATION,
  USER_SETTINGS,
  SUPER_MARKET,
  HISTORIC_PRICE,
  PROFIT_PARABOLA_DASHBOARD,
  API_PREDICTIVE_DASHBOARD,
  SELL_OUT_DASHBOARD,
  MACRO_ECON,
  MMPW_TEST,
  USER_LIST,
  COMING_SOON,
  USER_CREATE,
  REVENUE_MAP,
  ELASTICITY_PRICING_TRACK,
  PRICING_POTENTIAL,
  BRAND_LADDER,
  PACK_ECONOMICS,
  PRICE_SETTING_TOOL,
  PRICE_RANGE_PIANO,
  PRICE_LADDER,
  GEO_XREF,
  PROD_XREF,
  GRAMMAGE_PER_PACK_SIZE,
  TRADE_MANAGEMENT,
  MIX_MANAGEMENT,
  DATA_SOURCE_REFRESH,
  LOGIN_CALLBACK,
  USAGE_ACTIVITY_REPORT,
  USAGE_COUNT_REPORT,
  ASSORTMENT_TOOL,EXECUTION_TOOL,
  SCENARIO_LIBRARY,
  MIX_SIMULATION_TOOL,
  MIX_SIMULATION_SCENARIO_LIBRARY,
  LOGIN_DP
};
