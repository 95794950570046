import {
  SET_USER_INPUT,
  FETCH_USER_INPUT_REQUEST,
  FETCH_USER_INPUT_SUCCESS,
  FETCH_USER_INPUT_FAILURE,
} from "../actions/constants";

const initialState = {
  userInputValues: {},
  loading: false,
  error: null,
};

const userInputReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INPUT:
      return {
        ...state,
        userInputValues: {
          ...state.userInputValues,
          [action.payload.fieldName]: action.payload.value,
        },
      };
    case FETCH_USER_INPUT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USER_INPUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case FETCH_USER_INPUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userInputReducer;
