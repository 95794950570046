import React, { useState } from "react";
import { Card, CardContent } from "@mui/material";
import { useSelector } from "react-redux";
import _ from "lodash";
import mockData from "../../../../mocks/assortmentMocks";
import AssortmentFiltersAccordian from "../../../../components/AssortmentFiltersAccordian/AssortmentFiltersAccordian";
import Indicator from "../../../../components/Loader/Loader";
import GeoFilters from "../FilterComponents/GeoFilters/GeoFilters";

const AssortmentGeoFilters: React.FC<{
  onSelectFilters;
  selectedProductFilters;
  isViewEdit;
}> = ({
  onSelectFilters,
  selectedProductFilters,
  isViewEdit,
}) => {
  const [selectedGeoFilters, setSelectedGeoFilters] = useState({
    channel: null,
    region: null,
    storeSegment: null,
  });

  const handleGeoFiltersChange = (selectedFilters) => {
    setSelectedGeoFilters(selectedFilters);
    onSelectFilters(selectedFilters);
  };

  const { loaderForGeoFilters, } = useSelector(
    (state: any) => state.AssortmentFiltersReducer
  );

  return (
    <Card
      className="m-b-20"
      style={{
        marginTop: "3%",
        position: "relative",
        backgroundColor: "#F1F5FE",
      }}
    >
      <Indicator position="absolute" show={loaderForGeoFilters} />
      <CardContent>
        <AssortmentFiltersAccordian
          defaultOpen={true}
          title="Geo-Filters:"
          subTitle={null}
        >
          <div style={{ marginTop: "1rem" }}>
            <GeoFilters
              data={{ ...mockData.geoFilterV2Config }}
              onSelectFilters={handleGeoFiltersChange}
              selectedProductFilters={selectedProductFilters}
              isViewEdit={isViewEdit}
            />
          </div>
        </AssortmentFiltersAccordian>
      </CardContent>
    </Card>
  );
};

export default AssortmentGeoFilters;
