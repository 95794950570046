import {
  MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_REQUEST,
  MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_SUCCESS,
  MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_FAILURE,
  MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_OPERATIVE_DATA_SUCCESS,
  MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_CAPACITY_DATA_SUCCESS,
  UPLOAD_OPERATIVE_COMPLEXITY,
  UPLOAD_CAPACITIVE_COMPLEXITY,
  RESET_MIX_USER_CONSTRAINTS_CSV_DATA,
} from "../../actions/constants";

// Initial state
const initialState = {
  constraintsData: null,
  csvDataForOperative: [],
  csvDataForCapacity: [],
  constraintsLoader: false,
  capacitiveFile : null,
  operativeFile : null
};

const MixUserConstraintsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_REQUEST:
      return {
        ...state,
        error: null,
        constraintsLoader: true,
      };

    case MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_SUCCESS:
      return {
        ...state,
        constraintsData: action.payload,
        constraintsLoader: false,
      };
    case MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_OPERATIVE_DATA_SUCCESS:
      return {
        ...state,
        csvDataForOperative: action.payload,
        constraintsLoader: false,
      };
    case MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_CAPACITY_DATA_SUCCESS:
      return {
        ...state,
        csvDataForCapacity: action.payload,
        constraintsLoader: false,
      };
      
      case RESET_MIX_USER_CONSTRAINTS_CSV_DATA:
        return initialState;
    case MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_FAILURE:
      return {
        ...state,
        constraintsLoader: false,
        error: action.payload,
      };
    case UPLOAD_OPERATIVE_COMPLEXITY:
      return {
        ...state,
        operativeFile : action.payload
      }
    case UPLOAD_CAPACITIVE_COMPLEXITY:
      return {
        ...state,
        capacitiveFile : action.payload
      }    
    default:
      return state;
  }
};

export default MixUserConstraintsReducer;
