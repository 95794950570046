import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { DownloadButton } from "./AssortmentOutput.Styled";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import { toast } from "../../../../store/actions/toast.action";
import {
  fetchAssortmentRunSimulationFailure,
  fetchAssortmentRunSimulationRequest,
} from "../../../../store/actions/AssortmentTool/assortmentFilters.action";
import { API_URL } from "../../../../store/actions/constants";
import { downloadInExcelCsvFormat } from "../CommonComponents/utils";

export const AssortmentOutput = () => {
  const { uid } = useSelector((state: any) => state.AssortmentFiltersReducer);
  const dispatch = useDispatch();

  const downloadOverallData = () => {
    let payload = { uid: uid };
    dispatch(fetchAssortmentRunSimulationRequest());
    dispatch(
      fetchApi(
        payload,
        API_URL.ASSORTMENT.DOWNLOAD_ASSORTMENT_SIMULATION_DATA,
        null
      )
    )
      .then((res) => {
        dispatch(fetchAssortmentRunSimulationFailure(""));
        const data = res.data;
        downloadInExcelCsvFormat(data, "Assortment_Output_Overall_Data");
      })
      .catch((err) => {
        dispatch(fetchAssortmentRunSimulationFailure(""));
        dispatch(
          toast(
            "Something went wrong in overall download api, please try again later.",
            true,
            3000,
            "error"
          )
        );
      });
  };

  return (
    <>
      <Grid style={{ marginTop: "15px" }}>
        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography style={{}} color={"white"} variant="h5">
            OUTPUT
          </Typography>
          {uid !== null && (
            <DownloadButton onClick={downloadOverallData}>
              Full Data Download
            </DownloadButton>
          )}
        </Grid>
        <Divider
          color="white"
          style={{
            marginBottom: "10px",
            marginTop: "10px",
            width: "100%",
            color: "white",
            background: "white",
          }}
          sx={{ height: 2, m: 0 }}
          orientation="vertical"
        />
      </Grid>
    </>
  );
};
